import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './job.css';
import Pagination from '../../components/pagination/Pagination';
import Filter from '../../components/filter/Filter';
import JobSearch from '../../components/jobSearch/JobSearch';
import JobCard from '../../components/jobsCard/JobCard';
import { useDispatch, useSelector } from 'react-redux';
import { getJobListFetch } from '../../redux/Slice/jobsListSlice';

const Jobs = () => {
  const dispatch = useDispatch()
  const {jobList,countries,cities,search,jobtype,jobmode} = useSelector(state => state?.jobsList)
  const [filters, setFilters] = useState({limit: 10, page: 1 });
  const paginatedData = (page) => {
    setFilters((prevState) => ({
      ...prevState,
      limit: 10,
      page: page
    }));
  }

  useEffect(()=>{
    const jobsearch = { 
      ...((cities || countries || jobmode || jobtype) != null   && 
        {filters:{ 
          ...((cities || countries) != null && {joblocation: cities != null ? cities+","+countries: countries}),
          ...(jobmode != null && {jobmode:[jobmode]}),
          ...(jobtype !=null && {emptype:[jobtype]})
          }}),
         ...(search != null && {search:search}),  
    ...filters} 
    dispatch(getJobListFetch(jobsearch))
  },[dispatch,filters,countries,cities,search,jobtype,jobmode])


  return (
      <div className='jobListWrapper'>
        <Container fluid className='p-0'>
          <div className='searchTopWrap'>
            <JobSearch />
          </div>
        </Container>
        <Container className='mt-3 mt-md-3'>
          <Row>
            <Col md={4} lg={3}>
              <Filter />
            </Col>
            <Col md={8} lg={9}>
              <div className='recommended_Job_row d-flex justify-content-between'>
                <div className='recomJob'>Recommeded Jobs <span>386</span></div>
                <div className='sortBy'>Sort By: <span>Last Updated</span><img src='../filter.png' /></div>
              </div>
              <JobCard />
              <Pagination count={jobList?.totalPages} filters={filters} paginatedData={paginatedData} />
            </Col>
          </Row>
        </Container>
      </div> 
  )
}

export default Jobs
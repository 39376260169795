import { getDataApi, postDataApi } from "./apiMethod";

const apiConfig = {
  notificationList: {
    method: "GET",
    path: "api/notificationlist",
  }
};


export const getNotificationListRequest = async(data)=>{
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  const { limit, page } = data;
  let { path } = apiConfig.notificationList;
  path = `${path}?limit=${limit}&page=${page}`;
  return await getDataApi({ path,headers });
};
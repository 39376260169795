import React from 'react';
import { useSelector } from 'react-redux';
import { infoAlert, errorAlert, successAlert } from '../../utils/alerts';
import { appliedJobRequest } from '../../services';
import {history} from '../../utils/history'
import './JobDescription.css';


const JobDescription = () => {
  const jobdetail = useSelector(state => state?.jobsList?.jobdetails)
  const { isAuthenticated } = useSelector((state) => state.auth);
  const applyJob = async(id) => {
    if(!isAuthenticated){ infoAlert("Please login first to apply the job"); return -1 }
    const jobApplied = await appliedJobRequest({jobId:id})
    const { status, message, result } = jobApplied.data;
    if(status !== 201){ errorAlert("We are really sorry.Not able to apply the job right now");}
    else{ successAlert("Job applied successfully")  }
    setTimeout(()=>{history.navigate('/jobs')},500)
   }
  return (
    <div className='jobDescriptionWrapper'>
      <div className='jobProfileFixedOnTop d-flex justify-content-between justify-content-md-around align-items-center'>
        <div className='heading'>
          <h3>{jobdetail?.jobtitle}</h3>
          <p>{jobdetail?.orgname}</p>
        </div>
        <button className='btn btn-global applyBtn' onClick={() => { applyJob(jobdetail?.id) }}>Apply</button>
      </div>
      <div className='jobProfileHeading position-relative'>
        <h3>{jobdetail?.jobtitle}</h3>
        <p>{jobdetail?.orgname}</p>
        <div className='jobProfileInner'>
          <span className='experiance'><img src='../Briefcase.png' /></span> {jobdetail?.expfrom}-{jobdetail?.expto}
          {jobdetail?.currsymbol != "" ?
            <><span className='budget'>{jobdetail?.currsymbol}</span>{jobdetail?.salfrom || "0"}-{jobdetail?.salto || "0"} </> : ""}
          <br />
          <span className='location'><img src='../location-icon.png' /></span> {jobdetail?.joblocation}
        </div>
        {/* <div className='postedDate'>Posted: 30+ days agoOpenings: 1Job Applicants: 5719</div> */}
        <button className='btn btn-global applyBtn' onClick={() => { applyJob(jobdetail?.id) }}>Apply</button>
      </div>
      <div className='aboutJobs'>
        <label>Job Description</label>
        <ul>
          {jobdetail?.jd}
          {/* <li>Work with developer to design algorithms and flowcharts.</li>
          <li>Produce clean, efficient code based on specifications.</li>
          <li>Integrate software components and third-party programs.</li>
          <li>Verify and deploy program and systems.</li>
          <li>Troubleshoot, debug and upgrade existing software.</li>
          <li>Develop an initial Implementation plan based on the clients requirements and available resources.</li>
          <li>Working closely with the development teams to integrate the PlatformCreation and Implementation of scripts as per the product/projectcustomization</li>
          <li>Providing internal training to the team to ensure proper handshake of knowledge transfer</li>
          <li>Post development monitor and support ERP solution in ships</li> */}
        </ul>
        {/* <label>Requirements</label>
        <ul>
          <li>Proven experience as a Software Developer, Software Engineer or similar role</li>
          <li>Familiarity with Agile development methodologies</li>
          <li>Experience with software design and development in a test-drivenenvironment</li>
          <li>Knowledge of coding languages (C#, JavaScript, Jquery & .net)</li>
        </ul>
        <ul className='mb-4 list-unstyled'>
          <li><b>Role:</b> Software Development - Other</li>
          <li><b>Industry Type:</b> Recruitment / Staffing</li>
          <li><b>Department:</b> Engineering - Software & QA</li>
          <li><b>Employment Type:</b> Full Time, Permanent</li>
          <li><b>Role Category:</b> Software Development</li>
        </ul> */}
        {jobdetail?.reqedu ? 
        <ul className='list-unstyled'>
        <b>Education</b>
        {jobdetail?.reqedu.split(",").map((value,index)=>(
          <li>{value}</li>
        ))}
      </ul>
        : 
        ""}
        {jobdetail?.skills ? 
        <>
        <label>Key Skills</label>
        <ul className='keySkills'>
          {jobdetail?.skills.split(",").map((value,index)=>(
            <li>{value}</li>
          ))}
        </ul>
        </>
        :""}
      </div>
      {
        jobdetail?.compinfo ?
        <div className='aboutCompany'>
        <label>About Company</label>
        <p>{jobdetail?.compinfo}</p>
      </div>
        :""
      }

    </div>
  )
}

export default JobDescription
import { call, put } from "redux-saga/effects"
import {errorAlert} from '../../utils/alerts'
import * as services from  '../../services'
import {getCitiesListSuccess,getLocListFail,getLocListSuccess} from "../Slice/locSlice";

export function* getCountryListRequest(action) {
  try {
    const response = yield call( services.getCountryListRequest,action.payload );
    if (response?.data) {
      const { status, message, result } = response.data;
      if (status === 200) {
        yield put(getLocListSuccess(result));
      } else {
        errorAlert(message);
        yield put(getLocListFail());
      }
    } else {
      yield put(getLocListFail());
    }
  } catch (error) {
    errorAlert("Error in fetching  feedback listsss");
    yield put(getLocListFail());
  }
}

export function* getCitiesListRequest(action){
  try{
    const response = yield call( services.getCitiesListRequest,action.payload );
    if (response?.data) {
      const { status, message, result } = response.data;
        if (status === 200) {
          yield put(getCitiesListSuccess(result.cities));
        } else {
          errorAlert(message);
          yield put(getLocListFail());
        }
      } else {
        yield put(getLocListFail());
      }
    } catch (error) {
      errorAlert("Error in fetching  feedback list12334");
      yield put(getLocListFail());
    }
}

import { getDataApi, postDataApi } from "./apiMethod";
const config = {
  getProfile: {
    method: "GET",
    path: "api/profile",
  },
  updateProfile:{
    method:"POST",
    path:"api/profileupdate"
  },
  expertList:{
    method:"GET",
    path:"api/experts"
  },
  getExpertProfile : {
   method: "GET",
   path:"api/expert-profile"
  },
  ourMentors:{
    method:"GET",
    path:"api/ourmentors"
  }

};

export const getProfileRequest = async (data) => {
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  let { path } = config.getProfile;
  return await getDataApi({ path,headers });
};

export const updateProfileRequest = async(data)=>{
  let {path} = config.updateProfile;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"multipart/form-data", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({path,data,headers})
}

export const getExpertListRequest = async(data)=>{
  let { path } = config.expertList;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  return await getDataApi({ path,data,headers });
}

export const getExpertRequest = async(data)=>{
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  let { path } = config.getExpertProfile;
  return await getDataApi({ path,data,headers });
}

export const getOurMentorsRequest = async(data)=>{
  let { path } = config.ourMentors;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  return await getDataApi({ path,data,headers });
}
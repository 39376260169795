import React from 'react'
import {Modal, Button} from 'react-bootstrap';
import OtpInput from '../otp-input/OtpInput';
import { otpSentFail, otpSentRequest } from '../../redux/Slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const OtpPopup = (props) => {
  const dispatch = useDispatch();
  const { otpValue,userEmail } = useSelector((state) => state?.auth);
  const HandleClose = () => {
    dispatch(otpSentFail())
  }
  const verifyOtp = ()=>{
    const data = {email:userEmail,otp:otpValue}
    dispatch(otpSentRequest(data))
  }

  return (
    <Modal className='otpPopup-Wrapper'show={props.otpSent}  backdrop="static" onHide={HandleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>OTP Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='popupHeader'>
          <h3>Please Enter the One-Time Password to verify your account</h3>
          <p>A One-Time Password has been sent to your email </p>
        </div>
        <OtpInput />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={otpValue?.length === 6 ? false : true} variant="primary" onClick={()=>{verifyOtp()}}>
          Verify OTP
        </Button>
        <p>Resend ONe-Time Password</p>
      </Modal.Footer>
    </Modal>
  )
}

export default OtpPopup
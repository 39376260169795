import { call, put } from "redux-saga/effects"
import {errorAlert} from '../../utils/alerts'
import * as services from  '../../services'
import { getProfileListSuccess, getOurMentorListSuccess,getProfileFail, getMyProfileSuccess, getOtherProfileSuccess } from "../Slice/profileSlice";
import { loginFail } from "../Slice/authSlice";

export function* getProfileRequest(action) {
   const response = yield call( services.getProfileRequest, action.payload);
   const { status, message, result } = response?.data;
   if(response.status === 200){
    yield put(getMyProfileSuccess(result));
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(getProfileFail())
    yield put(loginFail())
   }
   else{
    errorAlert(message);
    yield put(getProfileFail());
   }
}


export function* getExpertRequest(action){
  const response = yield call( services.getExpertRequest, action.payload);
  const { message, result } = response?.data;
   if(response.status === 200){
    yield put(getOtherProfileSuccess(result));
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(getProfileFail())
    yield put(loginFail())
   }
   else{
    errorAlert(message);
    yield put(getProfileFail());
   }
}

// export function* getExpertRequest(action) {
//   if (!checkConnection()) {
//     return;
//   }
//     const response = yield call(
//       services.getExpertRequest,
//       action.payload
//     );
//    const { status, message, result } = response.data;
//    if(response.status === 200){
//     yield put({
//       type: actionTypes.profile.GET_EXPERT_SUCCESS,
//       payload: result,
//     });
//    }
//    else if(response.status === 401){
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.GET_EXPERT_ERROR });
//     yield put({type:actionTypes.auth.LOGIN_ERROR})
//    }
//    else{
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.GET_EXPERT_ERROR });
//    }
// }

// export function* updateProfileRequest(action) {
//   if (!checkConnection()) {
//     return;
//   }
//     const response = yield call(
//       services.updateProfileRequest,
//       action.payload
//     );
//    const { status, message, result } = response.data;
//    if(response.status === 201){
//     yield put({
//       type: actionTypes.profile.UPDATE_PROFILE_SUCCESS,
//       payload: result,
//     });
//     action.callback(true);
//    }
//    else if(response.status === 401){
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.UPDATE_PROFILE_ERROR });
//     yield put({type:actionTypes.auth.LOGIN_ERROR})
//     action.callback(false);
//    }
//    else{
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.UPDATE_PROFILE_ERROR });
//     action.callback(false);
//    }
// }


export function* getExpertListRequest(action){
  const response = yield call( services.getExpertListRequest, action.payload);
   const { message, result } = response?.data;
   if(response.status === 200){
    yield put(getProfileListSuccess(result));
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(getProfileFail())
    yield put(loginFail())
   }
   else{
    errorAlert(message);
    yield put(getProfileFail());
   }
}


// export function* getExpertListRequest(action){
//   if (!checkConnection()) {
//     return;
//   }
//     const response = yield call(
//       services.getExpertListRequest,
//       action.payload
//     );
//    const { status, message, result } = response.data;
//    if(response.status === 200){
//     yield put({
//       type: actionTypes.expertList.GET_EXPERTLIST_SUCCESS,
//       payload: result,
//     });
//    }
//    else if(response.status === 401){
//     errorAlert(message);
//     yield put({ type: actionTypes.expertList.GET_EXPERTLIST_ERROR});
//     yield put({type:actionTypes.auth.LOGIN_ERROR})
//    }
//    else{
//     errorAlert(message);
//     yield put({ type: actionTypes.expertList.GET_EXPERTLIST_ERROR });
//    }
// }

export function* getOurMentorsRequest(action){
    const response = yield call( services.getOurMentorsRequest, action.payload );
   const { status, message, result } = response.data;
   if(response.status === 200){
    yield put(getOurMentorListSuccess(result));
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(getProfileFail());
   }
   else{
    errorAlert(message);
    yield put(getProfileFail());
   }
}

// export function* getORGProfileRequest(action){
//   if (!checkConnection()) {
//     return;
//   }
//     const response = yield call(
//       services.getOurMentorsRequest,
//       action.payload
//     );
//    const { status, message, result } = response.data;
//    if(response.status === 200){
//     yield put({
//       type: actionTypes.expertList.GET_OURMENTORS_SUCCESS,
//       payload: result,
//     });
//    }
//    else if(response.status === 401){
//     errorAlert(message);
//     yield put({ type: actionTypes.expertList.GET_OURMENTORS_ERROR});
//     yield put({type:actionTypes.auth.LOGIN_ERROR})
//    }
//    else{
//     errorAlert(message);
//     yield put({ type: actionTypes.expertList.GET_OURMENTORS_ERROR });
//    }
// }

// export function* updateORGProfileRequest(action) {
//   if (!checkConnection()) {
//     return;
//   }
//     const response = yield call(
//       services.updateProfileRequest,
//       action.payload
//     );
//    const { status, message, result } = response.data;
//    if(response.status === 201){
//     yield put({
//       type: actionTypes.profile.UPDATE_PROFILE_SUCCESS,
//       payload: result,
//     });
//     action.callback(true);
//    }
//    else if(response.status === 401){
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.UPDATE_PROFILE_ERROR });
//     yield put({type:actionTypes.auth.LOGIN_ERROR})
//     action.callback(false);
//    }
//    else{
//     errorAlert(message);
//     yield put({ type: actionTypes.profile.UPDATE_PROFILE_ERROR });
//     action.callback(false);
//    }
// }

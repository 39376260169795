export const formDataCreator = (payload,...args) => {
    const formData = new FormData();
    for(let pair in payload){
        if(args.includes(pair)){
        }else{
            formData.append(pair,payload[pair]);
        }
    }
    return formData;
}

export const urltoFile = async (url, filename, mimeType, imgext) => {
  const fetchUrl = await fetch(url);
  //const bufferedData = await fetchUrl.arrayBuffer();
  const blob = await fetchUrl.blob();
  const file = await new File([blob], `${filename}.${imgext}` , { type: 'image/png' });
  //const file = new File([bufferedData], `${filename}.${imgext}`, { type: mimeType })
  return file
}
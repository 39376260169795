import { takeEvery, takeLatest }from "redux-saga/effects";
import * as job from "./sagaJobApiCall";
import * as auth from "./sagaAuthApiCall";
import * as profile from "./sagaProfileApiCall";
import * as notification from "./sagaNotificationApiCall";
import * as loc from "./sagaLocApiCall"
import * as meet from "./sagaMeetApiCall"

export function* locationsSaga() {
  yield takeEvery('loc/getLocListFetch', loc.getCountryListRequest);
  yield takeEvery('loc/getCitiesListFetch', loc.getCitiesListRequest);
}
export function* jobSaga() {
  yield takeEvery('jobs/getJobListFetch', job.jobListRequest);
  yield takeEvery('jobs/getLatestJobListFetch', job.getLatestJobRequest);
  yield takeEvery('jobs/getJobDetailsFetch', job.getJobDetail);
}

export function* authSaga(){
  yield takeEvery('auth/loginRequest', auth.loginRequest);
  yield takeEvery('auth/googleRequest', auth.googleRequest);
  yield takeEvery('auth/registerRequest',auth.registerRequest);
  yield takeEvery('auth/otpSentRequest', auth.verifyOtpRequest);
  yield takeEvery('auth/forgotPasswordRequest',auth.sendOtpRequest);
  yield takeEvery('auth/changePasswordRequest', auth.forgotPassword)
}

export function* profileSaga(){
  yield takeEvery('profile/getProfileFetch', profile.getProfileRequest);
  yield takeEvery('profile/getOurMentorFetch', profile.getOurMentorsRequest);
  yield takeEvery('profile/getListProfileFetch',profile.getExpertListRequest);
  yield takeEvery('profile/getOtherProfileFetch', profile.getExpertRequest);
}

export function* notificSaga() {
  yield takeEvery('notification/getNotificationListFetch', notification.getNotificationListRequest);
}


export function* videoMeetSagas() {
  yield takeEvery('meet/meetRequestStart', meet.meetConnectAcceptRejectRequest);
}

export default { jobSaga, authSaga, profileSaga, notificSaga, locationsSaga, videoMeetSagas }
import {configureStore, combineReducers, compose, applyMiddleware} from '@reduxjs/toolkit'
import authReducer from './Slice/authSlice';
import jobReducer from './Slice/jobsListSlice'; 
import profileReducer from './Slice/profileSlice'
import notificationReducer from './Slice/notificationSlice';
import locFilterReducer from './Slice/locSlice';
import meetReducer from './Slice/meetandslotSlice';

const appReducer = combineReducers({
  auth: authReducer,
  jobsList: jobReducer,
  profile: profileReducer,
  notificationList: notificationReducer,
  location: locFilterReducer,
  meet:meetReducer
});

const rootReducer = (state,action) => {
  const obj= { ...action };
  if (action.type === "auth/logout") {
    delete obj.type;
    localStorage.clear();
    return appReducer(undefined, obj);
  }
  return appReducer(state, obj);
};

export default rootReducer;
import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
    name:'jobs',
    initialState:{
      isLoading:false,
      jobList:null,
      latestJob:null,
      jobdetails:null,
      error:null,
      countries:null,
      cities:null,
      search:null,
      jobtype:null,
      jobmode:null,
      clearFilter:false
    },
    reducers:{
      getJobListFetch:(state)=>{
         state.isLoading = true
      },
      getJobListSuccess:(state,action)=>{
        state.jobList = action.payload
        state.isLoading = false
      },
      getLatestJobListFetch:(state)=>{
        state.isLoading = true
      },
      getLatestJobListSuccess:(state,action)=>{
        state.latestJob = action.payload
        state.isLoading = false
      },
      getJobSearchRequest:(state,action)=>{
        state.search = action.payload
      },
      getJobCountryRequest:(state,action)=>{
        state.countries = action.payload
      },
      getJobCitiesRequest:(state,action)=>{
        state.cities = action.payload
      },
      getJobDetailsFetch:(state,action)=>{
         state.isLoading = true
      },
      getJobDetails:(state,action)=>{
        state.jobdetails = action.payload
        state.isLoading = false
     },
      addJobType:(state,action)=>{
        state.jobtype = addString(state.jobtype,action.payload)
      },
      removeJobType:(state,action)=>{
        state.jobtype = removeString(state.jobtype,action.payload)
      },
      addJobMode:(state,action)=>{
        state.jobmode = addString(state.jobmode,action.payload)
      },
      removeJobMode:(state,action)=>{
        state.jobmode = addString(state.jobmode,action.payload)
      },
      setclearFilter:(state,action)=>{
        state.clearFilter = action.payload
      },
      getJobListFail:(state)=>{
        state.isLoading = false
        state.cities = null
        state.countries = null
        state.search = null
        state.jobmode = null
        state.jobtype = null

      }
    }
});

export const addValueInArray= (array,action)=>{
  let newArray = array.slice();
  newArray.splice(action.index, 0, action.item)
  return newArray
}

export const addString = (str,action)=>{
  let strs = str 
  if(strs === null){ strs = action }
   else{ strs = strs+","+action }
   return strs
}
 export const removeString = (str,action)=>{
  let strs = str.split(",") 
   strs = strs.filter((value,index)=> value != action)
   return strs.toString()
 }

export const removeValueInArray= (array,action)=>{
  let newArray = array.slice()
  newArray.splice(action.index, 1)
  return newArray
}

export const { getJobListFail, getJobListFetch, getJobListSuccess,getLatestJobListFetch,
  getLatestJobListSuccess,getJobSearchRequest,getJobCountryRequest,getJobCitiesRequest,
  addJobType, removeJobType, addJobMode, removeJobMode ,setclearFilter, getJobDetails, 
  getJobDetailsFetch } = jobSlice.actions

export default jobSlice.reducer;
import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faGooglePlus, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileFetch, getOtherProfileFetch, connectClicked } from '../../redux/Slice/profileSlice';
import { history } from '../../utils/history';

import './profile.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Appointment from '../../components/appointmentForm/Appointment';
// import 'react-big-calender/lib/addons/dragAndDrop/styles'


const Profile = () => {
  const dispatch = useDispatch();
  const { myprofile, connectMe, isLoading, connectClick } = useSelector((state) => state?.profile) || [];
  const socialink = typeof (myprofile?.socialink) === "string" ? JSON.parse(myprofile?.socialink) || "{}" : myprofile?.socialink || "{}"
  const { linkedinLink, fbLink, twitterLink, googleLink, instaLink } = socialink
  const connect =()=>{ dispatch(connectClicked(true)); }


  useEffect(() => {
    if (connectMe?.connectMe && history.location.pathname === '/expert-connect') {
      dispatch(getOtherProfileFetch(connectMe?.connectId));
    } else { dispatch(getProfileFetch()) }
  }, [connectMe?.connectMe, history.location.pathname])


  return (
    <div className='profileWrapper'>
      <Container>
        {isLoading ? "" :
          <>
            <Row>
              <Col md={4}>
                <div className='profileCaption text-center'>
                  <img src={myprofile?.profilepic || './user-photo.png'} className='img-fluid profilePic mb-2' />
                  <div className='userName mb-2 font-600'>{`${myprofile?.fname || "Name"} ${myprofile?.lname || ""}`}</div>
                  <div className='userDesignation mb-3 font-600'>{myprofile?.ProfileData?.profession || "Profession"}</div>
                  <Nav className="followIcons mb-4 mb-md-5 justify-content-center">
                    {fbLink && <Nav.Link href={fbLink || "#action1"}><FontAwesomeIcon icon={faFacebookF} /></Nav.Link>}
                    {twitterLink && <Nav.Link href={twitterLink || "#action1"}><FontAwesomeIcon icon={faTwitter} /></Nav.Link>}
                    {googleLink && <Nav.Link href={googleLink || "#action1"}><FontAwesomeIcon icon={faGooglePlus} /></Nav.Link>}
                    {instaLink && <Nav.Link href={instaLink || "#action1"}><FontAwesomeIcon icon={faInstagram} /></Nav.Link>}
                    {linkedinLink && <Nav.Link href={linkedinLink || "#action1"}><FontAwesomeIcon icon={faLinkedin} /></Nav.Link>}
                  </Nav>
                  {connectMe?.connectMe ? <Button onClick={()=>{connect()}}  >Connect</Button> : <Nav.Link className='btn btn-global' as={Link} to="/edit-profile">Edit</Nav.Link>}
                </div>
              </Col>
              <Col md={8}>
                <div className='profileDetails'>
                  <div className='basicInfo'>
                    <div className='infoRow'><label>Email-ID:</label><span>{myprofile?.email || "Email"}</span></div>
                    <div className='infoRow'><label>Mobile Number:</label><span>{myprofile?.phone || "Phone"}</span></div>
                    <div className='infoRow'><label>Address:</label><span>{myprofile?.address || "Address"}</span></div>
                    {/* <div className='infoRow'><label>Age:</label><span>30</span></div> */}
                    {/* <div className='infoRow'><label>Location:</label><span>Delhi</span></div> */}
                    <div className='infoRow'><label>About:</label><span className='d-block w-100'>
                      {myprofile?.ProfileData?.aboutmore || "About"}
                    </span></div>
                  </div>
                  <div className='skills'>
                    <label>Profile Headline</label>
                    <ul>
                      <li>{myprofile?.ProfileData?.summary || "Summary"}</li>
                    </ul>
                    <label>Key Skills</label>
                    <ul className='keySkills p-0'>
                      {myprofile?.ProfileData?.skills?.length > 0 ? myprofile?.ProfileData?.skills?.map((skill, indx) => <li key={indx}>{skill}</li>) : ""}
                    </ul>
                    <ul className='list-unstyled'>
                      <b>Education</b>
                      {myprofile?.ProfileData?.education.length > 0 ? myprofile?.ProfileData?.education?.map((skill, indx) => <li key={indx}>{skill}</li>) : ""}
                    </ul>
                  </div>
                </div>
                {/* {connectMe?.connectMe ? 
                 : ""}  */}
              </Col>
            </Row>
          </>
        }
      </Container>
      {connectClicked ? <Appointment /> :""}
    </div>
  )
}

export default Profile
import React, { useEffect } from 'react';
import './latestjob.css';
import Container from 'react-bootstrap/Container';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const LatestJob = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
    },
    afterChange: function (currentSlide) {
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          //infinite: false,
          dots: false,
        }
      },
    ]
  };
  const latestJobs = useSelector((state) => state.jobsList) || []
  return (
    <div className='latestJob_Wrapper'>
      <Container>
        <h3 className='text-center position-relative'><span>LATEST JOBS</span></h3>
        <Row>
          <Col md={5}>
            <div className='d-flex h-100'>
              <img src='./latestJobImage.svg' className='img-fluid latestJobImage' />
            </div>
          </Col>
          <Col md={7}>
            <div className='cardSlider-Wrapper position-relative'>
              <Slider {...settings}>
                 {!latestJobs?.isLoading && latestJobs?.latestJob?.map((items,index) => (
                  <Card className='p-3 p-md-4 mb-3' key={`laJob-${index}`}>
                    <h4>{items?.jobtitle}<span>$5000-10,000$</span></h4>
                    <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                      <ul className='d-flex list-unstyled w-100 justify-content-between mr-4 flex-wrap'> 
                        <li><img src='./building.svg' />{items?.orgname}</li>
                        <li><img src='./remote-control.svg' />{items?.jobMode}</li>
                        <li><img src='./save-time.svg' />{items?.emptype}</li>
                      </ul>
                      <button className='btn btnJobDetail applyBtn'>Apply</button>
                    </div>
                    <div className='jobDetails'>{items?.jd}</div>
                    <Nav.Link className='btn btnJobDetail mt-3' as={Link} to={`/job-details/${items?.id}`} >Job Details</Nav.Link>
                  </Card>
                ))}

                {/* <Card className='p-3 p-md-4 mb-3'>
                            <h4>Front-End Developer <span>$5000-10,000$</span></h4>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                <ul className='d-flex list-unstyled'>
                                    <li><img src='./building.svg' /> TCS</li>
                                    <li><img src='./remote-control.svg' /> Remote</li>
                                    <li><img src='./save-time.svg' /> Full-Time</li>
                                </ul>
                                <button className='btn btnJobDetail'>Apply</button>
                            </div>
                            <div className='jobDetails'>Dot Net Developer Experience – 2 to 8 Years Experience with developing applications using Microsoft technologies such as .NET. Framework 4.5/4.0/3.5/3.0/2.0.</div>
                            <button className='btn btnJobDetail mt-3'>Job Details</button>
                        </Card>
                        <Card className='p-3 p-md-4 mb-3'>
                            <h4>Front-End Developer <span>$5000-10,000$</span></h4>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                <ul className='d-flex list-unstyled'>
                                    <li><img src='./building.svg' /> TCS</li>
                                    <li><img src='./remote-control.svg' /> Remote</li>
                                    <li><img src='./save-time.svg' /> Full-Time</li>
                                </ul>
                                <button className='btn btnJobDetail'>Apply</button>
                            </div>
                            <div className='jobDetails'>Dot Net Developer Experience – 2 to 8 Years Experience with developing applications using Microsoft technologies such as .NET. Framework 4.5/4.0/3.5/3.0/2.0.</div>
                            <button className='btn btnJobDetail mt-3'>Job Details</button>
                        </Card>
                        <Card className='p-3 p-md-4 mb-3'>
                            <h4>Front-End Developer <span>$5000-10,000$</span></h4>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                <ul className='d-flex list-unstyled'>
                                    <li><img src='./building.svg' /> TCS</li>
                                    <li><img src='./remote-control.svg' /> Remote</li>
                                    <li><img src='./save-time.svg' /> Full-Time</li>
                                </ul>
                                <button className='btn btnJobDetail'>Apply</button>
                            </div>
                            <div className='jobDetails'>Dot Net Developer Experience – 2 to 8 Years Experience with developing applications using Microsoft technologies such as .NET. Framework 4.5/4.0/3.5/3.0/2.0.</div>
                            <button className='btn btnJobDetail mt-3'>Job Details</button>
                        </Card>
                        <Card className='p-3 p-md-4 mb-3'>
                            <h4>Front-End Developer <span>$5000-10,000$</span></h4>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                <ul className='d-flex list-unstyled'>
                                    <li><img src='./building.svg' /> TCS</li>
                                    <li><img src='./remote-control.svg' /> Remote</li>
                                    <li><img src='./save-time.svg' /> Full-Time</li>
                                </ul>
                                <button className='btn btnJobDetail'>Apply</button>
                            </div>
                            <div className='jobDetails'>Dot Net Developer Experience – 2 to 8 Years Experience with developing applications using Microsoft technologies such as .NET. Framework 4.5/4.0/3.5/3.0/2.0.</div>
                            <button className='btn btnJobDetail mt-3'>Job Details</button>
                        </Card>
                        <Card className='p-3 p-md-4 mb-3'>
                            <h4>Front-End Developer <span>$5000-10,000$</span></h4>
                            <div className='d-flex justify-content-between flex-wrap flex-md-nowrap'>
                                <ul className='d-flex list-unstyled'>
                                    <li><img src='./building.svg' /> TCS</li>
                                    <li><img src='./remote-control.svg' /> Remote</li>
                                    <li><img src='./save-time.svg' /> Full-Time</li>
                                </ul>
                                <button className='btn btnJobDetail'>Apply</button>
                            </div>
                            <div className='jobDetails'>Dot Net Developer Experience – 2 to 8 Years Experience with developing applications using Microsoft technologies such as .NET. Framework 4.5/4.0/3.5/3.0/2.0.</div>
                            <button className='btn btnJobDetail mt-3'>Job Details</button>
                        </Card>  */}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LatestJob

import {Route, Routes } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./publicPrivateRoute";
import { PATHS } from "./paths";
import Homepage from "../screens/homepage/Homepage";
import Jobs from "../screens/jobScreen/Jobs";
import Login from "../screens/login/Login"
import Registration from "../screens/registration/Registration";
import Contactus from "../screens/contactus/Contactus"
import Profile from "../screens/profile/Profile"
import EditProfile from "../screens/editProfile/EditProfile"
import JobDetails from "../components/jobDetails/JobDetails";
import Experts from "../screens/experts/Experts";
import Notification from "../screens/notification/Notification";
import PageNotFound from "../screens/pageNotFound/PageNotFound";

const Routers = () => {
  return (
      <Routes>
        <Route element={<PublicRoute children={<Homepage />} />} path={PATHS.HOMEPAGE} />
        <Route element={<PublicRoute children={<Jobs />} />} path={PATHS.JOBS} />
        <Route element={<PublicRoute children={<Login />} />} path={PATHS.LOGIN} />
        <Route element={<PublicRoute children={<Registration />} />} path={PATHS.REGISTRATION}/>
        <Route element={<PublicRoute children={<Contactus />}/>} path={PATHS.CONTACTUS}/>
        <Route element={<PublicRoute children={<JobDetails />}/>} path={PATHS.JOBDETAILS}/>
        <Route element={<PrivateRoute children={<Jobs />}  />} path={PATHS.JOBS}  />
        <Route element={<PrivateRoute children={<Profile />}/>} path={PATHS.PROFILE}  />
        <Route element={<PrivateRoute children={<EditProfile />}/>} path={PATHS.EDITPROFILE}  />
        <Route element={<PrivateRoute children={<JobDetails />}/>} path={PATHS.JOBDETAILS}/>
        <Route element={<PrivateRoute children={<Experts />}/>} path={PATHS.EXPERTS} />
        <Route element={<PrivateRoute children={<Notification />}/>} path={PATHS.NOTIFICATION}/>
        <Route element={<PrivateRoute children={<Profile />}/>} path={PATHS.EXPERTCONNECT}/>

        <Route path="*" element={<PageNotFound />} />

      </Routes>
  );
};

export default Routers;

// import { Route , Navigate} from "react-router-dom";
// import { useSelector } from "react-redux";
// import { PATHS } from "./paths";
// import Layout from "../layout";


// const PrivateRoute= ({ component: Component, ...rest }) => {
//   const { isLoading, isAuthenticated } = useSelector((state) => state.auth);
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isLoading ? null : isAuthenticated ? (
//           <Layout>
//             <Component {...rest} {...props} />
//           </Layout>
//         ) : (
//           <Navigate
//             to={{ pathname: PATHS.LOGIN, state: { from: props.location } }}
//           />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;



import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from "../layout/index"
import { history } from '../utils/history';


export const PrivateRoute =({children})=>{
  const { loading, isAuthenticated } = useSelector((state) => state.auth);
    return (  loading ? null : isAuthenticated ?(  <Layout children={children} /> ) : <Navigate to="/login"  state={{ from: history.location }} /> );
}

export const PublicRoute= ({children}) => {
  return ( <Layout children={children} />);
};

import logo from './logo.svg';
import './App.css';
import Router from './routes/routers';
import { useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { history } from './utils/history';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <div className="App">
      <ToastContainer />
      <Router />
    </div>
  );
}

export default App;

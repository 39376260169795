import { createSlice } from "@reduxjs/toolkit";

const meetSlice = createSlice({
    name:"meet",
    initialState:{
      isLoading:false,
      meetgenrate:false
    },
    reducers:{
      meetRequestStart:(state)=>{
        state.isLoading = true
      },
      meetRequestSuccess:(state,action)=>{
        state.meetgenrate = true
        state.isLoading = false
      },
      meetRequestFail:(state,action)=>{
        state.isLoading = false;
        state.meetgenrate = false;
      }
    }
})

export const {meetRequestStart, meetRequestSuccess, meetRequestFail } = meetSlice.actions
export default meetSlice.reducer;
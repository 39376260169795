import React from 'react';
import './jobCard.css';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';

const JobCard = () => {
  const {jobList, isLoading} = useSelector(state => state?.jobsList)
  return (
    <div className='jobCardWrapper d-flex gap-4 flex-wrap'>
        {!isLoading && jobList?.jobsdata?.map((items, index) => (
            <div className='jobCard' key={`jobcard-${index}`}>
                <div className='innerJobCard' style={{background: items?.cardColor || "#FFE1CB" }}>
                    <div className='jobDate'>20 May, 2023</div>
                    <div className='companyName'>{items?.orgname || ""}</div>
                    <div className='profileName'>{items?.jobtitle || ""}</div>
                    <div className='companyLogo'>
                        <img src={items?.brandLogo || './building.png'} />
                    </div>
                    <ul className='m-0 p-0'>{items?.emptype.split(",").map((tags, index) => (
                    <li key={`jobcardlist-${index}`}>{tags}</li>
                    ))}
                    </ul>
                    
                </div> 
                <div className='d-flex justify-content-between jobPriceLocationWrap'>
                    <div className='jobPriceLocation'>
                    <div className='jobPrice'> {items?.currsymbol ? <span>{items?.currsymbol}</span> : <span>&#8377;</span>} {items?.salfrom - items?.salto || "0-0"}</div>
                    <div className='jobLocation'>{items?.joblocation || ""}</div>
                    </div> 
                    <Nav.Link className='btn btn-details' as={Link} to={`/job-details/${items?.id}`}>Details</Nav.Link>  
                </div> 
            </div>
        ))} 
    </div>
  )
}

export default JobCard
import { getDataApi } from "./apiMethod";

const apiConfig = {
  country: {
    method: "GET",
    path: "api/country",
  },
  cities:{
    method:"GET",
    path:"api/cities"
  },
};


export const getCountryListRequest = async(data)=>{
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  let {path} = apiConfig.country
  return await getDataApi({ path,headers });
};

export const getCitiesListRequest = async(data)=>{
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  const { name } = data;
  let { path } = apiConfig.cities;
  path = `${path}?name=${name}`;
  return await getDataApi({ path,headers });
};

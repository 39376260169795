import { getDataApi, postDataApi, getGoogleAuth, putDataApi } from "./apiMethod";

const apiConfig = {
  login: {
    method: "POST",
    path: "api/authenticate",
  },
  register: {
    method: "POST",
    path: "api/register",
  },
  sendOtp: {
    method: "POST",
    path: "api/sendotp",
  },
  verifyOtp: {
    method: "POST",
    path: "api/verifyotp",
  },
  forgotPasswordRequest: {
    method: "PUT",
    path: "api/forgot-password",
  },
  setPassword: {
    method: "PUT",
    path: "api/forgot-password",
  },
  profile: {
    method: "GET",
    path: "api/profile",
  },
  google: {
    method: "GET",
    path: "api/dashboard",
  },
};

export const loginRequest = async (data) => { 
  const { path } = apiConfig.login;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({ path, data, headers });
};

export const googleRequest = async(data)=>{
  const {path } = apiConfig.google;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await getGoogleAuth({ path,headers });
}

export const logoutRequest = async()=>{
      
}


export const verifyOtpRequest = async (data) => {
  const { path } = apiConfig.verifyOtp;
    const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
    return await postDataApi({ path, data,headers });
};

/* register password api call */
export const registerRequest = async (data) => {
  const { path } = apiConfig.register;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({ path, data, headers });
};

/* forgot password api call */
export const sendOtpRequest = async (data) => { 
  const { path } = apiConfig.sendOtp;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({ path, data, headers });
};

/* forgot password api call */
export const forgotPasswordRequest = async (data) => { 
  const { path } = apiConfig.forgotPasswordRequest;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({ path, data, headers });
};

/* set password api call */
export const setPasswordRequest = async (data) => {
  const { path } = apiConfig.setPassword;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({ path, data,headers });
};


import React from 'react'

const BannerSvg = () => {
  return (
    <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 750 500" style={{background: '0 0 750 500'}}>
        <g id="BACKGROUND">
        </g>
        <g id="OBJECTS">
        <g>
            <rect style={{fill:'#E8D7CF'}} width="750" height="500"/>
            <rect y="340.856" style={{fill:'#F2E9E4'}} width="750" height="159.144"/>
            <g>
            <path style={{fill:'#273238'}} d="M322.186,428.722h-10.671c-1.768,0-3.215-1.447-3.215-3.215l0,0c0-1.768,1.447-3.215,3.215-3.215
                h10.671c1.768,0,3.215,1.447,3.215,3.215l0,0C325.401,427.275,323.954,428.722,322.186,428.722z"/>
            <path style={{fill:'#FF7145'}} d="M531.768,439.701h-19.446c-5.199,0-9.453-4.254-9.453-9.453v-9.484
                c0-5.199,4.254-9.453,9.453-9.453h19.446c5.199,0,9.453,4.254,9.453,9.453v9.484
                C541.221,435.447,536.967,439.701,531.768,439.701z"/>
            <rect x="338.532" y="411.312" style={{fill:'#FFB74D'}} width="179.552" height="28.389"/>
            <rect x="338.532" y="432.366" style={{fill:'#F29A25'}} width="179.552" height="7.335"/>
            <rect x="338.532" y="418.147" style={{fill:'#F29A25'}} width="179.552" height="1"/>
            <rect x="500.714" y="411.312" style={{fill:'#FFFFFF'}} width="19.237" height="28.405"/>
            <rect x="504.453" y="411.311" style={{fill:'#E8D7CF'}} width="1" height="28.405"/>
            <rect x="515.212" y="411.311" style={{fill:'#E8D7CF'}} width="1" height="28.405"/>
            <polygon style={{fill:'#E0BDAF'}} points="318.221,422.009 318.221,429.004 338.532,439.701 338.532,411.312 			"/>
            </g>
            <g>
            <g>
                <polygon style={{fill:'#FFFFFF'}} points="131.905,191.116 87.501,191.116 82.96,116.298 136.446,116.298 				"/>
                <polygon style={{fill:'#E8D7CF'}} points="136.229,119.874 136.446,116.298 82.96,116.298 83.08,118.283 				"/>
            </g>
            <g>
                <polygon style={{fill:'#FF7145'}} points="82.381,110.269 81.336,116.298 138.069,116.298 137.025,110.269 				"/>
                <polygon style={{fill:'#FF7145'}} points="131.534,103.081 128.884,103.081 97.135,103.081 87.717,103.081 86.271,111.428 
                97.118,111.428 128.87,111.428 132.979,111.428 				"/>
                <rect x="86.472" y="109.769" style={{fill:'#E8551A'}} width="46.307" height="1"/>
            </g>
            <g>
                <polygon style={{fill:'#C99585'}} points="134.836,166.383 136.834,133.456 82.571,133.456 84.569,166.383 				"/>
                <path style={{fill:'#FFFFFF'}} d="M115.705,162.129c-6.732,0-12.21-5.477-12.21-12.21c0-6.732,5.478-12.209,12.21-12.209
                c6.732,0,12.21,5.477,12.21,12.209C127.914,156.652,122.437,162.129,115.705,162.129z M115.705,138.71
                c-6.181,0-11.21,5.028-11.21,11.209c0,6.181,5.029,11.21,11.21,11.21c6.181,0,11.21-5.029,11.21-11.21
                C126.914,143.738,121.886,138.71,115.705,138.71z"/>
            </g>
            </g>
            <g>
            <g>
                <rect x="54.471" y="191.116" style={{fill:'#FF7145'}} width="38.19" height="178.265"/>
                
                <rect x="92.661" y="191.116" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 192.4962 560.4969)" style={{fill:'#E8551A'}} width="7.174" height="178.265"/>
                <path style={{fill:'#FFFFFF'}} d="M83.794,322.933H63.338v-114.41h20.456V322.933z M64.338,321.933h18.456v-112.41H64.338V321.933z
                "/>
                <ellipse style={{fill:'#E8551A'}} cx="73.566" cy="347.387" rx="6.521" ry="6.652"/>
                <path style={{fill:'#FFFFFF'}} d="M73.566,357.81c-5.64,0-10.228-4.676-10.228-10.424c0-5.747,4.588-10.423,10.228-10.423
                c5.64,0,10.228,4.676,10.228,10.423C83.794,353.135,79.206,357.81,73.566,357.81z M73.566,337.964
                c-5.088,0-9.228,4.228-9.228,9.423c0,5.196,4.14,9.424,9.228,9.424c5.088,0,9.228-4.227,9.228-9.424
                C82.794,342.191,78.654,337.964,73.566,337.964z"/>
            </g>
            <g>
                
                <rect x="138.025" y="191.116" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 356.0345 560.4969)" style={{fill:'#F29A25'}} width="79.984" height="178.265"/>
                <rect x="99.835" y="191.116" style={{fill:'#FFB74D'}} width="38.19" height="178.265"/>
                <path style={{fill:'#FFFFFF'}} d="M129.158,322.933h-20.455v-114.41h20.455V322.933z M109.703,321.933h18.455v-112.41h-18.455
                V321.933z"/>
                <path style={{fill:'#F29A25'}} d="M125.451,347.387c0,3.674-2.919,6.652-6.521,6.652c-3.601,0-6.521-2.978-6.521-6.652
                s2.92-6.652,6.521-6.652C122.531,340.735,125.451,343.713,125.451,347.387z"/>
                <path style={{fill:'#FFFFFF'}} d="M118.93,357.81c-5.64,0-10.228-4.676-10.228-10.424c0-5.747,4.588-10.423,10.228-10.423
                c5.64,0,10.228,4.676,10.228,10.423C129.158,353.135,124.57,357.81,118.93,357.81z M118.93,337.964
                c-5.088,0-9.228,4.228-9.228,9.423c0,5.196,4.14,9.424,9.228,9.424s9.228-4.227,9.228-9.424
                C128.158,342.191,124.018,337.964,118.93,337.964z"/>
            </g>
            </g>
            <g>
            <g>
                <g>
                <path style={{fill:'#FFFFFF'}} d="M595.482,202.952h0.074l-0.078-0.078v-15.42h26.479v15.42l-0.078,0.078h0.074
                    c-0.042,7.333-5.927,13.271-13.235,13.414C601.409,216.223,595.524,210.285,595.482,202.952z"/>
                </g>
                <path style={{fill:'#FFFFFF'}} d="M583.663,103.081H383.584c-7.432,0-13.459,6.005-13.502,13.427h0.074l-0.078,0.078v50.411h0.05
                v35.877l-0.078,0.078h0.074c-0.043,7.422-6.069,13.427-13.502,13.427h200.079c7.433,0,13.459-6.005,13.502-13.427h-0.074
                l0.078-0.078v-50.411h-0.05v-35.877l0.078-0.078h-0.074C570.204,109.086,576.23,103.081,583.663,103.081z"/>
                <g>
                <path style={{fill:'#E0BDAF'}} d="M383.584,103.081h-1.64c0.277,0,0.547,0.025,0.82,0.041
                    C383.036,103.105,383.307,103.081,383.584,103.081z"/>
                <path style={{fill:'#E0BDAF'}} d="M595.482,202.952h0.075l-0.078-0.078v-35.877h0.05v-50.411l-0.078-0.078h0.074
                    c-0.041-7.146-5.633-12.961-12.682-13.386c-7.049,0.425-12.641,6.24-12.682,13.386h0.074l-0.078,0.078v35.877h0.05v50.411
                    l-0.078,0.078h0.074c-0.043,7.422-6.069,13.427-13.502,13.427h52.283C601.552,216.38,595.525,210.375,595.482,202.952z"/>
                </g>
            </g>
            <g>
                <g>
                <path style={{fill:'#F4ECE9'}} d="M553.756,83.849c1.914,0,3.471,1.489,3.471,3.318v170.35H213.103V87.167
                    c0-1.83,1.557-3.318,3.471-3.318H553.756"/>
                <polygon style={{fill:'#35ABE5'}} points="419.335,362.344 350.162,362.344 359.904,293.171 409.592,293.171 					"/>
                <polygon style={{fill:'#1086C1'}} points="414.067,324.945 409.592,293.171 359.904,293.171 356.887,314.598 					"/>
                <rect x="320.297" y="362.344" style={{fill:'#35ABE5'}} width="128.902" height="7.037"/>
                <path style={{fill:'#35ABE5'}} d="M557.226,94.618v-7.535c0-8.243-6.683-14.926-14.926-14.926H228.029
                    c-8.244,0-14.926,6.683-14.926,14.926v7.535H557.226z"/>
                <rect x="350.162" y="361.844" style={{fill:'#1086C1'}} width="69.173" height="1"/>
                </g>
                <path style={{fill:'#35ABE5'}} d="M213.103,255.907v38.191c0,9.172,7.436,16.608,16.609,16.608h310.907
                c9.173,0,16.608-7.436,16.608-16.608v-38.191H213.103z"/>
                <path style={{fill:'#FFFFFF'}} d="M385.477,290.86c-4.527,0-8.211-3.684-8.211-8.211c0-4.527,3.684-8.211,8.211-8.211
                c4.528,0,8.212,3.684,8.212,8.211C393.688,287.177,390.005,290.86,385.477,290.86z M385.477,275.439
                c-3.977,0-7.211,3.234-7.211,7.211c0,3.977,3.234,7.211,7.211,7.211c3.977,0,7.212-3.234,7.212-7.211
                C392.688,278.673,389.453,275.439,385.477,275.439z"/>
            </g>
            <g>
                <path style={{fill:'#E0BDAF'}} d="M498.935,42.303c7.432,0,13.459,6.005,13.502,13.427v16.427h-27.003V55.73
                C485.476,48.308,491.502,42.303,498.935,42.303z"/>
                <path style={{fill:'#FFFFFF'}} d="M285.428,55.73h-0.074c0.043-7.422,6.069-13.427,13.502-13.427h200.079
                c-7.432,0-13.459,6.005-13.502,13.427h0.074l-0.078,0.078v200.098H285.35V55.809L285.428,55.73z"/>
                <g>
                <g>
                    <path style={{fill:'#E0BDAF'}} d="M467.255,162.689h-15.676v-15.676h15.676V162.689z M452.579,161.689h13.676v-13.676h-13.676
                    V161.689z"/>
                    <polygon style={{fill:'#62A851'}} points="459.435,158.752 454.344,152.443 455.121,151.815 459.398,157.114 470.083,142.546 
                    470.89,143.138 						"/>
                    <g>
                    <rect x="408.216" y="138.213" style={{fill:'#3385E8'}} width="30.348" height="1"/>
                    <rect x="349.842" y="138.213" style={{fill:'#3385E8'}} width="48.092" height="1"/>
                    <rect x="372.064" y="149.084" style={{fill:'#E8D7CF'}} width="54.393" height="1"/>
                    <rect x="349.842" y="149.084" style={{fill:'#E8D7CF'}} width="13.101" height="1"/>
                    <rect x="392.13" y="156.086" style={{fill:'#E8D7CF'}} width="16.086" height="1"/>
                    <rect x="349.842" y="156.086" style={{fill:'#E8D7CF'}} width="32.006" height="1"/>
                    <rect x="398.971" y="163.088" style={{fill:'#FFB74D'}} width="35.115" height="1"/>
                    <rect x="349.842" y="163.088" style={{fill:'#FFB74D'}} width="41.666" height="1"/>
                    </g>
                    <g>
                    <defs>
                        <rect id="XMLID_6_" x="305.438" y="130.399" width="32.539" height="41.133"/>
                    </defs>
                    <use style={{overflow:'visible', fill:'#F2E9E4'}}/>
                    <clipPath id="XMLID_00000031916707873445173080000008876374159254011811_">
                        <use style={{overflow:'visible'}}/>
                    </clipPath>
                    <g style={{clipPath:'url(#XMLID_00000031916707873445173080000008876374159254011811_)'}}>
                        <g>
                        <rect x="316.927" y="150.799" style={{fill:'#FFA88D'}} width="9.561" height="18.206"/>
                        <path style={{fill:'#EA7759'}} d="M326.488,157.105c-4.183,0.369-7.361-0.14-9.56-1.404l0-4.902h9.561V157.105z"/>
                        <path style={{fill:'#FF7145'}} d="M341.134,167.683c-1.322-4.984-14.646-8.103-14.646-8.103c-3.187,1.786-6.374,1.786-9.56,0
                            c0,0-13.324,3.119-14.646,8.103c-1.322,4.984-2.441,14.951-2.441,14.951h21.867h21.867
                            C343.575,182.634,342.456,172.667,341.134,167.683z"/>
                        <polygon style={{fill:'#FFFFFF'}} points="316.927,157.334 315.251,160.023 318.961,164.021 320.601,160.894 									"/>
                        <polygon style={{fill:'#FFFFFF'}} points="326.496,157.334 328.172,160.023 324.462,164.021 322.822,160.894 									"/>
                        </g>
                        <g>
                        <path style={{fill:'#273238'}} d="M321.742,155.478h-0.069c-5.493,0-9.987-4.494-9.987-9.987v-1.315
                            c0-5.493,4.494-9.987,9.987-9.987h0.069c5.493,0,9.987,4.494,9.987,9.987v1.315
                            C331.729,150.984,327.235,155.478,321.742,155.478z"/>
                        <path style={{fill:'#FFA88D'}} d="M321.859,156.083h-0.302c-4.989,0-9.07-4.082-9.07-9.07v-0.302
                            c0-4.989,4.082-9.07,9.07-9.07h0.302c4.989,0,9.07,4.082,9.07,9.07v0.302C330.929,152.001,326.847,156.083,321.859,156.083
                            z"/>
                        <g>
                            <circle style={{fill:'#FFA88D'}} cx="312.486" cy="146.861" r="2.063"/>
                            <circle style={{fill:'#FFA88D'}} cx="330.929" cy="146.861" r="2.063"/>
                        </g>
                        <path style={{fill:'#273238'}} d="M328.866,138.507c0,1.406-3.205,2.175-7.159,2.175s-7.159-0.769-7.159-2.175
                            c0-1.407,3.205-2.547,7.159-2.547S328.866,137.1,328.866,138.507z"/>
                        <path style={{fill:'#273238'}} d="M312.564,144.81v2.459c0,0,1.112-0.432,1.186-4.109c0,0,2.041-0.901,2.082-3.19
                            c0.041-2.289-2.536,0.225-2.536,0.225l-0.995,2.514l-0.176,1.239L312.564,144.81z"/>
                        <path style={{fill:'#273238'}} d="M330.731,144.81v2.459c0,0-1.112-0.432-1.186-4.109c0,0-2.041-0.901-2.082-3.19
                            c-0.041-2.289,2.535,0.225,2.535,0.225l0.995,2.514l0.176,1.239L330.731,144.81z"/>
                        <g>
                            <circle style={{fill:'#273238'}} cx="317.617" cy="146.861" r="0.518"/>
                            <circle style={{fill:'#273238'}} cx="325.798" cy="146.861" r="0.518"/>
                            <path style={{fill:'#EA7759'}} d="M321.677,150.687c-0.416,0-0.82-0.128-1.212-0.385l0.274-0.418
                            c0.616,0.403,1.253,0.402,1.948-0.006l0.254,0.431C322.511,150.561,322.089,150.687,321.677,150.687z"/>
                            <rect x="316.948" y="144.956" style={{fill:'#273238'}} width="1.339" height="0.5"/>
                            <rect x="325.128" y="144.956" style={{fill:'#273238'}} width="1.339" height="0.5"/>
                            <g>
                            <path style={{fill:'#FFFFFF'}} d="M316.876,150.855c-2.202,0-3.994-1.792-3.994-3.994c0-2.202,1.792-3.994,3.994-3.994
                                c2.202,0,3.994,1.792,3.994,3.994C320.87,149.063,319.079,150.855,316.876,150.855z M316.876,143.117
                                c-2.064,0-3.744,1.679-3.744,3.744c0,2.064,1.679,3.744,3.744,3.744c2.064,0,3.744-1.679,3.744-3.744
                                C320.62,144.796,318.941,143.117,316.876,143.117z"/>
                            <path style={{fill:'#FFFFFF'}} d="M326.539,150.855c-2.202,0-3.994-1.792-3.994-3.994c0-2.202,1.791-3.994,3.994-3.994
                                c2.202,0,3.994,1.792,3.994,3.994C330.532,149.063,328.741,150.855,326.539,150.855z M326.539,143.117
                                c-2.064,0-3.744,1.679-3.744,3.744c0,2.064,1.679,3.744,3.744,3.744c2.064,0,3.744-1.679,3.744-3.744
                                C330.282,144.796,328.603,143.117,326.539,143.117z"/>
                            <path style={{fill:'#FFFFFF'}} d="M320.4,145.24l-0.125-0.216c0.95-0.552,1.914-0.552,2.864,0l-0.125,0.216
                                C322.134,144.73,321.28,144.73,320.4,145.24z"/>
                            </g>
                            <path style={{fill:'#EA7759'}} d="M321.323,148.333c-0.021-0.012-0.501-0.308-0.78-0.689
                            c-0.313-0.43,0.309-1.412,0.708-1.958l0.404,0.295c-0.412,0.565-0.746,1.224-0.7,1.383
                            c0.211,0.287,0.624,0.541,0.628,0.544L321.323,148.333z"/>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                <g>
                    <path style={{fill:'#E0BDAF'}} d="M467.255,93.097h-15.676V77.42h15.676V93.097z M452.579,92.097h13.676V78.42h-13.676V92.097z"
                    />
                    <g>
                    <rect x="372.602" y="70.619" style={{fill:'#3385E8'}} width="65.961" height="1"/>
                    <rect x="349.842" y="70.619" style={{fill:'#3385E8'}} width="14.801" height="1"/>
                    <rect x="399.468" y="81.49" style={{fill:'#E8D7CF'}} width="9.743" height="1"/>
                    <rect x="349.842" y="81.49" style={{fill:'#E8D7CF'}} width="42.301" height="1"/>
                    <rect x="376.085" y="88.492" style={{fill:'#E8D7CF'}} width="51.036" height="1"/>
                    <rect x="349.842" y="88.492" style={{fill:'#E8D7CF'}} width="19.03" height="1"/>
                    <rect x="398.971" y="95.494" style={{fill:'#FFB74D'}} width="16.21" height="1"/>
                    <rect x="349.842" y="95.494" style={{fill:'#FFB74D'}} width="41.666" height="1"/>
                    </g>
                    <g>
                    <defs>
                        <rect id="XMLID_5_" x="305.184" y="63.388" width="32.54" height="41.133"/>
                    </defs>
                    <use style={{overflow:'visible', fill:'#F2E9E4'}}/>
                    <clipPath id="XMLID_00000023968526196465508790000012875760129507976377_">
                        <use style={{overflow:'visible'}}/>
                    </clipPath>
                    <g style={{clipPath:'url(#XMLID_00000023968526196465508790000012875760129507976377_)'}}>
                        <g>
                        <rect x="311.432" y="76.614" style={{fill:'#273238'}} width="20.043" height="23.483"/>
                        <rect x="317.363" y="84.031" style={{fill:'#FFA88D'}} width="8.181" height="18.206"/>
                        <path style={{fill:'#EA7759'}} d="M325.544,90.337c-3.579,0.369-6.299-0.14-8.181-1.404l0-4.902h8.181L325.544,90.337z"/>
                        <path style={{fill:'#8ED182'}} d="M338.528,100.914c-1.162-4.984-12.873-8.103-12.873-8.103c-2.801,1.785-5.602,1.785-8.403,0
                            c0,0-11.711,3.119-12.873,8.103c-1.162,4.984-2.146,14.951-2.146,14.951h19.221h19.221
                            C340.674,115.865,339.691,105.898,338.528,100.914z"/>
                        </g>
                        <g>
                        <path style={{fill:'#273238'}} d="M321.488,87.88h-0.069c-5.493,0-9.987-4.494-9.987-9.987v-1.315
                            c0-5.493,4.494-9.987,9.987-9.987h0.069c5.493,0,9.987,4.494,9.987,9.987v1.315
                            C331.475,83.386,326.981,87.88,321.488,87.88z"/>
                        <path style={{fill:'#FFA88D'}} d="M321.605,89.314h-0.302c-4.989,0-9.07-4.082-9.07-9.07v-0.302c0-4.989,4.082-9.07,9.07-9.07
                            h0.302c4.989,0,9.07,4.082,9.07,9.07v0.302C330.675,85.232,326.593,89.314,321.605,89.314z"/>
                        <g>
                            <circle style={{fill:'#FFA88D'}} cx="312.232" cy="80.092" r="2.063"/>
                            <path style={{fill:'#FFA88D'}} d="M332.737,80.092c0,1.139-0.923,2.063-2.063,2.063s-2.063-0.923-2.063-2.063
                            s0.923-2.063,2.063-2.063S332.737,78.953,332.737,80.092z"/>
                        </g>
                        <g>
                            <circle style={{fill:'#273238'}} cx="317.363" cy="80.611" r="0.518"/>
                            <path style={{fill:'#273238'}} d="M326.062,80.611c0,0.286-0.232,0.518-0.518,0.518c-0.286,0-0.518-0.232-0.518-0.518
                            s0.232-0.518,0.518-0.518C325.83,80.092,326.062,80.324,326.062,80.611z"/>
                            <path style={{fill:'#EA7759'}} d="M321.423,83.746c-0.415,0-0.82-0.128-1.212-0.385l0.273-0.418
                            c0.617,0.403,1.254,0.402,1.949-0.006l0.253,0.431C322.257,83.62,321.835,83.746,321.423,83.746z"/>
                            <path style={{fill:'#273238'}} d="M316.8,79.075l-0.214-0.452c0.516-0.244,1.039-0.244,1.553,0l-0.214,0.452
                            C317.547,78.895,317.179,78.896,316.8,79.075z"/>
                            <path style={{fill:'#273238'}} d="M324.981,79.075l-0.214-0.452c0.516-0.244,1.039-0.244,1.553,0l-0.214,0.452
                            C325.727,78.895,325.36,78.896,324.981,79.075z"/>
                            <path style={{fill:'#EA7759'}} d="M321.088,81.575c-0.02-0.01-0.502-0.252-0.783-0.566c-0.332-0.371,0.202-1.123,0.708-1.688
                            l0.372,0.333c-0.372,0.417-0.699,0.923-0.686,1.063c0.16,0.161,0.497,0.353,0.611,0.41L321.088,81.575z"/>
                            <path style={{fill:'#EA7759'}} d="M316.845,81.678c0,0.245-0.452,0.444-1.01,0.444c-0.558,0-1.01-0.199-1.01-0.444
                            c0-0.245,0.452-0.445,1.01-0.445C316.392,81.233,316.845,81.432,316.845,81.678z"/>
                            <path style={{fill:'#EA7759'}} d="M328.22,81.678c0,0.245-0.452,0.444-1.01,0.444c-0.558,0-1.01-0.199-1.01-0.444
                            c0-0.245,0.452-0.445,1.01-0.445C327.767,81.233,328.22,81.432,328.22,81.678z"/>
                        </g>
                        <path style={{fill:'#FFFFFF'}} d="M312.577,82.155c0,0.393-0.318,0.711-0.711,0.711c-0.393,0-0.711-0.318-0.711-0.711
                            c0-0.393,0.318-0.711,0.711-0.711C312.259,81.444,312.577,81.762,312.577,82.155z"/>
                        <path style={{fill:'#FFFFFF'}} d="M331.751,82.155c0,0.393-0.318,0.711-0.711,0.711c-0.393,0-0.711-0.318-0.711-0.711
                            c0-0.393,0.318-0.711,0.711-0.711C331.433,81.444,331.751,81.762,331.751,82.155z"/>
                        <path style={{fill:'#273238'}} d="M314.824,71.554c0,0,5.255,6.476,15.851,6.476
                            C329.501,70.328,322.774,64.729,314.824,71.554z"/>
                        <path style={{fill:'#273238'}} d="M312.443,77.999c0,0,3.087-1.929,4.061-5.598c0.927-3.112-2.923-0.638-2.923-0.638
                            l-1.596,3.118L312.443,77.999z"/>
                        </g>
                    </g>
                    </g>
                </g>
                <g>
                    <path style={{fill:'#E0BDAF'}} d="M467.255,231.234h-15.676v-15.676h15.676V231.234z M452.579,230.234h13.676v-13.676h-13.676
                    V230.234z"/>
                    <g>
                    <rect x="389.518" y="204.981" style={{fill:'#3385E8'}} width="49.046" height="1"/>
                    <rect x="349.842" y="204.981" style={{fill:'#3385E8'}} width="30.348" height="1"/>
                    <rect x="416.528" y="215.852" style={{fill:'#E8D7CF'}} width="9.929" height="1"/>
                    <rect x="372.064" y="215.852" style={{fill:'#E8D7CF'}} width="37.23" height="1"/>
                    <rect x="349.842" y="215.852" style={{fill:'#E8D7CF'}} width="13.101" height="1"/>
                    <rect x="401.955" y="222.854" style={{fill:'#E8D7CF'}} width="33.209" height="1"/>
                    <rect x="349.842" y="222.854" style={{fill:'#E8D7CF'}} width="45.273" height="1"/>
                    <rect x="380.065" y="229.856" style={{fill:'#FFB74D'}} width="41.293" height="1"/>
                    <rect x="349.842" y="229.856" style={{fill:'#FFB74D'}} width="19.651" height="1"/>
                    </g>
                    <g>
                    <defs>
                        <rect id="XMLID_4_" x="305.305" y="197.352" width="32.54" height="41.133"/>
                    </defs>
                    <use style={{overflow:'visible', fill:'#F2E9E4'}}/>
                    <clipPath id="XMLID_00000183242114999346554110000016672239352323763331_">
                        <use style={{overflow:'visible'}}/>
                    </clipPath>
                    <g style={{clipPath:'url(#XMLID_00000183242114999346554110000016672239352323763331_)'}}>
                        <g>
                        <rect x="316.794" y="218.319" style={{fill:'#FFA88D'}} width="9.561" height="18.206"/>
                        <path style={{fill:'#EA7759'}} d="M326.355,224.625c-4.183,0.369-7.361-0.14-9.561-1.404v-4.902h9.561L326.355,224.625z"/>
                        <path style={{fill:'#FFFFFF'}} d="M341.001,235.202c-1.322-4.984-14.646-8.103-14.646-8.103c-3.187,1.786-6.374,1.786-9.561,0
                            c0,0-13.324,3.119-14.646,8.103c-1.322,4.984-2.441,14.951-2.441,14.951h21.867h21.867
                            C343.442,250.153,342.323,240.186,341.001,235.202z"/>
                        <path style={{fill:'#E0BDAF'}} d="M318.564,231.477l2.305-3.078c0,0-2.599-0.583-3.958-1.299l-2.018,0.76L318.564,231.477z"/>
                        <path style={{fill:'#E0BDAF'}} d="M324.598,231.477l-2.303-3.078c0,0,2.597-0.583,3.954-1.299l2.016,0.76L324.598,231.477z"/>
                        <path style={{fill:'#273238'}} d="M315.706,227.38c-3.202,0.867-12.466,3.708-13.557,7.822
                            c-1.322,4.984-2.441,14.951-2.441,14.951h15.998V227.38z"/>
                        <path style={{fill:'#273238'}} d="M327.432,250.153h16.01c0,0-1.119-9.967-2.441-14.951
                            c-1.093-4.119-10.378-6.962-13.569-7.826V250.153z"/>
                        <polygon style={{fill:'#FFFFFF'}} points="316.912,225.647 315.329,227.481 318.514,231.073 320.869,228.399 									"/>
                        <polygon style={{fill:'#FFFFFF'}} points="326.249,225.647 327.829,227.481 324.647,231.073 322.294,228.399 									"/>
                        <path style={{fill:'#FF7145'}} d="M323.553,250.153l-0.964-20.184c0.272-0.114,0.483-0.286,0.63-0.52l-0.925-1.05
                            l-0.786,0.036l-0.638-0.036l-0.925,1.05c0.147,0.234,0.358,0.406,0.63,0.52l-0.964,20.184h1.965v-1.75l0.013,1.75H323.553z
                            "/>
                        <rect x="320.574" y="229.844" style={{fill:'#E2612D'}} width="2.017" height="0.25"/>
                        </g>
                        <g>
                        <path style={{fill:'#F29A25'}} d="M321.609,222.997h-0.069c-5.493,0-9.987-4.494-9.987-9.987l0.113-0.574
                            c-0.311-1.45-1.089-4.435,1.228-5.103c0.513-3.7,4.509-5.625,8.647-5.625h0.069c5.493,0,9.987,4.494,9.987,9.987v1.314
                            C331.596,218.503,327.102,222.997,321.609,222.997z"/>
                        <path style={{fill:'#FFA88D'}} d="M321.726,223.602h-0.302c-4.989,0-9.07-4.082-9.07-9.07v-0.302
                            c0-4.989,4.082-9.07,9.07-9.07h0.302c4.989,0,9.07,4.082,9.07,9.07v0.302C330.796,219.52,326.714,223.602,321.726,223.602z
                            "/>
                        <g>
                            <circle style={{fill:'#FFA88D'}} cx="312.353" cy="214.38" r="2.063"/>
                            <circle style={{fill:'#FFA88D'}} cx="330.796" cy="214.38" r="2.063"/>
                        </g>
                        <g>
                            <circle style={{fill:'#273238'}} cx="317.484" cy="214.638" r="0.518"/>
                            <path style={{fill:'#273238'}} d="M326.183,214.638c0,0.286-0.232,0.518-0.518,0.518c-0.286,0-0.518-0.232-0.518-0.518
                            s0.232-0.518,0.518-0.518C325.951,214.12,326.183,214.352,326.183,214.638z"/>
                            <path style={{fill:'#EA7759'}} d="M321.544,218.206c-0.415,0-0.82-0.128-1.212-0.385l0.275-0.418
                            c0.615,0.403,1.253,0.401,1.948-0.006l0.253,0.431C322.378,218.08,321.956,218.206,321.544,218.206z"/>
                            <rect x="316.815" y="213.102" style={{fill:'#273238'}} width="1.339" height="0.5"/>
                            <rect x="324.996" y="213.102" style={{fill:'#273238'}} width="1.339" height="0.5"/>
                            <path style={{fill:'#EA7759'}} d="M321.19,216.169c-0.02-0.012-0.501-0.308-0.78-0.69c-0.313-0.43,0.309-1.411,0.708-1.958
                            l0.404,0.295c-0.412,0.565-0.746,1.224-0.7,1.382c0.212,0.287,0.624,0.542,0.628,0.544L321.19,216.169z"/>
                        </g>
                        <path style={{fill:'#F29A25'}} d="M320.107,204.052l1.502-2.344c0,0,5.101,0,5.909,0c2.892,0,3.938,1.743,3.147,3.341
                            c-0.797,1.514-2.273,2.973-5,2.983c-2.488,0-10.118,0-10.118,0s-1.131,3.317-2.989,4.286l-0.717-2.994l2.764-3.281
                            l3.55-2.021L320.107,204.052z"/>
                        <path style={{fill:'#F29A25'}} d="M327.259,207.597c0.45,2.04,1.66,3.559,3.341,4.756l0.453-1.294l-1.285-3.648l-1.347-1.244
                            c0,0-1.928,0.238-1.907,0.368C326.533,206.664,327.259,207.597,327.259,207.597z"/>
                        </g>
                    </g>
                    </g>
                </g>
                <rect x="305.184" y="117.052" style={{fill:'#F2E9E4'}} width="161.571" height="1"/>
                <rect x="305.184" y="183.877" style={{fill:'#F2E9E4'}} width="161.571" height="1"/>
                </g>
                <rect x="285.35" y="253.847" style={{fill:'#E8D7CF'}} width="200.079" height="2.059"/>
                <polygon style={{fill:'#E0BDAF'}} points="485.433,94.618 489.64,255.907 485.429,255.907 				"/>
            </g>
            </g>
            <g>
            <g>
                <rect x="208.576" y="205.118" style={{fill:'#FFA88D'}} width="18.107" height="43.363"/>
                <path style={{fill:'#FFA88D'}} d="M225.736,222.168L225.736,222.168c-6.528,0-11.868-5.341-11.868-11.868v-9.373
                c0-6.528,5.341-11.868,11.868-11.868h0c6.528,0,11.868-8.686,11.868-2.158v11.347c1.154,2.932,2.388,4.759,1.762,5.954
                c-0.626,1.195-1.762,2.954-1.762,2.954C237.545,218.051,232.264,222.168,225.736,222.168z"/>
                <path style={{fill:'#273238'}} d="M208.576,215.698c6.602,0,11.213-0.683,16.531-7.082c-2.834-2.897-2.989-6.864-1.733-8.55
                c0.774-1.04,2.846-1.442,4.006,1.409c1.806-2.84,2.826-5.859,2.921-9.089c5.99-1.208,10.414-3.48,8.852-10.385
                c-2.921-8.277-23.861-7.954-30.506,5.858C198.175,191.615,204.393,204.639,208.576,215.698z"/>
                <path style={{fill:'#EA7759'}} d="M226.683,222.13v-7.608l2.519,7.127C228.383,221.9,227.544,222.062,226.683,222.13z"/>
                <path style={{fill:'#273238'}} d="M226.683,197.429l0.696,4.046c0.44,1.436,0.412,2.897,0,4.377
                c2.625-1.126,3.303-6.217,2.921-13.466L226.683,197.429z"/>
                <polygon style={{fill:'#EA7759'}} points="237.604,207.155 237.604,202.854 238.53,205.667 				"/>
            </g>
            <path style={{fill:'#FFA88D'}} d="M240.277,237.795c0,0,6.957-4.646,18.156-4.614c11.199,0.032-4.565,15.304-4.565,15.304
                L240.277,237.795z"/>
            <g>
                <g>
                <rect x="231.897" y="430.617" style={{fill:'#FFFFFF'}} width="12.2" height="16.715"/>
                <polygon style={{fill:'#FFFFFF'}} points="194.981,447.332 182.312,447.332 185.533,430.617 198.202,430.617 					"/>
                <polygon style={{fill:'#273238'}} points="251.791,298.331 196.696,298.331 177.542,437.575 203.368,437.575 223.324,321.432 
                    226.611,437.575 251.791,437.575 					"/>
                
                    <rect x="216.114" y="309.786" transform="matrix(0.5016 -0.8651 0.8651 0.5016 -166.7441 345.676)" style={{fill:'#666666'}} width="1" height="15.512"/>
                <path style={{fill:'#273238'}} d="M231.453,441.546h13.092c0,0,1.904,3.958,4.416,5.024c2.512,1.066,8.264,1.416,8.264,6.547
                    h-23.908c-1.599,0-2.474-1.161-2.474-2.284L231.453,441.546z"/>
                <path style={{fill:'#273238'}} d="M182.958,441.546h13.45c0,0,0.685,3.958,3.197,5.024c2.512,1.066,5.784,2.132,5.861,6.547
                    h-21.505c-1.599,0-2.689-0.979-2.393-2.797L182.958,441.546z"/>
                <rect x="178.354" y="430.952" style={{fill:'#666666'}} width="26.065" height="1"/>
                <rect x="226.44" y="430.952" style={{fill:'#666666'}} width="25.351" height="1"/>
                </g>
                <g>
                <polygon style={{fill:'#FFFFFF'}} points="195.693,302.671 194.484,309.805 252.579,307.515 252.494,302.671 					"/>
                <path style={{fill:'#8ED182'}} d="M195.005,303.43l-4.806-56.973c-0.964-11.472,9.223-17.426,18.764-17.426
                    c3.928,0,18.401,0,18.401,0c15.675,0.43,26.041,6.456,26.041,18.939l-0.218,55.396L195.005,303.43z"/>
                <polygon style={{fill:'#6EB75C'}} points="253.187,265.01 242.029,258.485 253.119,270.302 					"/>
                <polygon style={{fill:'#FFFFFF'}} points="207.668,222.732 205.62,229.378 230.399,229.269 227.241,222.732 					"/>
                <rect x="194.603" y="299.164" style={{fill:'#62A851'}} width="58.599" height="1"/>
                </g>
            </g>
            <g>
                <g>
                
                    <rect x="284.824" y="187.573" transform="matrix(0.8406 0.5416 -0.5416 0.8406 154.8539 -127.6004)" style={{fill:'#C99585'}} width="18.838" height="23.504"/>
                <path style={{fill:'#273238'}} d="M282.826,200.36c-0.666-0.429-1.552-0.254-2.005,0.396l-33.233,47.721
                    c-1.146,1.779-0.633,4.151,1.146,5.297l14.737,9.495c1.779,1.146,4.151,0.633,5.297-1.146l29.719-49.985
                    c0.405-0.681,0.198-1.561-0.468-1.99L282.826,200.36z"/>
                <path style={{fill:'#273238'}} d="M349.273,113.915c-22.654-14.596-52.85-8.064-67.446,14.59
                    c-14.596,22.654-8.064,52.85,14.59,67.446c22.654,14.596,52.85,8.064,67.446-14.59
                    C378.459,158.708,371.927,128.511,349.273,113.915z M303.288,185.287c-16.764-10.801-21.598-33.147-10.797-49.91
                    c10.801-16.764,33.147-21.598,49.91-10.797c16.764,10.801,21.597,33.147,10.796,49.91
                    C342.398,191.254,320.052,196.088,303.288,185.287z"/>
                </g>
                <g>
                <path style={{fill:'#8ED182'}} d="M283.437,257.797l0.002-0.005l-38.178-24.521l-0.006,0.006
                    c-3.602-2.285-8.322-2.523-12.245-0.201c-5.565,3.295-7.406,10.479-4.11,16.044c1.166,1.97,2.825,3.462,4.721,4.436
                    l-0.002,0.01l39.366,23.021c0.496,0.345,1.014,0.654,1.555,0.91l0.004,0.002l0,0c3.134,1.475,6.919,1.422,10.124-0.475
                    c5.139-3.043,6.839-9.676,3.796-14.816C287.251,260.161,285.466,258.671,283.437,257.797z"/>
                <path style={{fill:'#FFA88D'}} d="M281.89,229.543l2.144-9.645c0.455-2.169,3.817-1.914,4.012,0
                    c0.378,2.805-0.216,7.832,0.95,8.204l8.669-14.581c2.927,6.049,3.502,12.472-0.737,19.66
                    C291.457,244.507,280.164,237.843,281.89,229.543z"/>
                <path style={{fill:'#8ED182'}} d="M297.883,232.884c0,0-6.794,35.394-8.605,38.645c-0.9,1.616-2.905,4.484-4.611,5.494
                    c-5.139,3.043-11.773,1.344-14.816-3.796c-2.121-3.582,0.107-11.2,0.107-11.2l11.016-32.649L297.883,232.884z"/>
                <polygon style={{fill:'#6EB75C'}} points="274.41,248.836 280.541,255.91 273.542,251.399 					"/>
                
                    <rect x="287.952" y="226.063" transform="matrix(0.2586 -0.966 0.966 0.2586 -13.0963 452.8301)" style={{fill:'#62A851'}} width="1" height="17.768"/>
                </g>
            </g>
            </g>
            <g>
            <g>
                <path style={{fill:'#6EB75C'}} d="M619.939,293.478c0,0,10.672-127.637,35.075-127.093c24.404,0.544,17.89,133.442,17.89,133.442
                L619.939,293.478z"/>
                <path style={{fill:'#569944'}} d="M647.326,316.023c-8.931-67.451,7.439-142.831,7.605-143.584l0.977,0.216
                c-0.166,0.752-16.497,75.965-7.59,143.237L647.326,316.023z"/>
                <path style={{fill:'#8ED182'}} d="M641.77,284.237c0,0,34.622-90.17,50.82-84.837c16.198,5.332-16.777,100.149-16.777,100.149
                L641.77,284.237z"/>
                <path style={{fill:'#8ED182'}} d="M607.625,302.984c0,0-24.659-93.866-2.782-102.078c21.877-8.211,51.648,87.905,51.648,87.905
                L607.625,302.984z"/>
                <path style={{fill:'#62A851'}} d="M643.827,307.658c-17.955-31.346-29.639-63.676-34.727-96.094l0.988-0.155
                c5.068,32.297,16.711,64.513,34.605,95.753L643.827,307.658z"/>
                <path style={{fill:'#62A851'}} d="M653.9,307.67l-0.854-0.52c16.889-27.713,27.877-56.29,32.659-84.938l0.986,0.165
                C681.889,251.151,670.856,279.849,653.9,307.67z"/>
            </g>
            <g>
                <path style={{fill:'#FFB74D'}} d="M662.604,369.381c18.753-8.043,31.89-26.671,31.89-48.369c0-12.074-4.07-23.195-10.909-32.073
                h-83.398c-6.839,8.878-10.909,20-10.909,32.073c0,21.698,13.137,40.326,31.89,48.369H662.604z"/>
                <path style={{fill:'#F29A25'}} d="M590.631,332.914c3.805,16.447,15.322,29.941,30.537,36.466h41.436
                c15.215-6.526,26.732-20.019,30.536-36.466H590.631z"/>
                <polygon style={{fill:'#FFFFFF'}} points="679.824,314.867 669.83,301.549 657.293,314.769 644.535,301.492 631.434,314.754 
                618.174,301.5 606.126,314.789 593.685,301.112 594.425,300.439 606.124,313.301 618.139,300.051 631.43,313.336 
                644.545,300.059 657.289,313.321 669.92,300.002 679.84,313.223 689.81,300.468 690.597,301.083 				"/>
                <rect x="596.529" y="293.852" style={{fill:'#FFFFFF'}} width="90.47" height="1"/>
                <polygon style={{fill:'#6EB75C'}} points="648.906,266.735 656.491,288.939 661.912,288.939 649.755,264.743 				"/>
            </g>
            </g>
            <g>
            <g>
                
                <rect x="528.136" y="198.395" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1073.7 438.5253)" style={{fill:'#FFA88D'}} width="17.428" height="41.736"/>
                <path style={{fill:'#FFA88D'}} d="M528.065,213.84L528.065,213.84c6.082,1.577,12.348-2.109,13.924-8.19l2.264-8.732
                c1.577-6.082-2.109-12.348-8.19-13.924l0,0c-6.081-1.577-8.959-10.959-10.536-4.878l-2.276,8.792
                c-1.783,2.453-3.374,3.857-3.08,5.122s0.928,3.178,0.928,3.178C518.523,205.372,521.983,212.263,528.065,213.84z"/>
                <path style={{fill:'#273238'}} d="M545.615,211.958c-6.234-0.688-12.195-0.695-13.242-10.031c3.34-2.014,3.994-6.232,3.232-8.107
                c-0.47-1.156-2.816-2.11-4.585,0.265c-0.996-3.082-0.704-6.062-0.013-9.094c-5.289-2.573-8.862-5.757-5.738-11.814
                c4.721-7.006,24.152-1.646,27.006,12.827C561.123,192.033,552.183,202.665,545.615,211.958z"/>
                <path style={{fill:'#273238'}} d="M533.159,190.563l-2.139,3.521c-0.757,1.232-1.156,2.614-1.13,4.093
                c-2.173-1.683-0.99-6.525,1.116-13.187L533.159,190.563z"/>
                <polygon style={{fill:'#EA7759'}} points="521.109,195.194 522.148,191.187 520.606,193.583 				"/>
                <path style={{fill:'#273238'}} d="M521.099,195.207c1.647,0.706,0.361,4.321,1.151,4.738c1.112,0.587,5.504-4.597,7.253-9.219
                l0.387,7.452c0,0-1.8,14.189-2.002,15.615C520.791,213.156,516.326,207.834,521.099,195.207z"/>
            </g>
            <g>
                <g>
                <polygon style={{fill:'#FFFFFF'}} points="504.814,390.02 505.318,401.063 516.237,401.063 516.176,390.02 					"/>
                <path style={{fill:'#273238'}} d="M517.091,399.842h-12.704c0,0-6.815,3.924-9.252,4.98c-3.233,1.438-10.063,1.403-10.063,6.49
                    l30.21,0c1.551,0,2.401-1.151,2.401-2.264L517.091,399.842z"/>
                <path style={{fill:'#273238'}} d="M568.922,428.246h-14.743c0,0-0.751,3.924-3.505,4.98c-2.754,1.056-8.349,2.113-8.432,6.49
                    l25.58,0c1.752,0,2.948-0.971,2.623-2.773L568.922,428.246z"/>
                <path style={{fill:'#273238'}} d="M564.473,286.28h-53.46l-15.037,40.451c-2.902,7.172-3.425,15.137-2.285,23.65l6.109,44.552
                    h23.786l-6.002-52.702l20.042-32.453l12.416,119.691h22.811L564.473,286.28z"/>
                
                    <rect x="536.489" y="305.122" transform="matrix(0.8606 -0.5093 0.5093 0.8606 -79.7245 319.9821)" style={{fill:'#666666'}} width="16.307" height="1.001"/>
                </g>
                <g>
                <g>
                    <path style={{fill:'#FF7145'}} d="M568.975,275.891l-0.005,0.002l-19.467-39.34l0.007-0.006
                    c-1.81-3.709-1.574-8.294,1.006-11.853c3.661-5.049,9.561-5.948,14.503-2.208c1.749,1.323,4.166,3.954,4.166,3.954
                    l18.584,41.224c0.278,0.514,0.52,1.045,0.708,1.593l0.002,0.004l-0.001,0c1.089,3.176,0.67,6.828-1.438,9.736
                    c-3.38,4.663-9.821,5.643-14.385,2.19C570.838,279.813,569.602,277.939,568.975,275.891z"/>
                    <path style={{fill:'#FF7145'}} d="M548.168,295.22c0,0,32.628-10.714,35.488-12.843c1.637-0.967,3.898-3.328,4.655-5.092
                    c2.281-5.314-0.087-11.511-5.288-13.841c-3.625-1.624-10.56,1.42-10.56,1.42l-29.551,14.463L548.168,295.22z"/>
                    <polygon style={{fill:'#E55028'}} points="567.808,288.564 570.568,238.638 572.854,286.772 						"/>
                </g>
                <path style={{fill:'#FF7145'}} d="M567.272,298.271l3.505-63.412c0.936-11.372-8.949-17.274-18.207-17.274
                    c-3.811,0-17.855,0-17.855,0c-6.865,0.108-20.132,0.438-29.146-2.355l3.878,21.129l-0.61,52.793L567.272,298.271z"/>
                <polygon style={{fill:'#E55028'}} points="509.402,240.271 514.4,242.202 509.375,242.558 					"/>
                
                    <rect x="537.682" y="260.131" transform="matrix(0.1594 -0.9872 0.9872 0.1594 166.2764 774.8998)" style={{fill:'#E8551A'}} width="0.999" height="59.353"/>
                <g>
                    <path style={{fill:'#FFA88D'}} d="M461.234,184.146c0,0-5.879-6.006-3.919-10.01c1.96-4.004,8.696-6.757,8.696-6.757
                    l-1.516-6.746c-0.771-2.595,2.618-3.806,3.652-1.401l7.05,22.662L461.234,184.146z"/>
                    <path style={{fill:'#FF7145'}} d="M483.299,208.281l0.002-0.005l42.105,13.147l-0.001,0.009c3.952,1.259,7.09,4.675,7.832,9.129
                    c1.053,6.321-3.108,12.317-9.295,13.393c-2.19,0.381-4.334,0.083-6.245-0.726l-0.006,0.007l-41.74-15.007
                    c-0.567-0.154-1.12-0.347-1.649-0.593l-0.004-0.002l0,0c-3.058-1.424-5.395-4.32-6.001-7.959
                    c-0.973-5.837,2.87-11.374,8.583-12.368C479.156,206.911,481.379,207.306,483.299,208.281z"/>
                    <path style={{fill:'#FF7145'}} d="M459.274,183.713c0,0,7.559,34.881,9.405,38.052c0.807,1.791,2.94,4.362,4.622,5.315
                    c5.068,2.872,11.455,1.002,14.266-4.175c1.959-3.608-0.41-11.095-0.41-11.095l-11.579-32.044L459.274,183.713z"/>
                    <polygon style={{fill:'#E55028'}} points="486.208,209.184 481.881,213.949 488.991,210.053 						"/>
                    
                    <rect x="460.148" y="185" transform="matrix(0.9722 -0.2343 0.2343 0.9722 -30.4153 114.9559)" style={{fill:'#E8551A'}} width="17.019" height="1.001"/>
                </g>
                </g>
            </g>
            </g>
        </g>
        </g>
        </svg>
  )
}

export default BannerSvg
import { call, put } from "redux-saga/effects"
import {errorAlert} from '../../utils/alerts'
import * as services from  '../../services'
import { meetRequestSuccess, meetRequestFail } from "../Slice/meetandslotSlice";
import { connectClicked } from "../Slice/profileSlice";
import { meetAccept, meetReject } from "../Slice/notificationSlice";
import { loginFail } from "../Slice/authSlice";

export function* meetConnectAcceptRejectRequest(action) {
    const response = yield call( services.meetConnectAcceptRejectRequest, action.payload);
   const { status, message, result } = response.data;
   if(response.status === 201 || 200){
    yield put(meetRequestSuccess(result));
    yield put(connectClicked(false));
    yield put(meetAccept(false));
    yield put(meetReject(false))
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(meetRequestFail());
    yield put(loginFail())
    action.callback(false);
   }
   else{
    errorAlert(message);
    yield put(meetRequestFail());
   }
}
import { toast } from "react-toastify";

export const checkConnection = () => {
  if (!navigator.onLine) {
    //check if user is online or not
    errorAlert("Please check your internet connection.");
    return false;
  }
  return true;
};

export const successAlert = (message) => {
  return toast(message, {
    toastId: message,
    type: "success",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      fontFamily: "inherit",
      fontSize: "15px",
      fontWeight: 500,
      marginBottom: "7px",
      display: "inlineBlock",
      color: "#344054",
    },
  });
};

export const errorAlert = (message) => {
  return toast(message, {
    toastId: message,
    type: "error",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      fontFamily: "inherit",
      fontSize: "15px",
      fontWeight: 500,
      marginBottom: "7px",
      display: "inlineBlock",
      color: "#344054",
    },
  });
};

export const infoAlert = (message)=>{
  return toast(message, {
    toastId: message,
    type: "info",
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    style: {
      fontFamily: "inherit",
      fontSize: "15px",
      fontWeight: 500,
      marginBottom: "7px",
      display: "inlineBlock",
      color: "#344054",
    },
  });
}

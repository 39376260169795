import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../components/banner/Banner";
import JobsCategory from "../../components/jobsCategory/JobsCategory";
import Mentor from "../../components/mentor/Mentor";
import TopRecruiter from "../../components/topRecruiter/TopRecruiter";
import LatestJob from "../../components/latestJob/LatestJob";
import { googleRequest } from "../../redux/Slice/authSlice";
import { getLatestJobListFetch } from '../../redux/Slice/jobsListSlice';
import { getOurMentorFetch } from "../../redux/Slice/profileSlice";

const Homepage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, googleAuth, isGoogleBtnClicked } = useSelector((state) => state?.auth);
  const latestJob = useSelector((state)=> state?.jobsList);
  const [ourMentorsFilters, setOurMentorsFilters] = useState({limit: 10, page: 1 });
  const paginatedDataOurMentors = (page) => {
    setOurMentorsFilters((prevState) => ({
      ...prevState,
      limit: 10,
      page: page
    }));
  }
  useEffect(()=>{
    if(isGoogleBtnClicked){ dispatch(googleRequest()) }
  },[]);
  const collectiveApiCall = async ()=>{
    await Promise.allSettled([dispatch(getLatestJobListFetch()), dispatch(getOurMentorFetch(ourMentorsFilters))])
  }
  useEffect(()=>{
     collectiveApiCall();
  },[])
  
    return (
        <>
            <Banner />
            <JobsCategory />
            <LatestJob />
            {/* <TopRecruiter /> */}
            <Mentor />
        </>
    )
}

export default Homepage;
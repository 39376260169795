import { createSlice } from "@reduxjs/toolkit";

const locFilters = createSlice({
  name:'loc',
  initialState:{
    isLoading:false,
    error:null,
    country:null,
    city:null
  },
  reducers:{
    getLocListFetch:(state)=>{
       state.isLoading = true
    },
    getLocListSuccess:(state,action)=>{
      state.country = action.payload
      state.isLoading = false
    },
    getCitiesListFetch:(state)=>{
      state.isLoading = true
    },
    getCitiesListSuccess:(state,action)=>{
      state.city = action.payload
      state.isLoading = false
    },
    getLocListFail:(state)=>{
      state.isLoading = false
    },
  }
});

export const { getLocListFetch,getLocListSuccess,getCitiesListFetch, 
  getCitiesListSuccess ,getLocListFail } = locFilters.actions

export default locFilters.reducer;
import { useState } from 'react'
import Avatar from "react-avatar-edit";
import { Modal } from 'react-bootstrap';

const ProfileUploadPopUp = ({closeImageModel, imageModalOpen, SetImagePreview}) => {
  const [src, setSrc] = useState("")
  const onClose = ()=>{
    closeImageModel();
    setSrc("");
  }
  const onCrop = (view)=>{
    SetImagePreview(view)
  }
  return (
    <Modal className='otpPopup-Wrapper imageUploadPopup'show={imageModalOpen}  backdrop="static" onHide={closeImageModel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Image Upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Avatar
            width={'100%'}
            height={300}
            onCrop={onCrop}
            onClose={onClose}
            exportAsSquare={false}
            // onBeforeFileLoad={this.onBeforeFileLoad}
            src={src}
            //exportSize={200}
        />
        <div className='text-center'><button className="btn btn-global imageUploadBtn mt-4 text-white" onClick={()=>{onClose();}} >Save</button></div>
      </Modal.Body>
    </Modal> 
  )
}

export default ProfileUploadPopUp



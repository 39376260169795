import React, { useEffect } from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import './registration.css';
import { useFormik } from "formik";

import { registerSchema } from '../../schemaValidator/loginRegistarSchema';
import ValidationError from '../../components/error/ValidationError';
import { registerRequest } from '../../redux/Slice/authSlice';
import { history } from '../../utils/history';
import { useDispatch, useSelector } from 'react-redux';
import OtpPopup from '../../components/popup/OtpPopup';
import { Link } from 'react-router-dom';

const Registration = () => {

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      tnc: "",
    },
    validationSchema: registerSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(registerRequest(values))
      resetForm()
    },
  });

  const { otpSent, otpValue } = useSelector((state) => state?.auth);
  useEffect(()=>{
    if(otpValue === 'verified'){
      history.navigate("/login")
    }
  },[otpValue])
  return (
    <div className='loginWrapper'>
      <Container>
        <Row>
          <Col xs={12} md={5} className='p-3 p-md-3 p-lg-3 p-xl-0 order-2'>
            <div className='loginForm'>
              <h3 className='text-center mb-4 mb-md-5'>REGISTRATION</h3>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="form-field mb-3" controlId="FirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter First Name" name="firstName"
                    onChange={formik.handleChange} value={formik.values.firstName} />
                  {formik.errors.firstName ? <ValidationError children={formik?.errors?.firstName} /> : ""}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="LastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Last Name" name="lastName"
                    onChange={formik.handleChange} value={formik.values.lastName} />
                  {formik.errors.lastName ? <ValidationError children={formik?.errors?.lastName} /> : ""}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="Email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email Address" name="email"
                    onChange={formik.handleChange} value={formik.values.email} />
                  {formik.errors.email ? <ValidationError children={formik?.errors?.email} /> : ""}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="Password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Enter Password" name="password"
                    onChange={formik.handleChange} value={formik.values.password} />
                  {formik.errors.password ? <ValidationError children={formik?.errors?.password} /> : ""}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="ConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" placeholder="Enter Confirm Password" name="confirmPassword"
                    onChange={formik.handleChange} value={formik.values.confirmPassword} />
                  {formik.errors.confirmPassword ? <ValidationError children={formik?.errors?.confirmPassword} /> : ""}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="I have read Terms & Condition" name="tnc"
                    onChange={formik.handleChange} value={formik.values.tnc} />
                  {formik.errors.tnc ? <ValidationError children={formik?.errors?.tnc} /> : ""}
                </Form.Group>
                <Button variant="primary" type="submit" className='w-100 mb-3'>
                  REGISTRATION
                </Button>
                <div className='text-center textLink'>Already have an account <Link to="/login" className='cursor-pointer underline-text'>Login</Link> </div>
              </Form>
            </div>
          </Col>
          <Col xs={12} md={7} className='p-3 p-md-0 order-md-2'>
            <div className='registrationBanner h-100 d-flex justify-content-center align-items-center'>
              <img src='./signUpBanner.svg' className='img-fluid' />
            </div>
          </Col>
        </Row>
      </Container>
     {otpSent ? <OtpPopup otpSent={otpSent} /> :""}
    </div>

  )
}

export default Registration
import { call, put } from "redux-saga/effects"
import * as services from  '../../services'
import { loginSuccess, loginFail, googleLoginSuccess, googleFail, registerSuccess,registerFail, 
  otpSentSuccess, otpSentFail, forgotPasswordFail, forgotPasswordSuccess, changePasswordSuccess, 
  changePasswordFail
} from "../Slice/authSlice";
import { successAlert, errorAlert, infoAlert } from "../../utils/alerts";
import * as momenttz from 'moment-timezone'


export function* loginRequest(action) { 
  const response = yield call( services.loginRequest,action.payload);
  const { status, message, result } = response.data;
  if(status === 200 || status === 201){
    yield put(loginSuccess(result));
    localStorage.setItem("accessToken", result.jwtToken);
      successAlert(message);
      if(result.role == 'member'){ infoAlert("Please update your profile to access more feature") }
   }
   else if(status === 401){
    errorAlert(message);
    yield put(loginFail(result));
   }
   else{
    yield put(loginFail(result));
    errorAlert(message);
   }
}

export function* googleRequest(action){
  const response = yield call( services.googleRequest,action.payload);
  const { status, message, result } = response.data;
  if(status === 200 || status === 201){
    yield put(googleLoginSuccess(result));
    localStorage.setItem("accessToken", result.jwtToken);
      successAlert(message);
      if(result.role == 'member'){ infoAlert("Please update your profile to access more feature") }
   }
   else if(status === 401){
    errorAlert(message);
    yield put(googleFail(result));
   }
   else{
    yield put(googleFail(result));
    errorAlert(message);
   }
}

export function* registerRequest(action) {
    const tz = momenttz.tz.guess();
    const data = action.payload;
    const postData = {
      fname: data.firstName,
      lname: data.lastName,
      email: data.email,
      password: data.password,
      role: data.role,
      phone: data.phone,
      timezone:tz
    };
    const response= yield call( services.registerRequest, postData );
    if (response?.data) {
      const { status, message } = response.data;
      if (status === 200 || status === 201) {
        successAlert(message);
        yield put(registerSuccess(postData.email))
       
      } else {
        errorAlert(message);
        yield put(registerFail())
        }
    } else {
      errorAlert("Error while registering");
      yield put(registerFail())
    }
}

export function* sendOtpRequest(action) { 
    const {email} = action.payload
    const response = yield call( services.sendOtpRequest, action.payload );
    if (response?.data) {
      const { status, message } = response.data;
      if (status === 200) {
        successAlert(message);
        yield put(forgotPasswordSuccess(email))
      } else {
        errorAlert(message);
        yield put(forgotPasswordFail())
      }
    } else {
      errorAlert("Error in Forgot Password");
      yield put(forgotPasswordFail())
    }
}

export function* forgotPassword(action) {
    const response = yield call( services.forgotPasswordRequest, action.payload);
    if (response?.data) {
      const { status, message } = response.data;
      if (status === 201) {
        successAlert(message);
        yield put(changePasswordSuccess())
       } 
       else {
        errorAlert(message);
        yield put(changePasswordFail())
      }
    } else {
      errorAlert("Error in Forgot Password");
      yield put(changePasswordFail())
    }

}

export function* verifyOtpRequest(action){
  const response = yield call( services.verifyOtpRequest, action.payload );
  if (response?.data) {
    const { status, message } = response.data;
    if (status === 200 || status === 201) {
      successAlert(message);
      yield put(otpSentSuccess())
    } else {
      errorAlert(message);
      yield put(otpSentFail())
    }
  } else {
    errorAlert("Error in Forgot Password");
    yield put(otpSentFail())
  }
}

import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name:'profile',
    initialState:{
      isLoading:false,
      myprofile:null,
      otherprofile:null,
      profileList:null,
      ourMentorList:null,
      connectMe:{connectMe:false, connectId:null},
      connectClick:false,
    },
    reducers:{
      getProfileFetch:(state)=>{
         state.isLoading = true
      },
      getMyProfileSuccess:(state,action)=>{
        state.connectMe.connectMe=false
        state.connectMe.connectId = null
        state.myprofile = action.payload
        state.isLoading = false
      },
     getOtherProfileFetch:(state)=>{
         state.isLoading=true
     },
     getOtherProfileSuccess:(state,action)=>{
       state.myprofile = action.payload
       //state.otherprofile = action.payload
       state.isLoading = false
     },
     getListProfileFetch:(state)=>{
      state.isLoading = false
     },
     getProfileListSuccess:(state,action)=>{
       state.profileList = action.payload
       state.isLoading = false
     },
     getOurMentorFetch:(state)=>{
      state.isLoading = true
     },
     getOurMentorListSuccess:(state,action)=>{
       state.ourMentorList = action.payload
       state.isLoading = false
    },
    connectMe:(state,action)=>{
      state.connectMe.connectMe=true
      state.connectMe.connectId=action.payload
    },
    connectClicked :(state,action)=>{
      state.connectClick = action.payload;
    },
    profileUpdate:(state,action)=>{
      
    },
     getProfileFail:(state)=>{
       state.isLoading = false
       state.connectMe.connectMe=false
       state.connectMe.connectId = null
     }
    }
});

export const { getProfileFetch, getMyProfileSuccess,getOtherProfileFetch, getOtherProfileSuccess, 
  getListProfileFetch,getProfileListSuccess,getOurMentorListSuccess, getOurMentorFetch, getProfileFail, 
  connectMe, connectClicked, connectClose} = profileSlice.actions
export default profileSlice.reducer;
import React from 'react';
import './mentor.css'; 
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../utils/history';
import { connectMe } from '../../redux/Slice/profileSlice';

const Mentor = () => {
  const settings = { 
    slidesToShow: 2,
    slidesToScroll: 1,  
    swipeToSlide: true, 
    responsive: [ 
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }, 
    ]
  };
  const disptach = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth);
  const connectExpert = (id)=>{ if(!isAuthenticated){  history.navigate('/login',{state:{from:history.location}})}
  else{ disptach(connectMe({expertId:id})); history.navigate('/expert-connect',{state:{from:history.location}}); }} 
  
  const ourMentors = useSelector((state)=> state?.profile?.ourMentorList)

  return (
    <div className='mentor_Wrapper'>
        <Container>
          <h3 className='text-center position-relative'><span>OUR MENTORS</span></h3>
          <div className='d-none d-md-flex justify-content-center'> 
          { ourMentors?.data?.map((items,index)=>(
              <div className='mentorSection text-center' key={`large-${index}`}>
              <div className='mentorProfile'>
                <img src={ items?.profilepic ||'./user-photo.png'} className='userProfile' />
                <h3>{items?.fname}</h3>
                <h4>{items?.ProfileData?.profession}</h4>
                <p className='text-justify'>{items?.ProfileData?.aboutmore}</p>
                <button className='btn btn-connect' onClick={()=>{connectExpert(items?._id)}}>CONNECT ME</button>
              </div>
          </div>
          )) }

          </div>
          <div className='d-block d-md-none justify-content-center'>
            <Slider {...settings}>
            { ourMentors?.data?.map((items,index)=>(
              <div className='mentorSection text-center' key={`small-${index}`}>
              <div className='mentorProfile'>
                <img src={items?.profilepic ||'./user-photo.png'} className='userProfile' />
                <h3>{items?.fname}</h3>
                <h4>{items?.ProfileData?.profession}</h4>
                <p className='text-justify'>{items?.ProfileData?.aboutmore}</p>
                <button className='btn btn-connect' onClick={()=>{connectExpert(items?._id)}}>CONNECT ME</button>
              </div>
          </div>
          )) }
            </Slider>
          </div>
        </Container> 
    </div>
  )
}

export default Mentor
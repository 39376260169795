import Form from 'react-bootstrap/Form';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

/** Custome file imports start here  */
import './banner.css';
import { PATHS } from "../../routes/paths"
import BannerSvg from './BannerSvg';
import JobSearch from '../jobSearch/JobSearch'; 

const Banner = () => {
    return (
    <>
      <div className='banner_wrapper'>
        <Container fluid className='p-0'>
          <section className="bg-home2" id="home">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="mb-4 pb-3 me-lg-5">
                        {/* <h6 className="sub-title">We have 150,000+ live jobs</h6> */}
                        <h1 className="display-5 fw-semibold mb-3">Get empowered for your career journey with <span className="text-blue fw-bold">CAREOB</span></h1>
                        <p className="lead text-muted mb-0">Careob is on a mission to equip you with tools, techniques and trainings(3Ts). The 3Ts will guide you through your career and help you get the job.</p>
                    </div>
                    <form action="#">
                        <div className="registration-form">
                            <div className="row g-0">
                              <div className='col-md-12'>
                                <JobSearch />
                              </div> 
                            </div>
                        </div>
                    </form>
                </div> 
                <div className="col-lg-5">
                  <div className="mt-5 mt-md-0">
                      <img src="https://themesdesign.in/jobcy/layout/assets/images/process-02.png" alt="" className="home-img" /> 
                  </div> 
                </div> 
              </div> 
            </div>
          </section> 
        </Container>  
      </div>
      <div className="position-relative">
          <div className="shape">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="150" preserveAspectRatio="none" viewBox="0 0 1440 220">
                  <g mask="url(&quot;#SvgjsMask1004&quot;)" fill="none">
                      <path d="M 0,213 C 288,186.4 1152,106.6 1440,80L1440 250L0 250z" fill="rgba(255, 255, 255, 1)"></path>
                  </g>
                  <defs>
                      <mask id="SvgjsMask1004">
                          <rect width="1440" height="250" fill="#ffffff"></rect>
                      </mask>
                  </defs>
              </svg>
          </div>
      </div>
    </>
    )
}

export default Banner;
import React from 'react';
import './jobsCategory.css';
import Container from 'react-bootstrap/Container';

const JobsCategory = () => {
  return (
    <div>
        <div className='jobsCategory_Wrapper'>
            <Container>
                <h3 className='heading text-center'><span>JOBS Category</span></h3>
                <div className='jobsCategory_row d-block d-md-flex align-items-center position-relative'>
                    <div className='jobsCategoryList d-flex flex-wrap justify-content-around'>
                        <div className='jobsCategory text-center position-relative'> 
                            <div className='recruiter'>
                                {/* <img src='./hired-image.png' className='img-fluid hiredImage' /> */}
                                <h4>RECRUITERS</h4>
                                <p>Come on our platfrom and post your jobs for FREE and reach out to the best candidates in the industry </p>
                            </div>
                        </div>
                        <div className='jobsCategory text-center position-relative'> 
                            <div className='recruiter'>
                                {/* <img src='./hired-image.png' className='img-fluid hiredImage' /> */}
                                <h4>EXPERT</h4>
                                <p>Become part of a revolutionary movement where you shape and guide the candidates and help them make the best decision</p>
                            </div>
                        </div>
                        <div className='jobsCategory text-center position-relative'> 
                            <div className='recruiter'>
                                {/* <img src='./hired-image.png' className='img-fluid hiredImage' /> */}
                                <h4>CANDIDATE</h4>
                                <p>Join the network of the highly distinguished experts and recruiters and accelerate your career. Ask for mentorship, guidance and learn from their experiences.</p>
                            </div>
                        </div>
                    </div>
                    <div className='jobsCategoryImage'>
                        <img src='./jobCategory.svg' className='img-fluid' />
                    </div>
                </div>
            </Container> 
        </div>
    </div>
  )
}

export default JobsCategory
import { call, put } from "redux-saga/effects"
import {errorAlert} from '../../utils/alerts'
import * as services from  '../../services'
import { getNotificationListFetch, getNotificationListFail, getNotificationListSuccess } from "../Slice/notificationSlice";
import { loginFail } from "../Slice/authSlice";
export function* getNotificationListRequest(action) {
  const response= yield call(services.getNotificationListRequest, action.payload );
   const { status, message, result } = response.data;
   if(response.status === 200){
    yield put(getNotificationListSuccess(result));
   }
   else if(response.status === 401){
    errorAlert(message);
    yield put(getNotificationListFail())
    yield put(loginFail())
   }
   else{
    errorAlert(message);
    yield put(getNotificationListFail());
   }
}
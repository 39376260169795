import * as Yup from "yup";

// =================Constants===================
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// =================Schema's====================

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password ahould contain at least one uppercase and lowercase character")
    .matches(/\d/, "Password should contain at least one number")
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character")
});

export const forgotOtpSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("This field is required"),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("This field is required"),
  newpasswd:Yup.string()
  .required("This field is required")
  .min(8, "Pasword must be 8 or more characters")
  .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password ahould contain at least one uppercase and lowercase character")
  .matches(/\d/, "Password should contain at least one number")
  .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),
  confirmpasswd:Yup.string().when("newpasswd", (newpasswd, field) => {
    if (newpasswd) {
      return field.required("The passwords do not match").oneOf([Yup.ref("newpasswd")], "The passwords do not match");
    }
  }),
});



export const registerSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().notRequired(),
  email: Yup.string().email("Please enter a valid email").required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password ahould contain at least one uppercase and lowercase character")
    .matches(/\d/, "Password should contain at least one number")
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),
  confirmPassword: Yup.string().when("password", (password, field) => {
    if (password) {
      return field.required("The passwords do not match").oneOf([Yup.ref("password")], "The passwords do not match");
    }
  }),
  tnc: Yup.boolean().oneOf([true], 'Field must be checked')
});

export const editProfileSchema = Yup.object().shape({
  fname: Yup.string().max(50).required("What's your name!!"),
  lname: Yup.string().max(50).notRequired(),
  email: Yup.string().max(100).email("Please enter a valid email").required("I'm sure you have it."),
  phone: Yup.string().max(10, "Only 10 digit number! Skip +91").matches(phoneRegExp, 'Number is required'),
  // age: Yup.number().max(99,"Can't be greater than 99").required("We will keep it secret 🤫"),
  profession: Yup.string().min(2).max(50).required("What's your Job profile"),
  role: Yup.string().required("Choose any preferred role!"),
  summary: Yup.string().min(100).max(150).required("Hey, write some catchy bio 😉"),
  address: Yup.string().max(150).required("You missed this!"),
  education: Yup.string().required("Please add your educations"),
  aboutmore: Yup.string().min(150).max(250).required("Here you can write detail info about yourself."),
  file: Yup.string().required("This field is required"),
  orgname: Yup.string().max(150).required("You missed this!"),
  skills:Yup.string(),
  socialink: Yup.lazy(value => {
   if ( value?.fbLink === undefined &&  value?.instaLink === undefined && value?.linkedinLink === undefined && value?.twitterLink === undefined)
       {
      return Yup.object({
        fbLink: Yup.string().required("This field is required"),
        instaLink: Yup.string().required("This field is required"),
        linkedinLink: Yup.string().required("This field is required"),
        twitterLink: Yup.string().required("This field is required"),
      })
    }else{
      return Yup.mixed().notRequired();
    }
  })
})


export const connectSchudler = Yup.object().shape({
  title: Yup.string().required("Please fill the title"),
  startdate: Yup.date().required("Please specify the time to start"),
  description: Yup.string().required("Please fill the description")
});

export const acceptScheduler = Yup.object().shape({
  title: Yup.string().required("Please fill the title"),
  startdate: Yup.date().required("Please specify the time to start"),
  description: Yup.string().required("Please fill the description")
});

export const rejectScheduler = Yup.object().shape({
  title: Yup.string().required("Please fill the title"),
  description: Yup.string().required("Please fill the description")
});
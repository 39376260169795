import { call, put } from "redux-saga/effects"
import {errorAlert} from '../../utils/alerts'
import * as services from  '../../services'
import { getJobListSuccess, getJobListFail, getLatestJobListSuccess, getJobDetails, jobApply } from "../Slice/jobsListSlice";

export function* jobListRequest(action) {
  try {
    const response = yield call( services.getJobsRequest,action.payload );
    if (response?.data) {
      const { status, message, result } = response.data;
      if (status === 200) {
        const colorArray = ['#FFE1CB','#D5F6ED','#E2DBF9','#E0F3FF','#FBE2F3','#ECEFF5'];
        result.jobsdata.map((value,index)=>{
          if(colorArray[index]== undefined){
            value["cardColor"] = colorArray[index-colorArray.length]
          }else{
            value["cardColor"] = colorArray[index]
          }
          return value
        })
        yield put(getJobListSuccess(result));
      } else {
        errorAlert(message);
        yield put(getJobListFail());
      }
    } else {
      yield put(getJobListFail());
    }
  } catch (error) {
    errorAlert("Error in fetching  feedback list12334");
    yield put(getJobListFail());
  }
}

export function* getLatestJobRequest(action){
  try {
    const response = yield call( services.getLatestJobRequest,action.payload );
    if (response?.data) {
      const { status, message, result } = response.data;
      if (status === 200) {
        yield put(getLatestJobListSuccess(result));
      } else {
        errorAlert(message);
        yield put(getJobListFail());
      }
    } else {
      yield put(getJobListFail());
    }
  } catch (error) {
    errorAlert("Error in fetching  feedback listsss");
    yield put(getJobListFail());
  }
}

export function* getJobDetail(action){
  try {
    const response = yield call( services.jobDetailsRequest,action.payload );
    if (response?.data) {
      const { status, message, result } = response.data;
      if (status === 200) {
        yield put(getJobDetails(result));
      } else {
        errorAlert(message);
        yield put(getJobListFail());
      }
    } else {
      yield put(getJobListFail());
    }
  } catch (error) {
    errorAlert("Error in fetching wrong job");
    yield put(getJobListFail());
  }
}

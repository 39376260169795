import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLocationPin, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';    
import { faFacebookF, faTwitter,faInstagram, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import './contactUs.css';
import './ContactSVG';
import ContactSVG from './ContactSVG';

const Contactus = () => {
  return (
    <div className='contactUsWrapper'>
        <div className='contactBanner text-center'> 
            <div className='contactBannerImg'>
                <ContactSVG />
            </div>
        </div>
        <div className='contactFormWrapper'>
            <Container>
                <Row> 
                    <Col xs={12} sm={7} md={6} className='p-3 p-md-3 p-lg-3 p-xl-0'>
                        <div className='contactForm mt-5'> 
                            <Form> 
                                <Form.Group className="form-field mb-3" controlId="Name">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control disabled={true}  type="text" placeholder="Enter Your Name" /> 
                                </Form.Group>
                                <Form.Group className="form-field mb-3" controlId="Email">
                                    <Form.Label>Your Email</Form.Label>
                                    <Form.Control disabled={true}  type="email" placeholder="Enter Email Address" /> 
                                </Form.Group> 
                                <Form.Group className="form-field mb-3" controlId="Message">
                                    <Form.Label>Your Message</Form.Label>
                                    <Form.Control disabled={true}  as="textarea" rows={3} placeholder="Enter Your Message" />
                                </Form.Group>
                                <Button variant="primary" type="submit" className='w-100 mb-3'>
                                    SEND MESSAGE
                                </Button> 
                            </Form>
                        </div>
                    </Col>
                    <Col xs={12} sm={5} md={5} className='p-3 p-md-0 order-md-2 offset-md-1 col-12 d-flex align-items-center mb-5'>
                        <div className="followUs">
                            <ul className="p-0 m-0 list-unstyled">
                                {/* <li> 
                                    <svg width="28" fill="#1c4383" viewBox="0 0 100 100"><path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z"/></svg>
                                    53-A JanakPuri, New Delhi India.</li> */}
                                <li>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M14 8C14.7956 8 15.5587 8.31607 16.1213 8.87868C16.6839 9.44129 17 10.2044 17 11C17 11.2652 17.1054 11.5196 17.2929 11.7071C17.4804 11.8946 17.7348 12 18 12C18.2652 12 18.5196 11.8946 18.7071 11.7071C18.8946 11.5196 19 11.2652 19 11C19 9.67392 18.4732 8.40215 17.5355 7.46447C16.5979 6.52678 15.3261 6 14 6C13.7348 6 13.4804 6.10536 13.2929 6.29289C13.1054 6.48043 13 6.73478 13 7C13 7.26522 13.1054 7.51957 13.2929 7.70711C13.4804 7.89464 13.7348 8 14 8Z" fill="#1c4383"/>
                                        <path d="M14 4C15.8565 4 17.637 4.7375 18.9497 6.05025C20.2625 7.36301 21 9.14348 21 11C21 11.2652 21.1054 11.5196 21.2929 11.7071C21.4804 11.8946 21.7348 12 22 12C22.2652 12 22.5196 11.8946 22.7071 11.7071C22.8946 11.5196 23 11.2652 23 11C23 8.61305 22.0518 6.32387 20.364 4.63604C18.6761 2.94821 16.3869 2 14 2C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3C13 3.26522 13.1054 3.51957 13.2929 3.70711C13.4804 3.89464 13.7348 4 14 4Z" fill="#1c4383"/>
                                        <path d="M22.75 15.91C22.695 15.7495 22.6 15.6056 22.4741 15.4919C22.3481 15.3781 22.1953 15.2984 22.03 15.26L16.03 13.89C15.8671 13.8531 15.6976 13.8575 15.5369 13.9029C15.3762 13.9484 15.2294 14.0333 15.11 14.15C14.97 14.28 14.96 14.29 14.31 15.53C12.1532 14.5362 10.425 12.8009 9.44 10.64C10.71 10 10.72 10 10.85 9.85C10.9667 9.73056 11.0516 9.58379 11.0971 9.42308C11.1425 9.26238 11.1469 9.09286 11.11 8.93L9.74 3C9.70164 2.8347 9.62186 2.68187 9.50814 2.55591C9.39443 2.42995 9.25053 2.335 9.09 2.28C8.85647 2.19659 8.61531 2.1363 8.37 2.1C8.11725 2.0414 7.85933 2.0079 7.6 2C6.38 2 5.20998 2.48464 4.34731 3.34731C3.48464 4.20998 3 5.38 3 6.6C3.00529 10.6827 4.62949 14.5967 7.5164 17.4836C10.4033 20.3705 14.3173 21.9947 18.4 22C19.0041 22 19.6022 21.881 20.1603 21.6498C20.7184 21.4187 21.2255 21.0798 21.6527 20.6527C22.0798 20.2255 22.4187 19.7184 22.6498 19.1603C22.881 18.6022 23 18.0041 23 17.4C23.0003 17.1455 22.9802 16.8913 22.94 16.64C22.898 16.3916 22.8344 16.1474 22.75 15.91ZM18.4 20C14.8469 19.9974 11.4401 18.5847 8.92769 16.0723C6.41528 13.5599 5.00265 10.1531 5 6.6C5.00263 5.91125 5.27741 5.25146 5.76443 4.76443C6.25146 4.27741 6.91125 4.00263 7.6 4H7.93L9 8.64L8.46 8.92C7.6 9.37 6.92 9.73 7.28 10.51C7.86622 12.1696 8.81477 13.6777 10.0567 14.9249C11.2987 16.172 12.8029 17.1269 14.46 17.72C15.3 18.06 15.63 17.43 16.08 16.56L16.37 16.01L21 17.07V17.4C20.9974 18.0888 20.7226 18.7485 20.2356 19.2356C19.7485 19.7226 19.0887 19.9974 18.4 20Z" fill="#1c4383"/>
                                    </svg>
                                    +91-9667783922
                                </li>
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM18.33 6L12 10.75L5.67 6H18.33ZM19 18H5C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V7.25L11.4 12.8C11.5731 12.9298 11.7836 13 12 13C12.2164 13 12.4269 12.9298 12.6 12.8L20 7.25V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18Z" fill="#1c4383"/>
                                    </svg>
                                    team@careob.com
                                </li>
                            </ul> 
                            <div className='followUsOn mt-4'>
                                <div className='footer_header'>Follow Us On</div> 
                                <Nav className="followIcons mt-3">
                                <Nav.Link href="https://www.facebook.com/careobfb/" rel="noreferrer" target="_blank" data-original-title="Facebook"><FontAwesomeIcon icon={faFacebookF} /></Nav.Link> 
                        <Nav.Link  href="https://twitter.com/Careob1" rel="noreferrer" target="_blank" data-original-title="Twitter"><FontAwesomeIcon icon={faTwitter} /></Nav.Link> 
                        <Nav.Link href="https://www.youtube.com/channel/UCK31H2rSQNJUjcVVNd220og" rel="noreferrer" target="_blank" data-original-title="Youtube"><FontAwesomeIcon icon={faYoutube} /></Nav.Link> 
                        <Nav.Link  href="https://www.instagram.com/careob_/" rel="noreferrer" target="_blank" data-original-title="Instagram"><FontAwesomeIcon icon={faInstagram} /></Nav.Link> 
                        <Nav.Link href="https://www.linkedin.com/company/careob/" rel="noreferrer" target="_blank" data-original-title="LinkedIn"><FontAwesomeIcon icon={faLinkedin} /></Nav.Link>
                                </Nav>
                            </div>  
                        </div>
                    </Col>
                </Row>
            </Container> 
            <Container fluid>
                <Row>
                    <Col xs={12} className='text-center mb-5 p-0'>
                        <div className='map'> 
                            <img src='./map.png' className='img-fluid w-100' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
  )
}

export default Contactus
import React, { useMemo, useEffect, useState } from 'react';
import './editProfile.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './editProfile.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileFetch } from '../../redux/Slice/profileSlice';
import { updateProfileRequest } from '../../services/profileService';
import { useFormik } from "formik";
import { editProfileSchema } from '../../schemaValidator/loginRegistarSchema';
import ValidationError from '../../components/error/ValidationError';
import { formDataCreator } from '../../utils/commonFns';
import ProfileUploadPopUp from '../../components/profileUpload/ProfileUploadPopUp';
import {
  uniqueNamesGenerator,
  Config,
  adjectives,
  colors,
  starWars
} from 'unique-names-generator';
import { urltoFile } from '../../utils/commonFns';
import { history } from '../../utils/history';
import { errorAlert, successAlert } from '../../utils/alerts';

const EditProfile = () => {

  const dispatch = useDispatch();
  const { myprofile } = useSelector((state) => state?.profile) || [];
  // const data = useMemo(() => { dispatch(getProfileFetch()) }, []);
  const [imageModalOpen, setImageModelOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const closeImageModel = () => {
    setImageModelOpen(false);
  };
  const customConfig = {
    dictionaries: [adjectives, colors, starWars],
    separator: '-',
    length: 3
  };
  const SetImagePreview = (view) => {
    setImagePreview(view);
  };
  const socialink = typeof(myprofile?.socialink) === "string" ? JSON.parse(myprofile?.socialink) : myprofile?.socialink
  const formik = useFormik({
    initialValues: {
      fname: myprofile?.fname || "",
      lname: myprofile?.lname || "",
      email: myprofile?.email || "",
      phone: myprofile?.phone || "",
      profession: myprofile?.ProfileData?.profession || "",
      orgname:myprofile?.ProfileData?.orgname || "",
      role: myprofile?.role || "",
      summary: myprofile?.ProfileData?.summary || "",
      address: myprofile?.address || "",
      education:myprofile?.ProfileData?.education?.join(",") || [],
      skills:myprofile?.ProfileData?.skills?.join(",") || [],
      aboutmore: myprofile?.ProfileData?.aboutmore || "",
      file: myprofile?.profilepic,
      socialink: {
        fbLink:   socialink?.fbLink || "",
        instaLink: socialink?.instaLink || "",
        linkedinLink: socialink?.linkedinLink || "",
        twitterLink: socialink?.twitterLink || ""
      }
    },
    validationSchema: editProfileSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values)
      // dispatch(updateProfileRequest(formData))
      //   resetForm()
    },
  });

  const handleSubmit = async (
    values,
    setSubmitting,
    setErrors
  ) => {
    let file;
    if (imagePreview !== (null || '' || undefined)) {
      const imgArr = imagePreview.split(',');
      const mimeType = imgArr[0].match(/:(.*?);/)?.[1];
      const imgext = mimeType?.split('/')[1];
      if (imgext) {
        const shortName = uniqueNamesGenerator(customConfig);
        file = await urltoFile(imagePreview, shortName, mimeType, imgext) || values.file;
      }
    }
    values.socialink = JSON.stringify(values.socialink);
    const myData= values;
    const formData = new FormData();
    for (const key in myData) {
      formData.append(key, myData[key]);
    }
    formData.set('file', file);
    formData.delete('education');
    formData.delete('skills')
    if(values.education.length > 0){
      values.education.split(",").forEach((value)=>{
        formData.append('education',value)
      })
    }
    if(values?.skills?.length > 0){
      values.skills.split(",").forEach((value)=>{
        formData.append('skills',value)
      })
    }
    const data = await updateProfileRequest(formData)
    if(data.data.status != 201){ errorAlert("Unable to update the profile"); return -1 }
    successAlert("Profile is updated successfully")
    history.navigate("/profile")
  };
  
  return (
    <div className="profileWrapper">
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={4}>
              <div className="uploadImageWrap text-center">
                <div className="uploadImage">
                  <img
                    className="w-100"
                    src={
                      imagePreview
                        ? imagePreview
                        : myprofile?.profilepic ||
                          formik?.values?.file ||
                          "./upload.png"
                    }
                    alt="profile pic"
                    // value={`${myprofile?.profilepic}` || ""}
                    onClick={() => {
                      setImageModelOpen(true);
                    }}
                  />
                </div>
                <p className="mt-4">
                  Drag & Drop to Upload <br /> Or Browse
                </p>
                {/* <button className='btn btn-global' type="reset">Upload Image</button> */}
              </div>
              <ul className="list-group list-group-flush socialLinks">
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-twitter icon-inline text-info"
                  >
                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                  </svg>
                  <span className="text-secondary">
                    <input
                      type="text"
                      name="socialink.twitterLink"
                      id="twitterLink"
                      placeholder="Twitter Profile URL"
                      value={formik.values.socialink.twitterLink}
                      onChange={formik.handleChange}
                      className="form-control"
                    />
                  </span>
                </li>
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-instagram icon-inline text-danger"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                  </svg>
                  <span className="text-secondary">
                    <input
                      type="text"
                      name="socialink.instaLink"
                      id="instaLink"
                      placeholder="Instagram Profile URL"
                      value={formik.values.socialink.instaLink}
                      onChange={formik.handleChange}
                      className="form-control"
                    />
                  </span>
                </li>
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-facebook icon-inline text-primary"
                  >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                  </svg>
                  <span className="text-secondary">
                    <input
                      type="text"
                      name="socialink.fbLink"
                      id="fbLink"
                      placeholder="Facebook Profile URL"
                      value={formik.values.socialink.fbLink}
                      onChange={formik.handleChange}
                      className="form-control"
                    />
                  </span>
                </li>
                <li className="list-group-item d-flex align-items-center flex-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#333"
                      d="M8.421,14h0.052l0,0C11.263,14,13,12,13,9.5C12.948,6.945,11.263,5,8.526,5S4,6.945,4,9.5	C4,12,5.736,14,8.421,14z M4,17h9v26H4V17z M44,26.5c0-5.247-4.253-9.5-9.5-9.5c-3.053,0-5.762,1.446-7.5,3.684V17h-9v26h9V28l0,0	c0-2.209,1.791-4,4-4s4,1.791,4,4v15h9C44,43,44,27.955,44,26.5z"
                    ></path>
                  </svg>
                  <span className="text-secondary">
                    <input
                      type="text"
                      name={`socialink.linkedinLink`}
                      id="linkeinLink"
                      placeholder="LinkedIn Profile URL"
                      value={formik.values.socialink.linkedinLink}
                      onChange={formik.handleChange}
                      className="form-control"
                    />
                  </span>
                </li>
                {formik.errors.socialink ? (
                  <ValidationError
                    children={formik?.errors?.socialink.twitterLink}
                  />
                ) : (
                  ""
                )}
              </ul>
            </Col>
            <Col md={8}>
              <div className="editProfile_Form">
                {/* <div className='heading text-center'>Edit Profile</div> */}
                <Row>
                  <Col md={6}>
                    <Form.Group className="form-field mb-3" controlId="fname">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fname"
                        value={formik.values.fname}
                        onChange={formik.handleChange}
                        placeholder="Enter First Name"
                      />
                      {formik.errors.fname ? (
                        <ValidationError children={formik?.errors?.fname} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-field mb-3" controlId="lname">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lname"
                        value={formik.values.lname}
                        onChange={formik.handleChange}
                        placeholder="Enter Last Name"
                      />
                      {formik.errors.lname ? (
                        <ValidationError children={formik?.errors?.lname} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-field mb-3" controlId="phoneNo">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        placeholder="Enter Mobile Number"
                      />
                      {formik.errors.phone ? (
                        <ValidationError children={formik?.errors?.phone} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Your Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Your Email"
                        //   value={`${myprofile?.email}` || ""}
                        name="email"
                        value={formik?.values?.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email ? (
                        <ValidationError children={formik?.errors?.email} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="profession"
                    >
                      <Form.Label>profession</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your profession"
                        //   value={`${myprofile?.ProfileData?.profession}` || ""}
                        name="profession"
                        value={formik.values.profession}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.profession ? (
                        <ValidationError
                          children={formik?.errors?.profession}
                        />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-field mb-3" controlId="orgname">
                      <Form.Label>Organisation</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Organisation Name"
                        //   value={`${myprofile?.ProfileData?.profession}` || ""}
                        name="orgname"
                        value={formik.values.orgname}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.orgname ? (
                        <ValidationError children={formik?.errors?.orgname} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Profile Summary</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Profile Summary"
                        //   value={`${myprofile?.ProfileData?.summary}` || ""}
                        name="summary"
                        value={formik.values.summary}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.summary ? (
                        <ValidationError children={formik?.errors?.summary} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Educations</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Please add your education with comma seprated. Ex B.tech,M.tech"
                        //   value={`${myprofile?.ProfileData?.education?.join(",")}` || ""}
                        name="education"
                        value={formik?.values?.education}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.education ? (
                        <ValidationError children={formik?.errors?.education} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Skills</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Please add your skills with comma seprated. Ex NodeJs,Javascript"
                        //   value={`${myprofile?.ProfileData?.education?.join(",")}` || ""}
                        name="skills"
                        value={formik?.values?.skills}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.skills ? (
                        <ValidationError children={formik?.errors?.skills} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Role</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="form-control"
                      >
                        {!formik?.values?.role && <option>Select role</option>}
                        <option
                          value={
                            formik?.values?.role === "expert"
                              ? formik?.values?.role
                              : "expert"
                          }
                        >
                          Expert
                        </option>
                        <option
                          value={
                            formik?.values?.role === "student"
                              ? formik?.values?.role
                              : "student"
                          }
                        >
                          Student
                        </option>
                        <option
                          value={
                            formik?.values?.role === "recruiter"
                              ? formik?.values?.role
                              : "recruiter"
                          }
                        >
                          Recruiter
                        </option>
                      </Form.Select>
                      {formik.errors.role ? (
                        <ValidationError children={formik?.errors?.role} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Your Address"
                        //   value={`${myprofile?.address}` || ""}
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.address ? (
                        <ValidationError children={formik?.errors?.address} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-field mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>About Yourself</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Mention About Yourself"
                        //   value={`${myprofile?.ProfileData?.aboutmore}` || ""}
                        name="aboutmore"
                        value={formik.values.aboutmore}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.aboutmore ? (
                        <ValidationError children={formik?.errors?.aboutmore} />
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center">
                    <button
                      className="btn btn-global mt-5 submitProfile"
                      type="submit"
                    >
                      Edit Profile
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
      {imageModalOpen ? (
        <ProfileUploadPopUp
          imageModalOpen={imageModalOpen}
          closeImageModel={closeImageModel}
          SetImagePreview={SetImagePreview}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default EditProfile
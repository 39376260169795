import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    googleAuth: false,
    auth: null,
    isAuthenticated: false,
    isGoogleBtnClicked: false,
    otpSent: false,
    forgotStatus: false,
    userEmail: null,
    otpValue: null,
    error: null
  },
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.auth = action.payload
      state.isLoading = false
    },
    loginFail: (state) => {
      state.isAuthenticated = false
      state.isLoading = false
    },
    googleSingup: (state) => {
      state.isGoogleBtnClicked = true
    },
    googleRequest: (state) => {
      state.isLoading = true
      state.googleAuth = true
    },
    googleLoginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.auth = action.payload
      state.isLoading = false
      state.googleAuth = false
      state.isGoogleBtnClicked = false
    },
    googleFail: (state) => {
      state.isAuthenticated = false
      state.isLoading = false
      state.googleAuth = false
      state.isGoogleBtnClicked = false
    },
    registerRequest: (state, action) => {
      state.isLoading = true
    },
    registerSuccess: (state, action) => {
      state.isLoading = false;
      state.otpSent = true;
      state.userEmail = action.payload
    },
    registerFail: (state) => {
      state.error = "Unable to process the request"
      state.isLoading = false;
      state.otpSent = false;
      state.userEmail = null
    },
    otpSentRequest: (state) => {
      state.isLoading = true;
    },
    otpSentSuccess: (state, action) => {
      state.isLoading = false;
      state.otpSent = false
      state.otpValue = 'verified'
      state.userEmail = state.forgotStatus ? state.userEmail : null
    },
    otpSentFail: (state) => {
      state.isLoading = false;
      state.otpSent = false;
      state.otpValue = null
      state.userEmail = null
      state.forgotStatus = false
    },
    setOtpValue: (state, action) => {
      state.otpValue = action.payload
    },
    forgotPasswordRequest: (state) => {
      state.isLoading = true;
    },
    forgotPasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.otpSent = true;
      state.forgotStatus = true;
      state.userEmail = action.payload
    },
    forgotPasswordFail: (state) => {
      state.isLoading = false;
      state.otpSent = false;
    },
    changePasswordRequest: (state) => {
      state.isLoading = true;
    },
    changePasswordSuccess: (state) => {
      state.isLoading = false;
      state.forgotStatus = false;
      state.otpSent = false;
      state.userEmail = null
    },
    changePasswordFail: (state) => {
      state.isLoading = false;
      state.forgotStatus = false
      state.userEmail = null
    },
    logout: (state) => {
      state.isAuthenticated = false
    }
  }
});

export const { loginFail, loginRequest, loginSuccess, logout, googleFail, googleLoginSuccess, googleRequest, 
  googleSingup,registerFail, registerRequest, registerSuccess, otpSentFail, otpSentRequest, otpSentSuccess, 
  setOtpValue,forgotPasswordFail, forgotPasswordRequest, forgotPasswordSuccess, changePasswordRequest, 
  changePasswordFail, changePasswordSuccess } = authSlice.actions;
export default authSlice.reducer;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setOtpValue } from "../../redux/Slice/authSlice";
import './otpInput.css';

const OtpInput = () => {
  const dispatch = useDispatch();
  const [otpNumber, setOtpNumber] = useState(["", "", "", "", "", ""]);

  const inputfocus = (elmnt, index) => {
    let temp = [...otpNumber];
    const previousValue = temp[index];
    const value = elmnt.key;
    if (isNaN(+value) && value !== "Backspace" && value !== "Delete") return;
    if (value === "Backspace" || value === "Delete") {
      temp[index] = "";
    } else {
      temp[index] = value;
    }
    setOtpNumber(temp);
    if (value === "Delete" || value === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1 && !previousValue) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };


  useEffect(() => {
    if (otpNumber.length === 6) {
      dispatch(setOtpValue(otpNumber.join("").toString()))
    }
  }, [otpNumber])

  return (
    <div className="container">
      <form className="otpInput text-center d-flex justify-content-between">
        {otpNumber.map((item, i) => (
          <input
            key={i}
            type="text"
            inputMode="numeric"
            maxLength={1}
            className={`otp-input text-center`}
            tabIndex={i + 1}
            pattern="[0-9]"
            onChange={()=>{}}
            onKeyUp={(e) => inputfocus(e, i)}
            autoFocus={i === 0 && true}
            autoComplete="off"
            value={item || ""}
            placeholder=" "
          />
        ))}
      </form>
    </div>
  )
}

export default OtpInput
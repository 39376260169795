import { getDataApi, postDataApi } from "./apiMethod";

const apiConfig = {
  getJob: {
    method: "GET",
    path: "api/jobs",
  },
  getLatestJob:{
    method:"GET",
    path:"api/latestjob"
  },
  applyJob:{
    method:"POST",
    path:"api/applyjob"
  },
  jobDeatil:{
    method:"GET",
    path:"api/jobdetails"
  }
};

export const getJobsRequest = async (data) => {
  const payload = data
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "","Content-type":"application/json","Access-Control-Allow-Credentials": true, }
  let { path } = apiConfig.getJob
  return await postDataApi({ path,data,headers});
};

export const getLatestJobRequest = async(data)=>{
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
  let { path } = apiConfig.getLatestJob;
  return await getDataApi({ path,headers });
}

export const appliedJobRequest =async (data) => {
  let {path} = apiConfig.applyJob;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"multipart/form-data", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({path,data,headers})
}

export const jobDetailsRequest = async (id)=>{
    let {path}=apiConfig.jobDeatil;
    path=`${path}/${id}`
    const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "", "Access-Control-Allow-Credentials": true, }
    return await getDataApi({ path,headers });
}

import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './pagination.css';

const Paginations = ({count,filters, paginatedData}) => { 
    let active = filters?.page;
    let items = [];
    for (let number = 1; number <= count; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={()=>{ paginatedData(number) }}>
                {number}
            </Pagination.Item>,  
        );
    }
  return (
    <div className='my-5 text-center paginationWrapper'>
        <Pagination>
            <Pagination.First className='leftArrow' />
                {items}
            <Pagination.Last className='rightArrow' />
        </Pagination> 
    </div>
  )
}

export default Paginations

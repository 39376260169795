import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './footer.css';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight, faPhoneAlt, faPhone, faFileCircleCheck, } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faGooglePlus, faInstagram, faLinkedin,faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className='footer'>
        <Container>
            <Row className='py-4 py-md-4 justify-content-between topFooter'>
                {/* <Col xs={12} md={4} lg={5} xl={5} className='my-3'>
                    <div className='footer_header'>Subscribe to Our Newsletter</div>
                    <Form>
                        <Form.Group className="position-relative newsletterForm"> 
                            <Form.Control type="email" placeholder="Enter Your Email Address" />
                            <button className='btn btn-global'> SUBSCRIBE</button>
                        </Form.Group>
                     </Form>
                </Col> */}
                <Col xs={12} md={4} lg={4} xl={3} className='my-3'>
                    <div className='footer_header'>Follow Us On</div> 
                    <Nav className="followIcons">
                        <Nav.Link href="https://www.facebook.com/careobfb/" rel="noreferrer" target="_blank" data-original-title="Facebook"><FontAwesomeIcon icon={faFacebookF} /></Nav.Link> 
                        <Nav.Link  href="https://twitter.com/Careob1" rel="noreferrer" target="_blank" data-original-title="Twitter"><FontAwesomeIcon icon={faTwitter} /></Nav.Link> 
                        <Nav.Link href="https://www.youtube.com/channel/UCK31H2rSQNJUjcVVNd220og" rel="noreferrer" target="_blank" data-original-title="Youtube"><FontAwesomeIcon icon={faYoutube} /></Nav.Link> 
                        <Nav.Link  href="https://www.instagram.com/careob_/" rel="noreferrer" target="_blank" data-original-title="Instagram"><FontAwesomeIcon icon={faInstagram} /></Nav.Link> 
                        <Nav.Link href="https://www.linkedin.com/company/careob/" rel="noreferrer" target="_blank" data-original-title="LinkedIn"><FontAwesomeIcon icon={faLinkedin} /></Nav.Link> 
                    </Nav>
                </Col>
                <Col xs={12} md={3} lg={3} xl={2} className='my-3'>
                    <div className='footer_header'>Need Help?</div> 
                    <Nav className="flex-column">
                        <Nav.Link href="#action1"><FontAwesomeIcon icon={faPhone} /> Call Us: +91-9667783922</Nav.Link> 
                    </Nav>
                </Col>
            </Row>
            <Row className='pb-0 pt-4 py-md-4 border-top-blue-1 justify-content-between bottomFooter'>
                <Col xs={12} md={3} lg={3} xl={4} className='my-3'>
                    <div className='footer_logo'>CAREOB</div>
                    <p>We are a team of enthusisats who believe in helping job seekers to get the right job.</p>
                </Col>
                <Col xs={6} md={3} lg={3} xl={2} className='my-3'>
                    <div className='footer_header'>Information</div> 
                    <Nav className="flex-column">
                        <Nav.Link href="/"><FontAwesomeIcon icon={faHandPointRight} /> About Us</Nav.Link>
                        <Nav.Link href="/"><FontAwesomeIcon icon={faHandPointRight} /> Privacy Policy</Nav.Link>  
                    </Nav>
                    <div className='d-md-none my-3'>
                        <div className='footer_header'>Contribute</div> 
                        <Nav className="flex-column">
                        <Nav.Link  href="https://forms.gle/wvUP5yFbACwD3jzB7"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Feedback</Nav.Link>
                        <Nav.Link  href="mailto:team@careob.com"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Press/Partner with Us</Nav.Link> 
                        <Nav.Link  href="mailto:team@careob.com"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Help</Nav.Link> 
                        </Nav>
                    </div>
                </Col>
                <Col xs={6} md={3} lg={3} xl={2} className='my-3'>
                    <div className='footer_header'>Explore</div> 
                    <Nav className="flex-column">
                        <Nav.Link href="https://forms.gle/a3rMkCZ96A7dj4Sf7" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Post A Job</Nav.Link>
                        <Nav.Link href="mailto:team@careob.com" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Resume Cover Letter</Nav.Link> 
                        {/* <Nav.Link href="mailto:team@careob.com" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Carrier Guidance</Nav.Link>  */}
                        <Nav.Link href="mailto:team@careob.com" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Events</Nav.Link> 
                        <Nav.Link href="/jobs" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Jobs</Nav.Link> 
                    </Nav>
                </Col>
                <Col xs={6} md={3} lg={3} xl={2} className='my-3 d-none d-md-block'>
                    <div className='footer_header'>Contribute</div> 
                    <Nav className="flex-column">
                        <Nav.Link  href="https://forms.gle/wvUP5yFbACwD3jzB7"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Feedback</Nav.Link>
                        <Nav.Link  href="mailto:team@careob.com"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Press/Partner with Us</Nav.Link> 
                        <Nav.Link  href="mailto:team@careob.com"rel="noreferrer"target="_blank"><FontAwesomeIcon icon={faHandPointRight} /> Help</Nav.Link> 
                    </Nav>
                </Col>
            </Row>
        </Container>
        <Container fluid className='footer_copyright_text text-center py-3'>
            <Row>
                <Col>
                    <div className='text-white line-height-1'>@ 2023 COPYRIGHT - CAREOB.COM</div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Footer
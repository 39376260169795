import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFormik } from "formik";
import * as momenttz from 'moment-timezone';
import { forgotSchema, loginSchema, forgotOtpSchema } from '../../schemaValidator/loginRegistarSchema';
import ValidationError from '../../components/error/ValidationError';
import { loginRequest, forgotPasswordRequest, changePasswordRequest, changePasswordFail } from '../../redux/Slice/authSlice';
import { history } from '../../utils/history';
import { googleSingup, otpSentFail } from '../../redux/Slice/authSlice';
import { apibaseUrl } from '../../conf/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import OtpPopup from '../../components/popup/OtpPopup';
import './login.css';

const Login = () => {
  const tz = momenttz.tz.guess();
  const dispatch = useDispatch();
  const { isAuthenticated, otpSent, forgotStatus, userEmail } = useSelector((state) => state?.auth);
  const [showPass, setShowPass] = useState({mainPass:false,newPass:false,confPass:false})
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); dispatch(changePasswordFail()) };
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      email: userEmail || "",
      password: "",
      timezone: tz
    },
    validationSchema: loginSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(loginRequest(values))
      resetForm()
    },
  });

  const formikForgotPass = useFormik({
    initialValues: {
      email: "",
      ...(!forgotStatus && { forgotStatus: true }),
      ...(forgotStatus && { newpasswd: "" }),
      ...(forgotStatus && { confirmpasswd: "" })
    },
    validationSchema: !forgotStatus ? forgotOtpSchema : forgotSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(!forgotStatus ? forgotPasswordRequest(values) : changePasswordRequest(values))
      resetForm()
    },
  });

  useEffect(() => {
    if (otpSent) { setShow(false) }
  }, [otpSent])

  useEffect(() => {
    if (isAuthenticated) {
      history.navigate("/profile")
    } else {
      dispatch(otpSentFail())
    }
  }, [isAuthenticated])

  const googleAuthClick = async () => {
    dispatch(googleSingup())
    window.open(
      `${apibaseUrl}/api/google`,
      "_self"
    );
  };

  return (
    <div className='loginWrapper'>
      <Container>
        <Row>
          <Col xs={12} md={5} className='p-3 p-md-3 p-lg-3 p-xl-0 order-2'>
            <div className='loginForm'>
              <h3 className='text-center mb-4 mb-md-5'>LOGIN</h3>
              <div className='LoginGoogle mb-4 position-relative text-center' onClick={() => { googleAuthClick() }} >
                <img src='./google-icon.png' /> Log in with Google
              </div>
              <div className='LoginEmail font-500 position-relative text-center mb-4'>
                OR Login with Email
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="form-field mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email Address" name='email'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email ? <ValidationError children={formik?.errors?.email} /> : ""}
                  {/* <ErrorMessage name="email" component={ValidationError} /> */}
                </Form.Group>

                <Form.Group className="form-field mb-3 position-relative" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type={showPass?.mainPass ? "text" : "password"} placeholder="Enter Password" name='password'
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  /> <span className='eyeicon' onClick={() => { setShowPass({mainPass:!showPass.mainPass}) }}>
                    <FontAwesomeIcon icon={showPass?.mainPass ? faEyeSlash : faEye} size={25} />
                  </span>
                  {formik.errors.password ? <ValidationError children={formik?.errors?.password} /> : ""}

                  {/* <ErrorMessage name="password" component={ValidationError} /> */}
                </Form.Group>
                <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Keep me Logged In" />
                </Form.Group>
                <Button variant="primary" type="submit" className='w-100 mb-3'>
                  LOGIN
                </Button>
                <div className='text-center textLink'>Don't have an account <a className='cursor-pointer underline-text'>Sign Up</a> </div>
                <div className='text-center textLink' onClick={handleShow}>If you forgot password click on <a className='cursor-pointer underline-text'>Forgot Password</a> </div>
              </Form>
            </div>
          </Col>
          <Col xs={12} md={7} className='p-3 p-md-0 order-md-2'>
            <div className='loginBanner h-100 d-flex justify-content-center align-items-center'>
              <img src='./secureLogin.svg' className='img-fluid' />
            </div>
          </Col>
        </Row>
      </Container>
      {otpSent ? <OtpPopup otpSent={otpSent} /> :
        <Modal show={show || forgotStatus} onHide={handleClose}>
          <Form onSubmit={formikForgotPass.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="form-field mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter Email Address" name='email'
                  onChange={formikForgotPass.handleChange}
                  value={formikForgotPass.values.email || userEmail}
                />
                {formikForgotPass.errors.email ? <ValidationError children={formikForgotPass?.errors?.email} /> : ""}
              </Form.Group>
              {
                forgotStatus ?
                  <>
                    <Form.Group className="form-field mb-3 position-relative" controlId="formBasicEmail">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type={showPass?.newPass ? "text" : "password"} placeholder="Enter the new password" name='newpasswd'
                        onChange={formikForgotPass.handleChange}
                        value={formikForgotPass.values.newpasswd}
                      /><span className='eyeicon' onClick={() => { setShowPass({newPass:!showPass.newPass}) }}>
                        <FontAwesomeIcon icon={showPass?.newPass ? faEyeSlash : faEye} size={25} />  </span>
                      {formikForgotPass.errors.newpasswd ? <ValidationError children={formikForgotPass?.errors?.newpasswd} /> : ""}
                    </Form.Group>
                    <Form.Group className="form-field mb-3 position-relative" controlId="formBasicEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type={showPass?.confPass ? "text" : "password"} placeholder="Enter the confirm password" name='confirmpasswd'
                        onChange={formikForgotPass.handleChange}
                        value={formikForgotPass.values.confirmpasswd}
                      /><span className='eyeicon' onClick={() => { setShowPass({
                        ...showPass,
                        confPass:!showPass.confPass}) }}>
                        <FontAwesomeIcon icon={showPass?.confPass ? faEyeSlash : faEye} size={25} />
                      </span>
                      {formikForgotPass.errors.confirmpasswd ? <ValidationError children={formikForgotPass?.errors?.confirmpasswd} /> : ""}
                    </Form.Group>
                  </> : ""
              }

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      }
    </div>
  )
}

export default Login
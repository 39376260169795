import React from 'react'
import { Link } from 'react-router-dom'
import './pageNotFound.css'
import { Container } from 'react-bootstrap'

const PageNotFound = () => {
  return (
    <Container fluid>
        <div className='pageNotFound_Wrapper'> 
            <div className='pageNotFound_image'><img src='./pageNotFound2.svg' className='img-fluid' /></div>
            <div className='pageNotFound_text text-left'>
                <h2>OOPS<span>...</span></h2> 
                <p>We can't seem to find the page that you're looking for..</p>
                <Link to="/" className="btn btn-global text-white">Back to Homepage</Link>
            </div>
        </div>
    </Container> 
  )
}

export default PageNotFound
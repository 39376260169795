import React from 'react'; 
import Container from 'react-bootstrap/Container'; 
import { useDispatch, useSelector } from 'react-redux';
import { connectMe } from '../../redux/Slice/profileSlice';
import { history } from '../../utils/history';

const ExpertCard = ({profileData}) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const disptach = useDispatch()
  const connectExpert = (id)=>{ if(!isAuthenticated){ history.navigate('/login',{state:{from:history.location}}); }
  else{ disptach(connectMe({expertId:id})); history.navigate('/expert-connect',{state:{from:history.location}});}  } 
    
  
  return (
    <div className='mentor_Wrapper mb-4'> 
          <div className=''> 
              <div className='mentorSection text-center'>
                  <div className='mentorProfile'>
                    <img src={profileData?.profilepic || './user-photo.png'} className='userProfile' />
                    <h3>{profileData?.fname+" "+profileData.lname}</h3>
                    <h4>{profileData?.ProfileData?.profession}</h4>
                    <p>{profileData?.ProfileData?.aboutmore}</p>
                    <button className='btn btn-connect' onClick={()=>{connectExpert(profileData?._id)}}>CONNECT ME</button>
                  </div>
              </div> 
          </div>  
    </div>
  )
}

export default ExpertCard
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './notificationList.css';
import TickIcon from '../icons/TickIcon';
import DeleteIcon from '../icons/DeleteIcon'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { meetAccept,meetReject } from '../../redux/Slice/notificationSlice';
import Appointment from '../appointmentForm/Appointment';

const NotificationList = ({ notificationData }) => {
  const dispatch = useDispatch();
  const notificationList = useSelector(state => state?.notificationList || []);
  const [notificId, setNotificId] = useState(null);
  const [openaccept, setOpenAccept] = useState(false);
  const [openreject, setOpenReject] = useState(false);
  const { auth } = useSelector((state) => state.auth)
  const {myprofile} = useSelector((state) => state.profile)

  const authRole = auth?.role;
   const profileRole = myprofile?.role

  const fieldsForHeader = (authRole == 'student') || (profileRole === 'student') ? 
  [
  { id: "expertname", label: "Expert Name" },
  { id: "connectinfo", label: "Connect Info" },
  { id: "rejectinfo", label: "Reject Info" },
  { id: "starttime", label: "Meet Start" },
  { id: "endtime", label: "Meet End" },
  { id: "meetstatus", label: "Meet Status" },] : 
  [
    { id: "candidatename", label: "Candidate Name" },
  { id: "connectinfo", label: "Connect Info" },
  { id: "rejectinfo", label: "Reject Info" },
  { id: "starttime", label: "Meet Start" },
  { id: "endtime", label: "Meet End" },
  { id: "meetstatus", label: "Meet Status" },
  { id: "action", label:"Action" }
  ]

  const firstKey = authRole || profileRole  == 'student' ? 'expertname' : 'candidatename'
  const meetAcceptCal = (data) =>{ dispatch(meetAccept({status:true, notificInfo:data}))  }
  const meetRejectCal = (data) =>{ dispatch(meetReject({status:true, notificInfo:data})) }

  const headerFields = fieldsForHeader;
  return (
    <div className='notificationWraper py-5'>
      <Container>
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Connect Name</th>
              <th>Connect Info</th>
              <th>Reject Info</th>
              <th>Meet Start</th>
              <th>Meet Link</th>
              <th>Meet Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!notificationList.isLoading && notificationData?.map((items, index) => (
              <tr key={`notiList-${index}`}>
                <td>{items?.ConnectInfo?.fname} {items?.ConnectInfo?.lname}</td>
                <td>{items?.MeetData?.connectinfo}</td>
                <td>{items?.MeetData?.rejectinfo || ""}</td>
                <td>{items?.MeetData?.startdate} {items?.MeetData?.starttime}</td>
                <td className="MeetLink" title={items?.MeetLink[0]?.link}><Link to={items?.MeetLink[0]?.link}>{items?.MeetLink[0]?.link}</Link></td>
                <td>{items?.connectstatus}</td>
                <td>
                  {items?.action === 'expert' && items?.connectstatus === 'connect' ? 
                  <>
                  <div className='notific-accept' onClick={()=>{meetAcceptCal(items)}}>
                  <TickIcon color="#63b731" width="22px" height="22px" />
                  </div>
                  <div className='notific-delete' onClick={()=>{meetRejectCal(items)}}>
                  <DeleteIcon color="#ff0000" width="22px" height="22px" />
                  </div>
                  </>
                  :
                  items?.action === 'student' && items?.connectstatus === 'connect' ? "Pending"  
                  : 
                  (items?.action === 'expert'|| items?.action === 'student') && items?.connectstatus === 'expired' ? "Expired" 
                  :
                  (items?.action === 'expert'|| items?.action === 'student') && items?.connectstatus === 'reject'? "Reject"
                  :
                  (items?.action === 'expert' || items?.action === 'student') && items?.connectstatus === 'accept'? "Accept"
                  : ""
                  }
                  
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      {notificationList?.meetAccept ? <Appointment /> :""}
      {notificationList?.meetReject ? <Appointment /> :""}
    </div>
  )
}

export default NotificationList
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/Slice/authSlice';
import { useEffect, useState } from 'react';
import { apibaseUrl } from '../../conf/config';

/** Custome file imports start here  */
import './header.css';

const Header = () => {
  const dispatch = useDispatch();
  const [show,setShow] = useState(false);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const logoutClick = () => {
    dispatch(logout())
    window.open(`${apibaseUrl}/api/logout`, "_self");
  }
  useEffect(() => {
    let scrollpos = window.scrollY;
    const header = document.querySelector("nav");
    const header_height = header?.offsetHeight;
    const add_class_on_scroll = () => header.classList.add("sticky")
    const remove_class_on_scroll = () => header.classList.remove("sticky")
    const add_class_on_body = () => document.querySelector('body').classList.add("scrolled500")
    const remove_class_on_body = () => document.querySelector('body').classList.remove("scrolled500")
    window.addEventListener('scroll', function () {
      scrollpos = window.scrollY;
      if (scrollpos >= header_height) {
        add_class_on_scroll();
        add_class_on_body();
      } else {
        remove_class_on_scroll();
        remove_class_on_body();
      }
    })
  }, []);
  const handleClose = ()=>{
    setShow(false)
  }

  return (
    <div className='header'>
      <Navbar key={'md'} expand="md" className="bg-body-tertiary" bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand><Nav.Link as={Link} to="/"><img src='./CAREOB-white.png' style={{width: '100px'}} /></Nav.Link></Navbar.Brand>
          <Navbar.Toggle bg="light" data-bs-theme="light" aria-controls={`offcanvasNavbar-expand-${'md'}`} onClick={()=>{setShow(true)}} />
          
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${'md'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'md'}`}
            placement="end"
            show={show} onHide={handleClose}
          >
            {/* <Offcanvas show={show} onHide={handleClose}> */}
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'md'}`}>
                <img src='./CAREOB-white.png' style={{width: '100px'}} className='img-fluid' />
              </Offcanvas.Title>
            </Offcanvas.Header>
            {/* </Offcanvas> */}
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 mb-4 mb-md-0">
                <Nav.Link as={Link} to="/"  onClick={()=>{setShow(false)}}>Home</Nav.Link>
                {/* <Nav.Link href="#action2">About Us</Nav.Link> */}
                <Nav.Link as={Link} to="/jobs" onClick={()=>{setShow(false)}}>Jobs</Nav.Link>
                {/* <Nav.Link href="#action2">Careers</Nav.Link> */}
                <Nav.Link as={Link} to="/contactus" onClick={()=>{setShow(false)}} >Contact Us</Nav.Link>
              </Nav>
              <Nav className="justify-content-end flex-grow-1">
                {/* Before login */}
                {isAuthenticated ? "" :
                  <Nav.Link as={Link} to="/login" onClick={()=>{setShow(false)}}><FontAwesomeIcon icon={faUserAlt} /> Login</Nav.Link>}
                {/* Before login */}
                {/* After login */}
                {isAuthenticated ?
                  <>                  
                  <Nav.Link as={Link} to="/notification" onClick={()=>{setShow(false)}}>
                    <svg width="20" height="20" fill="currentColor" className="bi bi-bell" viewBox="0 0 20 20">
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                  </Nav.Link>
                    <NavDropdown title="Username" id="profile">
                      <NavDropdown.Item as={Link} to="/profile" onClick={()=>{setShow(false)}} >Profile</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item as={Link} to="/experts" onClick={()=>{setShow(false)}} >Experts</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={() => { logoutClick(); setShow(false) }}  >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                  : ""}
                {/* After login */}
                {isAuthenticated ? "" : <Nav.Link as={Link} to="/registration" onClick={()=>{setShow(false)}} ><FontAwesomeIcon icon={faUserPlus} /> SignUp</Nav.Link>}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header;
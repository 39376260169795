import React from 'react';
import './jobSearch.css';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getJobSearchRequest } from '../../redux/Slice/jobsListSlice';
import { history } from '../../utils/history';

const JobSearch = () => {
  const dispatch = useDispatch()
  const jobSearchval = useSelector((state)=> state?.jobsList?.search) || ""
  const search = async(event)=>{
      dispatch(getJobSearchRequest(event.target.value))
  }
  const buttonCliked = window.location.pathname != 'jobs' ? true: false
  const searchJob = ()=>{
    if(buttonCliked){
      history.navigate('/jobs')
    }
    else {   return -1 }
  }
  return (
    <div className='d-flex'>
        <div className='searchWrapper d-flex'>
            <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder='Job Title Or Keyword'
                onChange={(event)=>{ search(event) }}
                value={jobSearchval || ""}
            /> 
            <button className='btn btnSearch' onClick={()=>{searchJob()}}>Search</button>
        </div>
    </div>
  )
}

export default JobSearch
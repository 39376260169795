import { getDataApi, postDataApi } from "./apiMethod";
const config = {
  meetConnectAcceptReject: {
    method: "POST",
    path: "api/meetstatus",
  }

};
export const meetConnectAcceptRejectRequest = async(data)=>{
  let {path} = config.meetConnectAcceptReject;
  const headers = { "Authorization": `Bearer ${localStorage.getItem('accessToken')}`|| "",  "Content-Type":"application/json", "Access-Control-Allow-Credentials": true, }
  return await postDataApi({path,data,headers})
}
import { createSlice } from "@reduxjs/toolkit";

const notificSlice = createSlice({
    name:'notification',
    initialState:{
      isLoading:false,
      notificList:null,
      meetAccept:false,
      meetReject:false,
      notificInfo:null,
      error:null
    },
    reducers:{
      getNotificationListFetch:(state)=>{
         state.isLoading = true
      },
      getNotificationListSuccess:(state,action)=>{
        state.notificList = action.payload
        state.isLoading = false
      },
      getNotificationListFail:(state)=>{
        state.isLoading = false
      },
      meetAccept:(state,action)=>{
        state.meetAccept = action.payload?.status
        state.notificInfo = action.payload?.notificInfo
      },
      meetReject:(state,action)=>{
       state.meetReject = action.payload?.status
       state.notificInfo = action.payload?.notificInfo
      },
    }
});

export const { getNotificationListFetch, getNotificationListSuccess, getNotificationListFail, meetAccept,meetReject } = notificSlice.actions

export default notificSlice.reducer;
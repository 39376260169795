import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getLocListFetch, getCitiesListFetch } from '../../redux/Slice/locSlice';
import { getJobCitiesRequest,getJobCountryRequest, addJobMode, addJobType, 
  removeJobMode, removeJobType, getJobListFail, setclearFilter } from '../../redux/Slice/jobsListSlice';
import './filter.css';

const Filter = () => {
  const dispatch = useDispatch()
  const {country, city} = useSelector((state) => state?.location)
  const {countries,cities,jobtype,jobmode,clearFilter}= useSelector((state) => state?.jobsList)
  const JobType = [{value:"Permanent", label: "Fulltime Job" }, {value:"parttime", label: "Part-time Job" }, 
  {value:"internship", label: "Internship" }, {value:"projectwork", label: "Project Work" }]
  const JobMode = [{value:"onsite", label: "On-Site" }, {value:"hybrid", label: "Hybrid" },{value:"remote", label: "Remote" }]
  const [filtershow, setFilterShow] = useState(false);
  
  useEffect(() => {
    dispatch(getLocListFetch())
  }, []);
  useEffect(() => {
    {window?.screen?.availWidth >= 767 ? setFilterShow(true) : setFilterShow(false)}
    if (countries !== null) {
      dispatch(getCitiesListFetch({ name: countries }))
    }
  }, [countries])

  const countryCitySelect = (event, key) => {
    switch (key) {
      case 'country':
        dispatch(getJobCountryRequest(event.target.value))
        break;
      case 'city':
        dispatch(getJobCitiesRequest(event.target.value));
        break;
    }
  }


 const checkBoxCheck = (event,index,type)=>{
  switch(type){
     case 'jobtype':
      if (event.target.checked) {
         dispatch(addJobType(event.target.value))
      } else {
        dispatch(removeJobType(event.target.value))
      }
      break;
     case 'jobmode':
      if (event.target.checked) {
        dispatch(addJobMode(event.target.value))
      } else {
        dispatch(removeJobMode(event.target.value))
      }
     break; 

  }
}

const clearFilters = ()=>{
  dispatch(getJobListFail())
  window.location.reload();
  //dispatch(setclearFilter(true))
}

  return (
    <div className='jobFilter'>
      <div className='d-flex justify-content-between filterHeading'>
        <h3>Filter <span onClick={() => setFilterShow(!filtershow)} className='mx-2 d-md-none'><img src='./downArrow-icon.png' style={{width: '16px'}}/></span></h3>
        <p className="mb-0" onClick={()=>{clearFilters()}}>Clear All</p>
      </div> 
      {filtershow && (  
        <div className='filterWrap'>
          <div className='Location mb-4'>
            <Form.Label>Locations</Form.Label>
            <Form.Select onChange={(event) => { countryCitySelect(event, 'country') }}>
              <option>{countries || "Select Country"}</option>
              {country?.map((value, index) => (
                <option key={`country-${index}`} value={value.name}>{value.name || countries}</option>
              ))}
            </Form.Select>
            <Form.Select className='mt-3' disabled={countries !== null ? false : true} onChange={(event) => { countryCitySelect(event, 'city') }}>
              <option>{cities || "Select City"}</option>
              {city?.map((value, index) => (
                <option key={`city-${index}`} value={value.name}>{value.name || cities}</option>
              ))}
            </Form.Select>
          </div>
          <div className='jobType mb-4 pb-3'>
            <Form.Label>Job Types</Form.Label>
            {JobType.map((value, index) => (
              <Form.Group className="form-field mb-2" controlId="formBasicCheckbox" key={`jobType-${index}`}>
                <Form.Check type="checkbox" value={value?.value} defaultChecked={jobtype?.includes(value?.value) || false} onChange={(event)=>{checkBoxCheck(event,index,'jobtype')}} label={value?.label} />
              </Form.Group>
            ))}

          </div>
          {/* <div className='jobType mb-4 pb-3'>
                  <Form.Label>Range Salary</Form.Label> 
                  <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="radio" label="Under $1000" />
                  </Form.Group>
                  <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="radio" label="$1000 - $2500" />
                  </Form.Group>
                  <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="radio" label="$2500 - $5000" />
                  </Form.Group>
                  <Form.Group className="form-field mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="radio" label="Custom" />
                  </Form.Group>
              </div> */}
          <div className='jobType'>
            <Form.Label>OnSite/Remote</Form.Label>
            {JobMode.map((value, index) => (
              <Form.Group className="form-field mb-3" controlId="formBasicCheckbox" key={`jobMode-${index}`}>
                <Form.Check type="checkbox" value={value?.value} defaultChecked={jobmode?.includes(value?.value) || false}  onChange={(event)=>{checkBoxCheck(event,index,'jobmode')}} label={value?.label} />
              </Form.Group>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Filter
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import JobIntrested from '../jobIntrested/JobIntrested';  
import JobDescription from '../jobDescription/JobDescription';
import './jobDetail.css';
import { useDispatch } from 'react-redux';
import { getJobDetailsFetch } from '../../redux/Slice/jobsListSlice';
import { useParams } from 'react-router-dom';

const JobDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  useEffect(()=>{
     dispatch(getJobDetailsFetch(id))
  },[])
  return (
    <>
        <div className='jobDetailWrapper'> 
            <Container fluid>

            </Container>
            <Container>
                <Row>
                    <Col md={8}> 
                        <JobDescription />
                    </Col>
                    {/* <Col md={4}>
                        <JobIntrested />
                    </Col> */}
                </Row>
            </Container>
        </div>
    </>
  )
}

export default JobDetails
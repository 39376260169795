export const PATHS = {
  HOMEPAGE: "/",
  LANDINGPAGE:"/",
  JOBS: "/jobs",
  LOGIN: "/login",
  REGISTRATION: "/registration",
  CONTACTUS: "/contactus",
  JOBDETAILS: "/job-details/:id",
  PROFILE: "/profile",
  EDITPROFILE: "/edit-profile",
  EXPERTS: '/experts',
  NOTIFICATION: '/notification',
  EXPERTCONNECT:"/expert-connect",
  NOTFOUND:"*"
}

import axios from 'axios';
import {checkConnection} from '../utils/alerts'
import { apibaseUrl } from '../conf/config';

const $axios = axios.create({
  baseURL: apibaseUrl,
  timeout: 20000
});

export const getDataApi = async ({
  path = 'no-path-provided',
  data = {},
  headers = {}
}) => {
  try {
    checkConnection();
    const response = await $axios.get(path, { headers: headers, params: data });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getGoogleAuth =async({
  path = 'no-path-provided',
})=>{
  try {
    checkConnection();
    const response = await $axios.get(path, {withCredentials:true});
    return response;
  } catch (error) {
    return error.response;
  }
}

export const postDataApi = async ({
  path = 'no-path-provided',
  data = {},
  headers = {}
}) => {
  try {
    checkConnection();
    const response = await $axios.post(path, data, { headers: headers });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const putDataApi = async ({ path = 'no-path-provided', data = {} }) => {
  try {
    checkConnection();
    const response = await $axios.put(path, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}` || ''
      }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteDataApi = async ({ path = 'no-path-provided', data = {} }) => {
  try {
    checkConnection();
    const response = await $axios.delete(path, data)
    return response
  } catch (error) {
    return error.response
  }
}
import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useFormik } from "formik";
import { connectSchudler, acceptScheduler, rejectScheduler } from '../../schemaValidator/loginRegistarSchema';
import { useDispatch, useSelector } from 'react-redux';
import { connectClicked } from '../../redux/Slice/profileSlice';
import ValidationError from '../error/ValidationError';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { meetRequestStart } from '../../redux/Slice/meetandslotSlice';
import { meetAccept, meetReject } from '../../redux/Slice/notificationSlice';
import "react-datepicker/dist/react-datepicker.css";

const Appointment = () => {
  const dispatch = useDispatch();
  const { myprofile, connectMe, isLoading, connectClick } = useSelector((state) => state?.profile) || [];
  const notific = useSelector((state) => state?.notificationList)
  const modelOpen = connectClick || notific.meetAccept || notific.meetReject || false;

  const formik = useFormik({
    initialValues: {
      title:  notific?.meetAccept || notific?.meetReject ? notific?.notificInfo?.MeetData?.title : "",
      description:  connectClick || notific?.meetAccept ? notific?.notificInfo?.MeetData?.connectinfo :"",
      startdate: ""
    },
    validationSchema: connectClick? connectSchudler : notific?.meetAccept ? acceptScheduler : rejectScheduler ,
    onSubmit: (values, { resetForm }) => {
      const payloadObject = { 
        title: values.title || "", 
        ...((connectClick || notific?.meetAccept) && {connectinfo: values.description || ""}),
        ...(notific?.meetReject && {rejectinfo: values.description || ""}),  
        ...((connectClick || notific?.meetAccept) && {startdate: moment(values.startdate).format("YYYY-MM-DD") || new Date()}), 
        ...((connectClick || notific?.meetAccept) && {starttime: moment(values.startdate).format("HH:mm:00") || ""}), 
        ...(connectClick && {expertId: myprofile._id || ""}), 
        ...(notific.meetAccept &&{expiredate:moment(Date.parse(values.startdate)+900000).format("YYYY-MM-DD") || ""}),
        ...(notific.meetAccept &&{expiretime:moment(Date.parse(values.startdate)+900000).format("HH:mm:00") || ""}),
        ...((notific.meetAccept || notific.meetReject) &&{notificId:notific?.notificInfo?._id || ""}),   
        connectstatus: connectClick ? 'connect' : notific?.meetAccept ? 'accept' : "reject" }
      dispatch(meetRequestStart(payloadObject))
      resetForm()
    },
  });

  const handleClose = () => {
    dispatch(connectClicked(false))
    dispatch(meetAccept(false));
    dispatch(meetReject(false));
  }



  return (
    <Modal
      className='otpPopup-Wrapper' animation={false}
      show={modelOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Meet Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="form-field mb-3" controlId="MeetingTitle">
            <Form.Label>Meeting Title</Form.Label>
            <Form.Control type="text" placeholder="Meeting Title" name="title"
              onChange={formik.handleChange} value={formik.values.title} />
            {formik?.errors?.title ? <ValidationError children={formik?.errors?.title} /> : ""}
          </Form.Group>
          <Form.Group className="form-field mb-3" controlId="Description">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows={3} name="description"
              onChange={formik.handleChange} value={formik.values.description} />
            {formik?.errors?.description ? <ValidationError children={formik?.errors?.description} /> : ""}
          </Form.Group>
          {notific?.meetReject ? "" :
            <Form.Group className="form-field mb-3" controlId="Email">
              <DatePicker
                name="startdate"
                placeholderText="Select Meet Start Date"
                selected={formik.values.startdate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-100 form-control"
                id="end"
                onChange={(newValue) => {
                  formik.setFieldValue('startdate', newValue)
                }}

              />
              {formik?.errors?.startdate ? <ValidationError children={formik?.errors?.startdate} /> : ""}
            </Form.Group>
          }
          <Button variant="primary" type="submit" className='w-100 mb-3'>
            Send Request
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default Appointment
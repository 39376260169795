import {configureStore } from '@reduxjs/toolkit'
import logger from "redux-logger";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from '@redux-saga/core';
import rootSagas from "./Saga/index";
import rootReducer from './reducer';
import { prod } from '../conf/config';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth","jobsList","location","profile"]
}

const saga = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  ...(!prod &&{devTools:true}),
  middleware: [saga]
})

export const persistor = persistStore(store)
saga.run(rootSagas);

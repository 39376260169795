import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import ExpertCard from '../../components/expertCard/ExpertCard';
import './experts.css';
import Paginations from '../../components/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { getListProfileFetch } from '../../redux/Slice/profileSlice';
import { useSelector } from 'react-redux';

const Experts = () => {
  const dispatch = useDispatch();
  const expertList = useSelector((state)=> state?.profile?.profileList)
  const [filters, setFilters] = useState({limit: 10, page: 1 });
  const paginatedData = (page) => {
    setFilters((prevState) => ({
      ...prevState,
      limit: 10,
      page: page
    }));
  }

  useEffect(()=>{
    dispatch(getListProfileFetch(filters))
  },[])

  return (
    <div className='profileWrapper'>
      <h1 className='text-center pb-5 text-white'>Experts</h1>
      <div className='expertWrapper'> 
          <Container>
              <Row>  
                {expertList?.data?.map((value,index)=> (
                  <Col lg={4} md={6} sm={6} key={`expertList-${index}`}>
                    <ExpertCard profileData={value} />
                  </Col>
                ))}
                  
                  {/* <Col lg={4} md={6} sm={6}>
                    <ExpertCard />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <ExpertCard />
                  </Col> 
                  <Col lg={4} md={6} sm={6}>
                    <ExpertCard />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <ExpertCard />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <ExpertCard />
                  </Col>   */}
                </Row>
                <Row md={12}>
                  <Paginations /> 
                </Row>
          </Container>
      </div> 
    </div>
  )
}

export default Experts
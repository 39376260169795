import React from 'react'

const ContactSVG = () => {
  return (
        <svg version="1.1" x="0px" y="0px"
            viewBox="0 0 500 500" style={{enableBackground: 'new 0 0 500 500'}}>
        <g id="BACKGROUND">
        </g>
        <g id="OBJECTS">
            <rect style={{fill:'#DEF0F3'}} width="500" height="500"/>
            <g>
                <g>
                    <g>
                        <path style={{fill:'#7497A7'}} d="M94.969,372.415h-7.904c-0.835,0-1.53-0.64-1.598-1.473l-1.877-22.855h14.855l-1.877,22.855
                            C96.499,371.775,95.804,372.415,94.969,372.415z"/>
                        <rect x="87.968" y="372.415" style={{fill:'#BDD3D6'}} width="6.099" height="39.136"/>
                        <path style={{fill:'#7497A7'}} d="M96.171,430.094H85.864v-17.152c0-0.768,0.622-1.39,1.39-1.39h7.526
                            c0.768,0,1.39,0.622,1.39,1.39V430.094z"/>
                        <path style={{fill:'#BDD3D6'}} d="M134.011,432.136H48.024c-2.291,0-4.148-1.857-4.148-4.148l0,0c0-2.291,1.857-4.148,4.148-4.148
                            h85.987c2.291,0,4.148,1.857,4.148,4.148l0,0C138.159,430.279,136.302,432.136,134.011,432.136z"/>
                        <g>
                            
                                <ellipse transform="matrix(0.1826 -0.9832 0.9832 0.1826 -380.7846 421.5888)" style={{fill:'#7497A7'}} cx="63.145" cy="439.792" rx="7.656" ry="7.656"/>
                            
                                <ellipse transform="matrix(0.2204 -0.9754 0.9754 0.2204 -365.7741 421.9477)" style={{fill:'#7497A7'}} cx="81.072" cy="439.792" rx="7.656" ry="7.656"/>
                            
                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -281.9838 198.8155)" style={{fill:'#7497A7'}} cx="99" cy="439.792" rx="7.656" ry="7.656"/>
                            
                                <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -335.9159 484.763)" style={{fill:'#7497A7'}} cx="116.927" cy="439.792" rx="7.656" ry="7.656"/>
                        </g>
                    </g>
                    <g>
                        <polygon style={{fill:'#7497A7'}} points="98.963,202.555 47.102,202.555 47.102,339.632 124.584,339.632 124.584,202.555 				"/>
                        <path style={{fill:'#BDD3D6'}} d="M47.102,202.555c0,0,7.37,1.371,7.624,4.929c0.254,3.558-1.016,34.308-2.795,56.417
                            c-1.779,22.109,0.319,57.942,2.828,64.549c2.509,6.608,3.095,7.989,17.249,7.624c14.154-0.365,47.774-0.365,47.774-0.365
                            s6.291,0.089,4.803,4.803c-1.488,4.714-2.403,7.575-2.403,7.575H66.162c0,0-21.347,4.251-22.872-15.571
                            c-1.525-19.822-1.779-50.826-2.287-67.599s-0.508-39.391,0-49.048S42.019,203.98,47.102,202.555z"/>
                    </g>
                </g>
                <g>
                    <rect x="123.178" y="61.129" style={{fill:'#FFFFFF'}} width="139.863" height="240.877"/>
                    <rect x="127.11" y="65.545" style={{fill:'#BDD3D6'}} width="132.722" height="232.005"/>
                    <rect x="127.11" y="65.545" style={{fill:'#798586'}} width="132.722" height="44.359"/>
                    <circle style={{fill:'#E4E473'}} cx="229.384" cy="127.087" r="9.205"/>
                    <path style={{fill:'#FFFFFF'}} d="M164.216,148.975h40.698c0,0,0.452-11.804-7.112-8.349c0,0-1.233-9.213-9.725-9.213
                        c-8.492,0-12.81,13.193-12.81,13.193S169.288,135.732,164.216,148.975z"/>
                    <path style={{fill:'#FFFFFF'}} d="M203.155,190.72h56.677v-10.894c0,0-1.363-7.541-11.727-8.4
                        c-10.364-0.859-19.865,10.121-19.865,10.121S214.345,169.703,203.155,190.72z"/>
                    <path style={{fill:'#FFFFFF'}} d="M127.11,195.326h41.175c0,0-1.729-14.483-13.459-5.758c0,0-1.59-10.859-7.128-8.021
                        c0,0-6.559-14.54-20.588-10.169V195.326z"/>
                    <g>
                        <polygon style={{opacity:'0.23',fill:'#FFFFFF'}} points="181.174,109.904 127.11,227.283 127.11,250.314 193.982,109.904 				"/>
                        <polygon style={{opacity:'0.23',fill:'#FFFFFF'}} points="212.138,109.904 127.11,281.661 127.11,297.55 173.881,297.55 
                            259.832,108.669 				"/>
                    </g>
                    <rect x="127.11" y="74.153" style={{fill:'#FFFFFF'}} width="132.722" height="2.112"/>
                    <rect x="127.11" y="86.472" style={{fill:'#FFFFFF'}} width="132.722" height="2.112"/>
                    <rect x="127.11" y="98.792" style={{fill:'#FFFFFF'}} width="132.722" height="2.112"/>
                </g>
                <g>
                    <g>
                        <g>
                            <path style={{fill:'#2C8E96'}} d="M171.65,265.931c-1.422,6.321,0.07,12.04,3.331,12.773c3.262,0.734,7.058-3.796,8.48-10.117
                                s-0.07-12.04-3.331-12.773C176.868,255.081,173.071,259.61,171.65,265.931z"/>
                            <g>
                                <path style={{fill:'#EA7566'}} d="M175.808,257.086c0,0,15.724,1.054,20.074,1.556c4.349,0.502,8.029,4.546,10.204,7.208
                                    c2.175,2.662,5.688,11.026,6.022,14.371c0.335,3.346-2.175,3.68-2.175,3.68s-2.175,3.513-5.018,2.007
                                    c0,0-3.178,1.506-5.52-1.171c-2.342-2.677-5.421-5.446-5.421-5.446s-3.612-2.082-5.285-2.082
                                    c-1.673,0-3.346,1.673-7.026,1.171c-3.68-0.502-8.2-3.68-8.2-3.68S170.288,266.236,175.808,257.086z"/>
                                <g>
                                    <path style={{fill:'#051B2D'}} d="M199.227,272.693c1.318,2.043,2.349,4.21,3.312,6.406c0.934,2.208,1.799,4.447,2.375,6.809
                                        c-1.32-2.042-2.351-4.209-3.312-6.406C200.67,277.293,199.805,275.055,199.227,272.693z"/>
                                </g>
                                <g>
                                    <path style={{fill:'#051B2D'}} d="M204.914,269.348c1.216,2.295,2.14,4.69,2.992,7.11c0.823,2.43,1.572,4.886,2.027,7.443
                                        c-1.218-2.294-2.142-4.69-2.992-7.11C206.12,274.36,205.371,271.904,204.914,269.348z"/>
                                </g>
                            </g>
                            <path style={{fill:'#34A9B7'}} d="M139.995,197.983c0,0,14.033,0.322,16.121,9.092c2.088,8.77,0.835,45.523,0.835,45.523
                                l24.223,2.923c0,0-7.068,1.879-7.293,10.65c-0.225,8.771,3.952,12.813,3.952,12.813H134.58L139.995,197.983z"/>
                        </g>
                        <path style={{fill:'#34A9B7'}} d="M139.995,197.983c0,0,6.62,17.519,9.296,23.875c2.676,6.357,4.015,16.226,1.171,22.917
                            c-2.844,6.691-11.375,23.434-11.375,23.434v16.546H77.862c0,0,13.048-37.471,15.724-49.515
                            c2.676-12.044,1.983-30.644,1.983-30.644L139.995,197.983z"/>
                        <g>
                            <path style={{fill:'#051B2D'}} d="M146.765,215.588c2.215,4.145,3.883,8.595,4.934,13.209c1.034,4.594,1.365,9.502,0.002,14.161
                                l-0.657,1.693c-0.212,0.57-0.507,1.065-0.756,1.601l-1.559,3.158c-1.047,2.102-2.059,4.22-3.14,6.305l-3.203,6.273
                                c-1.101,2.074-2.171,4.163-3.299,6.223c0.921-2.163,1.901-4.296,2.85-6.444l2.945-6.394c0.968-2.137,2.006-4.241,3.008-6.362
                                l1.515-3.175c0.238-0.53,0.538-1.056,0.725-1.59l0.598-1.596c0.645-2.181,0.987-4.474,0.943-6.787
                                c-0.044-2.308-0.291-4.622-0.738-6.907c-0.436-2.285-1.005-4.554-1.733-6.777C148.51,219.943,147.691,217.746,146.765,215.588z
                                "/>
                        </g>
                        <g>
                            <path style={{fill:'#2C8E96'}} d="M173.881,256.151c0,0-6.27,6.447-6.256,12.409c0.013,5.963,3.078,13.017,3.078,13.017
                                s7.618-3.614,7.618-13.368C178.321,258.456,173.881,256.151,173.881,256.151z"/>
                            <g>
                                <path style={{fill:'#EA7566'}} d="M171.539,259.446c0,0,15.724,1.054,20.074,1.556c4.349,0.502,8.029,4.546,10.204,7.208
                                    s5.688,11.026,6.022,14.371c0.335,3.346-2.175,3.68-2.175,3.68s-2.175,3.513-5.018,2.007c0,0-3.178,1.505-5.52-1.171
                                    c-2.342-2.676-5.421-5.446-5.421-5.446s-3.612-2.082-5.285-2.082c-1.673,0-3.346,1.673-7.026,1.171
                                    c-3.68-0.502-8.199-3.679-8.199-3.679S166.019,268.596,171.539,259.446z"/>
                                <g>
                                    <path style={{fill:'#051B2D'}} d="M194.958,275.054c1.318,2.043,2.349,4.21,3.312,6.406c0.934,2.208,1.799,4.447,2.375,6.809
                                        c-1.32-2.043-2.351-4.209-3.312-6.406C196.401,279.654,195.536,277.415,194.958,275.054z"/>
                                </g>
                                <g>
                                    <path style={{fill:'#051B2D'}} d="M175.726,259.734c4.102,0.171,8.199,0.38,12.292,0.643l3.07,0.189
                                        c1.076,0.063,2.113,0.295,3.118,0.66c1,0.371,1.95,0.908,2.784,1.598c0.791,0.663,1.57,1.349,2.319,2.069
                                        c1.495,1.441,2.883,3.017,4.037,4.769c0.579,0.874,1.09,1.795,1.527,2.752c0.429,0.975,0.78,1.916,1.165,2.873
                                        c0.737,1.92,1.556,3.795,2.072,5.864c0.12,0.509,0.201,1.1,0.155,1.65c-0.032,0.527-0.092,1.06-0.217,1.595
                                        c-0.133,0.529-0.314,1.078-0.713,1.559c-0.371,0.508-1.111,0.768-1.71,0.685l-0.251-0.035l-0.072-0.22
                                        c-1.599-4.877-3.176-9.761-4.658-14.677c1.864,4.785,3.632,9.603,5.379,14.429l-0.323-0.255
                                        c0.849,0.104,1.333-0.729,1.51-1.677c0.102-0.466,0.156-0.957,0.179-1.453c0.033-0.475-0.034-0.912-0.151-1.399
                                        c-0.498-1.911-1.34-3.81-2.097-5.705c-0.391-0.941-0.771-1.918-1.18-2.816c-0.43-0.902-0.921-1.775-1.459-2.622
                                        c-1.085-1.689-2.406-3.228-3.847-4.648c-0.721-0.71-1.476-1.389-2.249-2.053c-0.741-0.625-1.593-1.124-2.512-1.477
                                        c-0.911-0.363-1.889-0.62-2.859-0.709l-3.065-0.296C183.886,260.645,179.804,260.209,175.726,259.734z"/>
                                </g>
                            </g>
                            <path style={{fill:'#34A9B7'}} d="M95.57,204.597c-3.489-1.976-16.417,7.224-13.215,19.101
                                c3.202,11.877,27.959,50.351,33.145,54.533c5.186,4.182,8.866,3.178,28.103,3.346c19.237,0.167,27.099,0,27.099,0
                                s-3.68-8.029-1.84-15.223c1.84-7.193,5.018-10.204,5.018-10.204H134.58C134.58,256.151,99.059,206.573,95.57,204.597z"/>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M110.829,223.625c4.12,5.303,8.16,10.664,12.181,16.039c4.038,5.363,7.98,10.796,11.975,16.19
                                    l-0.405-0.206l9.825,0.026l9.825,0.043c6.55,0.09,13.1,0.143,19.65,0.31l0.045,0.001l0.027,0.023
                                    c1.213,0.997,2.241,2.236,2.826,3.681c-0.668-1.403-1.761-2.563-2.971-3.485l0.072,0.024c-6.55,0.167-13.1,0.221-19.65,0.311
                                    l-9.825,0.043l-9.825,0.026l-0.257,0.001l-0.149-0.207c-3.922-5.447-7.897-10.856-11.776-16.335
                                    C118.503,234.645,114.626,229.164,110.829,223.625z"/>
                            </g>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M173.881,256.151c-0.666,0.92-1.292,1.858-1.833,2.844c-0.565,0.97-1.065,1.973-1.487,3.003
                                    c-0.855,2.053-1.428,4.207-1.552,6.399c-0.141,2.234-0.132,4.474,0.066,6.698c0.097,1.112,0.265,2.22,0.528,3.305
                                    c0.232,1.096,0.588,2.162,1.1,3.178c-1.392-1.832-2.013-4.136-2.406-6.381c-0.39-2.268-0.428-4.576-0.306-6.861
                                    c0.156-2.329,0.801-4.619,1.86-6.665C170.888,259.615,172.225,257.716,173.881,256.151z"/>
                            </g>
                        </g>
                        <g>
                            <path style={{fill:'#EA7566'}} d="M108.872,179.826l-1.538,17.689l-11.765,7.082c0,0,15.92,14.808,30.26,12.35
                                c14.34-2.458,14.164-18.964,14.164-18.964l-8.838-0.468l-3.673-13.452L108.872,179.826z"/>
                            <g>
                                <path style={{fill:'#EA7566'}} d="M131.376,149.497c0,0,1.442,2.563,2.083,6.248c0.641,3.685,1.121,11.214,1.121,16.02
                                    c0,4.806-1.922,13.777-8.971,14.738c-7.049,0.961-14.898-3.524-18.743-9.131c0,0-7.209,3.204-7.85-2.723
                                    c-0.641-5.927,4.646-7.369,7.209-3.204c0,0-1.762-2.563-1.922-12.816c-0.16-10.253,5.126-13.937,9.772-13.937
                                    C118.72,144.691,125.929,143.569,131.376,149.497z"/>
                                <path style={{fill:'#051B2D'}} d="M93.89,141.487c5.16-3.555,13.617-5.607,21.787-5.126c8.17,0.481,15.219,4.325,16.661,7.369
                                    c1.442,3.044,0,7.922,0,7.922s-0.961-2.796-3.204-2.476c-2.243,0.32-2.723,0.961-6.088,0.801
                                    c-3.364-0.16-6.063-0.13-9.267-0.45c-3.204-0.32-7.234,2.532-6.433,11.343c0.801,8.811,0.828,12.82,0.828,12.82
                                    s-2.488-3.264-4.301-4.357s-5.338,0.348-4.857,5.314c0,0-2.563,0-5.126-1.602c-2.563-1.602-5.927-11.534-6.568-16.34
                                    C86.681,151.9,86.681,146.453,93.89,141.487z"/>
                                <circle style={{fill:'#051B2D'}} cx="82.355" cy="142.768" r="13.617"/>
                                <g>
                                    <path style={{fill:'#051B2D'}} d="M101.4,173.726c-0.128-0.594-0.125-1.254,0.217-1.87c0.354-0.641,1.201-0.955,1.923-0.788
                                        c0.74,0.161,1.29,0.634,1.712,1.123c0.428,0.503,0.756,1.03,1.02,1.64l0.386,0.895l-0.961-0.214
                                        c-0.36-0.08-0.796-0.127-1.131-0.077c-0.338,0.076-0.526,0.166-0.586,0.508c-0.051,0.364-0.036,0.672,0.315,0.78
                                        c0.359,0.11,0.836,0.039,1.277-0.061c-0.376,0.266-0.798,0.505-1.347,0.498c-0.271,0.003-0.612-0.165-0.769-0.447
                                        c-0.153-0.275-0.219-0.546-0.205-0.832c-0.008-0.279,0.095-0.622,0.321-0.871c0.22-0.255,0.524-0.391,0.792-0.46
                                        c0.554-0.134,1.043-0.112,1.566-0.005l-0.574,0.681c-0.235-0.476-0.538-0.977-0.869-1.399c-0.344-0.406-0.723-0.8-1.16-0.925
                                        c-0.425-0.16-0.935-0.084-1.271,0.275C101.708,172.548,101.517,173.136,101.4,173.726z"/>
                                </g>
                                <g>
                                    <g>
                                        <path style={{fill:'#051B2D'}} d="M127.836,161.022c0.555,1.932,0.849,3.881,1.288,5.747c0.095,0.466,0.248,0.892,0.38,1.413
                                            c0.11,0.504,0.208,1.022,0.198,1.556c0.043,1.053-0.445,2.225-1.352,2.718c0.6-0.834,0.708-1.76,0.557-2.663
                                            c-0.131-0.926-0.506-1.746-0.775-2.786c-0.222-0.996-0.352-1.989-0.397-2.987
                                            C127.686,163.023,127.696,162.023,127.836,161.022z"/>
                                    </g>
                                    <g>
                                        <path style={{fill:'#051B2D'}} d="M124.146,175.956c0.818,0.948,1.626,1.708,2.606,1.983c0.483,0.144,1.002,0.2,1.557,0.167
                                            c0.277-0.004,0.558-0.063,0.855-0.079c0.291-0.048,0.585-0.108,0.911-0.128c-0.224,0.222-0.476,0.412-0.738,0.598
                                            c-0.283,0.136-0.564,0.311-0.882,0.392c-0.623,0.213-1.336,0.21-2.004,0.024c-0.666-0.207-1.253-0.632-1.642-1.169
                                            C124.418,177.214,124.167,176.596,124.146,175.956z"/>
                                    </g>
                                    <path style={{fill:'#051B2D'}} d="M119.972,161.325c1.216-0.21,2.783-0.302,2.722-0.968c-0.061-0.665-0.786-3.085-2.117-2.178
                                        C119.246,159.086,118.357,161.603,119.972,161.325z"/>
                                    <path style={{fill:'#051B2D'}} d="M123.178,166.315c0,0.551-0.447,0.998-0.998,0.998c-0.551,0-0.998-0.447-0.998-0.998
                                        c0-0.551,0.447-0.998,0.998-0.998C122.731,165.317,123.178,165.764,123.178,166.315z"/>
                                    <path style={{fill:'#051B2D'}} d="M131.866,159.606c-1.227,0.133-2.759,0.475-2.883-0.182c-0.125-0.656-0.093-3.182,1.436-2.676
                                        C131.948,157.255,133.495,159.43,131.866,159.606z"/>
                                    <path style={{fill:'#051B2D'}} d="M130.157,165.286c0.152,0.53,0.704,0.837,1.234,0.685c0.53-0.152,0.837-0.704,0.685-1.234
                                        c-0.152-0.53-0.704-0.837-1.234-0.685C130.312,164.204,130.005,164.757,130.157,165.286z"/>
                                </g>
                                <g>
                                    <path style={{fill:'#092E47'}} d="M88.066,146.485c1.231,1.44,2.606,2.714,4.047,3.886c1.448,1.164,2.988,2.204,4.626,3.054
                                        c1.624,0.874,3.336,1.595,5.118,2.105c1.772,0.566,3.616,0.859,5.494,1.151c-1.884,0.105-3.823,0.074-5.692-0.38
                                        c-0.952-0.157-1.854-0.495-2.773-0.786c-0.456-0.154-0.886-0.375-1.331-0.559c-0.447-0.182-0.879-0.394-1.298-0.631
                                        c-1.699-0.905-3.282-2.03-4.668-3.356C90.195,149.66,88.974,148.154,88.066,146.485z"/>
                                </g>
                                <g>
                                    <path style={{fill:'#092E47'}} d="M93.78,142.042c2.036-0.698,4.163-1.11,6.314-1.339c2.151-0.223,4.346-0.218,6.513,0.17
                                        c2.139,0.405,4.341,1.195,5.951,2.8c0.798,0.788,1.427,1.736,1.844,2.759c0.369,1.044,0.544,2.127,0.554,3.201
                                        c-0.405-2.104-1.326-4.088-2.927-5.379c-1.565-1.315-3.569-2.011-5.606-2.377c-2.046-0.392-4.164-0.427-6.278-0.394
                                        c-1.059,0.021-2.119,0.097-3.179,0.201C95.902,141.761,94.845,141.902,93.78,142.042z"/>
                                </g>
                                <g>
                                    <path style={{fill:'#092E47'}} d="M87.264,155.47c-0.998-2.973-0.71-6.466,0.901-9.294c0.822-1.387,1.857-2.686,3.18-3.603
                                        c0.602-0.533,1.35-0.855,2.024-1.261c0.721-0.327,1.464-0.604,2.201-0.858c-0.642,0.466-1.327,0.826-1.943,1.288
                                        l-0.909,0.704c-0.312,0.219-0.597,0.47-0.863,0.741l-0.821,0.778l-0.726,0.86c-0.26,0.272-0.453,0.593-0.665,0.9
                                        c-0.209,0.309-0.432,0.609-0.587,0.951c-0.753,1.288-1.241,2.723-1.529,4.207C87.25,152.367,87.146,153.908,87.264,155.47z"
                                        />
                                </g>
                            </g>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M129.834,184.598c-0.82,1.082-2.153,1.682-3.444,2.042c-1.313,0.372-2.673,0.471-4.019,0.463
                                    c-1.348-0.032-2.707-0.218-3.999-0.641c-1.279-0.43-2.535-1.084-3.416-2.103c1.128,0.702,2.385,1.047,3.624,1.334
                                    c1.255,0.245,2.528,0.376,3.811,0.39c1.283,0.027,2.57-0.024,3.831-0.218c0.632-0.094,1.255-0.236,1.866-0.431
                                    C128.704,185.252,129.299,184.99,129.834,184.598z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path style={{fill:'#051B2D'}} d="M81.85,284.756c0,0-5.295,9.398-6.548,12.322s-7.518,14.826-2.715,25.267
                            c4.803,10.441,13.573,13.365,13.573,13.365h33.62c0,0,6.265,0,4.803,4.803c-1.462,4.803-13.573,42.182-13.573,42.182h62.871
                            l25.042-54.711c0,0,7.518-11.276-22.344-19.629c-20.949-5.86-31.723-9.459-36.289-11.085c-1.726-0.615-3.036-2.035-3.518-3.803
                            l-2.375-8.71H81.85z"/>
                        <g>
                            <path style={{fill:'#EA7566'}} d="M117.275,382.694l-11.903,42.182c0,0,3.506,1.879,9.584,2.088s11.298-2.715,11.298-2.715
                                l18.376-41.555H117.275z"/>
                            <path style={{fill:'#051B2D'}} d="M105.372,424.875c0,0-2.923-1.253-3.341,3.132c-0.269,2.828-0.191,9.042-0.102,13.1
                                c0.047,2.124,1.782,3.815,3.907,3.815h48.603c1.719,0,2.713-1.958,1.686-3.337c-0.79-1.061-2.08-2.201-4.186-3.137
                                c-5.638-2.506-13.156-4.803-17.541-7.726c-4.385-2.923-3.092-8.421-8.144-6.473c0,0-5.682,2.715-11.298,2.715
                                C109.34,426.963,105.372,424.875,105.372,424.875z"/>
                        </g>
                        <g>
                            <path style={{fill:'#EA7566'}} d="M146.433,382.694l-11.903,42.182c0,0,3.506,1.879,9.584,2.088
                                c6.078,0.209,11.298-2.715,11.298-2.715l18.376-41.555H146.433z"/>
                            <path style={{fill:'#051B2D'}} d="M134.531,424.875c0,0-2.924-1.253-3.341,3.132c-0.269,2.828-0.191,9.042-0.102,13.1
                                c0.047,2.124,1.782,3.815,3.907,3.815h48.603c1.719,0,2.713-1.958,1.686-3.337c-0.79-1.061-2.08-2.201-4.186-3.137
                                c-5.638-2.506-13.156-4.803-17.541-7.726c-4.385-2.923-3.092-8.421-8.144-6.473c0,0-5.682,2.715-11.298,2.715
                                C138.498,426.963,134.531,424.875,134.531,424.875z"/>
                            <g>
                                <path style={{fill:'#092E47'}} d="M131.19,428.008c0.157,1.606,0.25,3.202,0.278,4.794c0.031,1.592,0.02,3.183,0.078,4.778
                                    l0.009,2.392l-0.015,1.171c0.025,0.35,0.094,0.723,0.192,1.075c0.209,0.705,0.575,1.363,1.144,1.841
                                    c0.551,0.515,1.327,0.74,2.119,0.863c-0.794,0.106-1.663-0.01-2.408-0.455c-0.742-0.449-1.295-1.185-1.607-1.986
                                    c-0.16-0.403-0.278-0.812-0.331-1.258l-0.061-1.224l-0.063-2.4c0.003-0.801-0.024-1.6,0.004-2.401
                                    c0.048-0.801,0.078-1.603,0.145-2.403C130.781,431.194,130.953,429.596,131.19,428.008z"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect x="148.099" y="288.269" style={{fill:'#629CAC'}} width="59.532" height="4.826"/>
                            <polygon style={{fill:'#629CAC'}} points="218.361,232.168 201.762,288.269 207.63,288.269 224.586,232.168 					"/>
                            <polygon style={{fill:'#7CA7B2'}} points="224.586,232.168 289.762,232.168 271.273,293.095 207.63,293.095 207.63,288.269 					
                                "/>
                        </g>
                        <path style={{fill:'#629CAC'}} d="M253.291,258.594c-1.202,4.027-4.44,6.616-7.233,5.782c-2.793-0.833-4.083-4.774-2.881-8.801
                            c1.202-4.027,4.44-6.616,7.233-5.782S254.492,254.567,253.291,258.594z"/>
                    </g>
                    <g>
                        <rect x="145.996" y="293.095" style={{fill:'#BAD2D7'}} width="140.653" height="8.911"/>
                        <rect x="171.96" y="302.006" style={{fill:'#BAD2D7'}} width="8.074" height="142.916"/>
                        <rect x="266.732" y="302.006" style={{fill:'#BAD2D7'}} width="8.074" height="142.916"/>
                        <polygon style={{fill:'#7CA7B2'}} points="266.732,302.006 274.807,302.006 274.807,321.066 266.732,328.484 				"/>
                        <polygon style={{fill:'#7CA7B2'}} points="171.96,302.006 180.034,302.006 180.034,321.389 171.96,328.484 				"/>
                    </g>
                </g>
            </g>
            <g>
                <path style={{fill:'#0A343E'}} d="M386.882,109.174c0,0-5.861,0.426-9.697,1.451c-3.836,1.025-9.518,7.616-7.103,17.722
                    s6.251,22.848,1.563,29c-4.688,6.151-12.928,14.939-9.944,25.045c2.983,10.106,18.752,11.424,26.424,6.152
                    c7.672-5.273,4.972-25.778,4.262-42.182C391.675,129.959,393.027,109.627,386.882,109.174z"/>
                <path style={{fill:'#0A343E'}} d="M410.286,112.973c0,0,6.961-1.908,9.376,2.34c2.415,4.247,2.841,12.742,2.699,16.404
                    c-0.142,3.662,0.284,10.399,5.967,13.621c5.682,3.222,18.184,10.106,19.037,19.626c0.852,9.52-7.814,21.091-20.457,19.773
                    c-12.644-1.318-18.61-4.54-18.61-11.863c0-7.323-0.061-39.011,0.538-47.187C409.433,117.509,410.286,112.973,410.286,112.973z"/>
                <path style={{fill:'#227F7F'}} d="M374.486,239.953c0,0-4.641,16.111-7.79,30.171c-3.149,14.061,2.96,71.914,2.96,71.914h61.23
                    c0,0,7.103-28.853,5.967-51.702s-13.496-50.53-13.496-50.53L374.486,239.953z"/>
                <path style={{fill:'#32BD9D'}} d="M387.955,180.404c-3.667,0.085-5.371-1.087-7.644,3.307c-2.273,4.394-9.518,17.722-12.502,24.459
                    c-2.983,6.737,0.71,15.232,3.125,20.212c2.415,4.98,3.552,11.571,3.552,11.571s3.36,2.636,18.941,2.929
                    c15.58,0.293,25.383-1.757,29.929-3.076c0,0,3.694-14.5,5.398-24.752c1.705-10.252,13.354-27.974-9.092-37.495L387.955,180.404z"
                    />
                <path style={{fill:'#32BD9D'}} d="M385.727,177.146c0,0-9.395,2.61-13.515,9.494c-4.12,6.884-28.271,43.646-28.271,43.646
                    l-21.31-16.404c0,0-1.847,9.227-7.245,15.672c-5.398,6.444-9.802,9.667-9.802,9.667l30.26,22.702c0,0,10.087,5.859,18.326,0
                    c8.24-5.859,24.719-31.343,24.719-31.343L385.727,177.146z"/>
                <g>
                    <path style={{fill:'#7CA7B2'}} d="M284.932,189.824l6.188,55.647c0.124,1.111,1.036,1.951,2.121,1.951h36.027l-8.666-60.05h-33.548
                        C285.778,187.372,284.787,188.517,284.932,189.824z"/>
                    <path style={{fill:'#629CAC'}} d="M320.602,187.372L320.602,187.372l8.666,60.05l0,0c1.198,0,2.117-1.096,1.944-2.319l-7.845-55.268
                        C323.166,188.421,321.989,187.372,320.602,187.372z"/>
                </g>
                <polygon style={{fill:'#BAD2D7'}} points="404.84,252.142 391.249,290.922 438.559,307.972 449.773,272.028 		"/>
                <path style={{fill:'#AA3F3F'}} d="M372.098,342.038c0,0,4.65,78.44,4.103,86.335s-6.977,12.407-9.506,14.663
                    c-2.528,2.256,2.668,5.075,10.873,2.256c8.205-2.82,15.042-5.357,15.863-10.151c0.821-4.793,0.273-5.921,1.368-33.836
                    c1.094-27.915,3.942-59.266,3.942-59.266H372.098z"/>
                <path style={{fill:'#051B2D'}} d="M366.695,443.036c0,0-12.648,5.357-14.289,12.407c0,0,6.838,2.538,18.598,0
                    c11.761-2.538,23.248-8.968,23.248-8.968s0.547-7.104-0.821-11.333c0,0-1.367,6.203-15.863,10.151
                    C363.073,449.239,366.695,443.036,366.695,443.036z"/>
                <path style={{fill:'#AA3F3F'}} d="M428.026,342.038c0,0-4.65,78.44-4.103,86.335s6.977,12.407,9.506,14.663
                    c2.528,2.256-2.668,5.075-10.873,2.256c-8.205-2.82-15.043-5.357-15.863-10.151c-0.82-4.793-0.273-5.921-1.367-33.836
                    s-3.942-59.266-3.942-59.266H428.026z"/>
                <path style={{fill:'#051B2D'}} d="M433.43,443.036c0,0,12.648,5.357,14.289,12.407c0,0-6.838,2.538-18.598,0
                    c-11.761-2.538-23.248-8.968-23.248-8.968s-0.547-7.104,0.82-11.333c0,0,1.368,6.203,15.863,10.151
                    C437.052,449.239,433.43,443.036,433.43,443.036z"/>
                <polygon style={{fill:'#FFFFFF'}} points="387.449,173.197 391.249,182.246 382.299,188.105 383.847,177.796 385.727,172.154 
                    387.888,170.589 		"/>
                <path style={{fill:'#FFFFFF'}} d="M412.473,166.143l2.785,1.75l4.404,9.667c0,0-15.911,11.344-19.179,14.02l-9.234-9.333
                    c0,0,9.044,0.001,13.598-3.385c4.555-3.386,7.995-8.186,7.995-8.186L412.473,166.143z"/>
                <path style={{fill:'#AA3F3F'}} d="M389.542,160.741l-2.555,15.207c0,0-0.852,5.566,4.262,6.298
                    c5.114,0.732,17.474-2.636,21.594-11.571l-2.273-27.828L389.542,160.741z"/>
                <path style={{fill:'#AA3F3F'}} d="M389.402,113.262c-6.911,0.962-13.212,4.98-13.354,14.939c-0.142,9.96,1.137,25.778,4.12,29.439
                    c2.983,3.662,11.365,3.076,11.365,3.076s15.343,1.318,19.037-17.868c0,0,4.688,2.197,5.967-2.636
                    c1.279-4.833-2.273-8.055-7.245-5.419C409.291,134.792,412.559,110.04,389.402,113.262z"/>
                <path style={{fill:'#0A343E'}} d="M387.413,107.989c-1.047,1.484-1.705,6.737,0,9.959s9.518,6.005,17.332,6.005
                    c0,0-0.966,11.232,4.546,10.838c0,0,4.122-2.931,6.82,0.878c0,0,1.42-2.782,1.704-9.666c0.284-6.884-1.137-18.747-10.229-20.212
                    C398.494,104.328,389.686,104.767,387.413,107.989z"/>
                <g>
                    <path style={{fill:'#32BD9D'}} d="M429.02,183.718c0,0-7.227-0.153-11.488,5.412c-4.262,5.566-1.279,14.821,2.131,21.105
                        c3.41,6.284,9.358,19.026,9.358,19.026l-16.177,42.767c0,0,6.393,2.197,10.513,5.419c4.12,3.222,6.819,6.737,6.819,6.737
                        s20.599-43.06,23.156-46.429c2.557-3.369,2.131-11.717-1.421-19.919C448.359,209.635,435.323,183.249,429.02,183.718z"/>
                    <path style={{fill:'#1B5E49'}} d="M412.843,272.028c0,0,10.55,2.79,17.332,12.156c0,0-6.251-1.025-11.649-4.394
                        C413.127,276.422,412.843,272.028,412.843,272.028z"/>
                    <g>
                        <path style={{fill:'#AA3F3F'}} d="M407.871,279.645c0,0-4.023,5.126-6.486,7.323c-2.464,2.197-4.355,6.737,0,6.005
                            c0,0-2.319-0.732-2.605,2.636c-0.285,3.369,0.993,5.273,3.977,6.005c2.983,0.732,2.699,0,2.699,0s0.71,2.636,2.415,3.222
                            c1.705,0.586,3.41,0,3.41,0s1.279,1.692,5.114,0.26c3.836-1.432,5.967-6.705,8.098-9.927c2.131-3.222,3.836-7.47,2.983-8.788
                            s0.951-4.381,0.951-4.381s-5.101-6.234-12.615-8.909l-2.228,3.916C413.584,277.008,410.767,276.129,407.871,279.645z"/>
                        <g>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M408.297,290.043c-0.321,0.593-0.794,1.076-1.31,1.488c-0.516,0.418-1.097,0.75-1.706,1.014
                                    c-1.221,0.509-2.594,0.73-3.896,0.428c0.619-0.268,1.215-0.451,1.795-0.676c0.581-0.214,1.15-0.434,1.71-0.677
                                    C406.023,291.153,407.102,290.612,408.297,290.043z"/>
                            </g>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M405.456,301.614c0.952-0.411,1.697-0.841,2.436-1.35c0.365-0.257,0.721-0.531,1.085-0.822
                                    c0.367-0.29,0.724-0.605,1.167-0.904c-0.171,1.041-0.826,1.929-1.677,2.55c-0.442,0.289-0.929,0.523-1.451,0.625
                                    C406.501,301.819,405.949,301.825,405.456,301.614z"/>
                            </g>
                            <g>
                                <path style={{fill:'#051B2D'}} d="M411.28,304.836c0.726-0.573,1.332-1.018,1.907-1.537c0.291-0.25,0.572-0.513,0.855-0.794
                                    c0.293-0.277,0.566-0.582,0.931-0.89c0.045,0.938-0.433,1.825-1.124,2.44c-0.353,0.302-0.757,0.547-1.198,0.687
                                    C412.216,304.886,411.743,304.951,411.28,304.836z"/>
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <path style={{fill:'#AA3F3F'}} d="M287.547,213.335h8.635c2.154,0,3.227,2.759,1.618,4.236c-1.18,1.084-3.144,1.847-6.408,1.451
                        c-8.432-1.023-10.416-2.801-8.68-4.468c1.736-1.668,4.554-3.746,4.554-3.746L287.547,213.335z"/>
                    <path style={{fill:'#AA3F3F'}} d="M289.16,223.625h7.936c0,0,4.993-4.019,0.884-6.228h-12.54c0,0-2.976,1.162-2.232,3.439
                        C283.952,223.114,287.672,223.625,289.16,223.625z"/>
                    <path style={{fill:'#AA3F3F'}} d="M290.856,228.483c3.76,0.256,7.232-1.759,7.728-2.669s-1.488-2.188-1.488-2.188l-11.154-0.529
                        c0,0-3.973,3.318-1.493,4.352C286.928,228.483,290.856,228.483,290.856,228.483z"/>
                    <path style={{fill:'#AA3F3F'}} d="M289.16,231.551c0,0,5.952-0.511,7.936-1.79c1.984-1.278,0.015-4.339-6.24-2.469
                        c-2.892,0.865-4.968,0.617-4.968,0.617S285.802,231.99,289.16,231.551z"/>
                    <g>
                        <g>
                            <path style={{fill:'#051B2D'}} d="M284.814,217.713c2.241,0.007,4.415,0.077,6.597,0.035c1.09,0.002,2.176-0.058,3.266-0.11
                                c1.094-0.05,2.177-0.172,3.304-0.241c-2.041,0.954-4.319,1.319-6.556,1.355c-1.122,0.004-2.245-0.053-3.353-0.223
                                C286.964,218.365,285.864,218.122,284.814,217.713z"/>
                        </g>
                        <g>
                            <path style={{fill:'#051B2D'}} d="M285.941,223.096c1.899,0.132,3.72,0.224,5.566,0.288l2.772,0.087
                                c0.929,0.033,1.861,0.052,2.817,0.154c-1.812,0.624-3.725,0.808-5.624,0.763c-0.95-0.048-1.9-0.141-2.833-0.346
                                C287.71,223.839,286.783,223.565,285.941,223.096z"/>
                        </g>
                        <g>
                            <path style={{fill:'#051B2D'}} d="M285.888,227.909c1.874-0.15,3.685-0.135,5.494-0.207c1.815-0.059,3.573-0.16,5.413-0.515
                                c-0.761,0.565-1.669,0.91-2.583,1.145c-0.919,0.244-1.86,0.327-2.797,0.375c-0.938,0.026-1.874-0.02-2.799-0.148
                                C287.692,228.431,286.773,228.243,285.888,227.909z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path style={{fill:'#051B2D'}} d="M384.82,131.064c0.258,1.09,0.376,2.192,0.428,3.298c0.057,1.107,0.022,2.216-0.111,3.338
                            c-0.178,1.173-0.499,2.127-0.546,3.166c-0.069,1.016,0.129,2.034,0.869,2.899c-1.048-0.468-1.677-1.718-1.741-2.878
                            c-0.066-0.586-0.014-1.167,0.051-1.734c0.087-0.588,0.205-1.075,0.259-1.601c0.146-1.051,0.23-2.132,0.35-3.214
                            C384.491,133.253,384.616,132.164,384.82,131.064z"/>
                    </g>
                    <g>
                        <path style={{fill:'#051B2D'}} d="M390.335,147.184c0.052,0.696-0.144,1.392-0.498,2.016c-0.351,0.628-0.927,1.166-1.623,1.477
                            c-0.7,0.287-1.481,0.38-2.18,0.209c-0.355-0.051-0.677-0.217-0.998-0.336c-0.303-0.176-0.595-0.36-0.858-0.579
                            c0.356-0.011,0.68,0.028,1,0.049c0.322-0.014,0.632,0.027,0.931-0.002c0.603-0.019,1.153-0.146,1.652-0.361
                            C388.779,149.234,389.556,148.303,390.335,147.184z"/>
                    </g>
                    <path style={{fill:'#051B2D'}} d="M393.288,130.554c-1.326-0.102-3.017-0.036-3.021-0.778c-0.005-0.742,0.522-3.497,2.044-2.636
                        C393.834,128,395.05,130.689,393.288,130.554z"/>
                    <path style={{fill:'#051B2D'}} d="M390.369,136.418c0.057,0.61,0.583,1.056,1.175,0.997s1.024-0.602,0.967-1.211
                        c-0.057-0.61-0.583-1.056-1.175-0.997C390.744,135.266,390.311,135.808,390.369,136.418z"/>
                    <path style={{fill:'#051B2D'}} d="M380.35,129.931c1.33,0.015,3.009,0.229,3.074-0.51c0.066-0.739-0.232-3.53-1.82-2.806
                        C380.017,127.339,378.584,129.911,380.35,129.931z"/>
                    <path style={{fill:'#051B2D'}} d="M382.775,136.03c-0.107,0.602-0.668,1.001-1.253,0.89c-0.584-0.111-0.971-0.689-0.863-1.291
                        c0.108-0.603,0.668-1.001,1.253-0.89C382.496,134.849,382.883,135.427,382.775,136.03z"/>
                </g>
                <g>
                    <path style={{fill:'#051B2D'}} d="M409.176,139.282c0.128-0.751,0.492-1.441,0.97-2.043c0.479-0.606,1.119-1.1,1.847-1.432
                        c0.73-0.32,1.555-0.466,2.334-0.345c0.784,0.073,1.503,0.408,2.106,0.848c-0.765-0.004-1.452-0.069-2.119,0.023
                        c-0.662,0.061-1.288,0.222-1.87,0.495C411.257,137.339,410.24,138.234,409.176,139.282z"/>
                </g>
                <g>
                    <path style={{fill:'#051B2D'}} d="M389.542,160.741c0.713-0.203,1.375-0.377,2.049-0.516c0.67-0.152,1.342-0.253,2.016-0.364
                        c0.678-0.081,1.357-0.173,2.053-0.194c0.694-0.036,1.398-0.039,2.138,0.076c-1.238,0.825-2.608,1.216-4.007,1.431
                        c-0.702,0.088-1.414,0.137-2.132,0.08C390.948,161.201,390.214,161.087,389.542,160.741z"/>
                </g>
            </g>
        </g>
        </svg>
    )
}

export default ContactSVG
import React,{useEffect,useState} from 'react' 
import NotificationList from '../../components/notificationList/NotificationList'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationListFetch } from '../../redux/Slice/notificationSlice';
import Pagination from '../../components/pagination/Pagination';
import OtpInput from '../../components/otp-input/OtpInput';

const Notification = () => {
  const dispatch = useDispatch() 
  const notificList = useSelector(state => state?.notificationList?.notificList || [])
  const [mobileOtpValue, setMobileOtpValue] = useState(["", "", "", "", "", "",]);
  const [mailValue, setMailValue] = useState(["", "", "", "", "", ""]);
  const [filters, setFilters] = useState({limit: 10, page: 1 });
  const paginatedData = (page) => {
    setFilters((prevState) => ({
      ...prevState,
      limit: 10,
      page: page
    }));
  }
  useEffect(()=>{
    dispatch(getNotificationListFetch(filters)) 
  },[dispatch,filters]) 
  return (
    <>
      <NotificationList notificationData={notificList.data} />
      <Pagination count={notificList?.totalPages} filters={filters} paginatedData={paginatedData} /> 
    </>
  )
}

export default Notification